#pnlRecentlyViewedProducts .AltStratDescription,
#hotspotModal .hsColourDesc .ColourDesc,
#hotspotModal .hsColourDesc .ColourLabel,
#hotspotModal .sizeGuideLink a,
#hotspotModal .hsSizeLabel,
.sdlProdList .s-productthumbbox .s-producttext-top-wrapper a .nameWrapTitle,
.product-sash {
    font-family: var(--brand-font);
}

.reviews-container
    .bv-cv2-cleanslate
    .bv-content-pagination
    .bv-content-pagination-container
    .bv-content-btn-pages-load-more,
.currencySelector .languageSwitcherLabel,
.BaskName,
.lillBasket #lblBagSubTotal,
.lillBasket .BaskName,
.lillBasket .ColrandSize,
.lillBasket .BaskQuant,
.PSPlacementWrapper .SuggestedProduct > h4 a .PSProdName,
.SzQuantGroup .SizeGuideText {
    font-family: var(--brand-font) !important;
}

#divsiblinglistwrapper ul li a,
#pnlRecentlyViewProducts .AltStratBrand .wrap,
#pnlRecentlyViewedProducts .AltStratBrand,
.hotspotbuy.hotspotquickbuy .QuickLookText,
.sdlProdList #hotspotModal #hsViewProduct a,
.sdlProdList .s-productthumbbox .s-producttext-top-wrapper a .brandWrapTitle,
.bv_text,
.sdlProdList .Responsive .s-largered .fromProductPrice,
.sdlProdList .s-producttext-price .s-largered,
#filterByMob .MobFiltersText,
.sdlProdList .productFilterList .FilterValue,
.brandTitle,
#Body.sdlProdList #mobFilterControls li .textIconWrap,
.mobappfltrsNo,
.ui-autocomplete > li > .ui-corner-all,
#mp-menu .mp-level .menuitemtext,
.sdDetails .WishListContain a,
.sdlProdList .dropprods_Order,
.FooterSubGroup .FooterHeader,
.FooterWrap .footerContainer .SubTitle,
.lillBasket #aViewBag,
.lillBasket #aCheckout,
#topLinkMenu ul.TopLinkMenu > li span,
.RightBarLinks div.TopLinkMenu > a span,
.sitewideBanner .swiper-slide .swiper-text,
#MobtxtSearch,
#hotspotModal .hsColourDesc .ColourDesc,
#hotspotModal .PinWrapText select,
.PSPlacementWrapper .SuggestedProduct > h4 a .PSProdTitle,
.PSPlacementWrapper .SuggestedProduct > h4 a .PSProdBrand,
#pnlRecentlyViewedProducts .AltStratDescription,
.cookie-banner__content,
.PSProdBrand,
.mobSortDesc .productFilterTitle {
    font-family: var(--brand-font);
    font-weight: var(--fw-bold);
}

#mp-menu .level2 .MobMenChevron,
#mp-menu .account-menu .account-menu a.collapsable-item {
    font-weight: var(--fw-normal);
}

nav#topMenu li.root .SubMenuWrapper > ul .level1.sdmColHeader > a,
.stackFlexHeader h3,
nav#topMenu li.root .SubMenuWrapper > ul .level2.sdmColHeader > a,
.sdlProdList .HeadnCount h1,
#filterByMob .MobFiltersText,
.sdDetails .AltProdDet .pdpPrice,
.sdDetails .s-productextras-column-1 span,
.sdDetails .s-productextras-checkbox-column-1 label,
.s-productextras-column-3 a,
.sdDetails .RadTabStrip .rtsLevel .rtsIn,
.infoTabPage h2,
.ProdDetRight .infoaccordion .panel-body h2,
.sdDetails .s-productextras-column-1 label,
.sdDetails #parDeliveryMethods li span.TitleDel,
.PSPlacementWrapper .PSPlacementTitle,
#pnlRecentlyViewedProducts .AltStratTitle,
.sdlProdList .layout span.ViewbyCols,
.sdlProdList .pagination .sortbyfilter label,
.appLinks .appText,
#divCurrencySelector span.currencyHeader,
#divLanguageSelector span.languageHeader,
#topLinkMenu ul.TopLinkMenu .iconsCS a span,
#topLinkMenu ul.TopLinkMenu .iconsStores a span,
.signup-wrapper .signupInner .SubTitle,
li#mob-signout a,
#liMobileLanguageSelector a.mp-back span,
#liMobileCurrencySelector a.mp-back span,
.mobMenuGroup li .mp-level .menulevelheader a,
.MobileMenuContentWrap .shop > ul > li .menulevelheader > a,
.mobMenuGroup #mob-myaccount .menulevelheader a,
#divBagItemsChild .bagHeader p,
.lillBasket .BaskPrice,
.lillBasket #spanBagSubTotalValue,
#topLinkMenu ul li.TopLinkDrop ul li.SignOut a span,
.sdlProdList #mobFilterControls .mobApplyFilter .textIconWrap,
.newBasketPromoCode .PromoCodeBut a,
.Basket #divContinueSecurely.ImgButWrap a,
.Basket #divContinueSecurelyTop.ImgButWrap a,
#hotspotModal #hsPrice,
#hotspotModal .hsColourDesc .ColourLabel,
#mp-menu .shop > ul li.level2.sdmColHeader a,
.PSPlacementWrapper .SuggestedProduct .PSProdPrice div,
#pnlRecentlyViewedProducts .AltStratRefSell div,
.PSPlacementWrapper .SuggestedProduct .PSProdPrice span,
#pnlRecentlyViewedProducts .AltStratRefSell span,
.ui-autocomplete .header,
.infoaccordions .DeliveryMethods .price,
#DisplayAttributes li span.feature-name,
.infoaccordions .DeliveryMethods li span.TitleDel,
.sdlProdList .s-largered .curprice,
.sticky-atb .pdpPrice span,
.ProdDetails .ShopTheLook .s-largered .curprice,
#lblCategoryHeader,
.sdlProdList h1,
.order-details-title,
#accountHeader p,
.order-summary-total-label,
.order-summary-total,
.product-gallery .product-price,
#divWishList .wishlist-summary ul li .wishItemContentWrap .wishItemContentWrap-price,
.OrderComplete a.button,
.OrderCompleteRegistration .ContinueOn,
.OrderCompleteLogin .OrderCompleteLoginForm .dnnPrimaryAction,
.ProdDetails .printess-button-cta {
    font-family: var(--brand-font);
    font-weight: var(--fw-bold);
}

nav#topMenu li.root .SubMenuWrapper li .fourPromoItem a .fourPromoCTA,
.sdDetails .AltProdDet .addToBasketContainer .ImgButWrap a,
.currencyCountryHeader,
#topLinkMenu .TopSubLinkMenu .user-name a,
nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .level1.sdmColHeader > a,
nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .level2.leftHeader.sdmColHeader > a,
nav#topMenu li.root .SubMenuWrapper li .fourPromoSlot.BottomPromoRight .fourPromoItem.shopAllPromo .fourPromoCTA div,
#divPersaddToBasketContainer .ImgButWrap a,
#hotspotModal #hsAddToBagContainer a,
#hotspotModal #hsAddToWishListContainer a,
.PSPlacementWrapper .PSPlacementTitle,
#pnlRecentlyViewedProducts .AltStratTitle,
#pnlRecentlyViewedProducts .AltStratBrand,
nav#topMenu > ul li.newTag::after,
.am-menu ul li.newTag .menuitemtext::after,
nav#topMenu > ul li.saleTag::after,
.am-menu ul li.saleTag .menuitemtext::after,
nav#topMenu > ul li.hotTag::after,
.am-menu ul li.hotTag .menuitemtext::after,
nav#topMenu ul li.root .SubMenuWrapper > ul li.level1.sdmColHeader.BlackItalicFont > a,
#productImages #productImageContainer .productImage.productImageGrid .innerImageContainer a.viewMoreNumber,
.sticky-atb .sticky-atb--buysection .addToBag,
.OrderComplete h1,
.order-complete-subtitle,
.order-details-section h2,
.OrderComplete h2,
.order-details-section p b {
    font-family: var(--brand-font);
    font-weight: var(--fw-bold);
}

.sdlProdList .QuickLookIcon,
.sdlProdList .WishIcon,
.productFilterTitleBox .glyphicon::before,
.back-to-top,
.sdDetails .pdpWishListIco,
.sdDetails .SuggestedProduct .WishIcon,
.sdDetails .hotspotbuy .QuickLookIcon,
.sdDetails .hotspotbuy .WishIcon,
a.bx-prev,
a.bx-next,
.sdDetails #mainDetails p.preOrder::before,
.sdDetails .productImage .mainImageButtonPrev a span,
.sdDetails .productImage .mainImageButtonNext a span,
.updateIcon,
#li_placement .SuggestedProduct .QuickLookIcon,
#li_placement .SuggestedProduct .WishIcon,
#li_placement .hotspotbuy .QuickLookIcon,
#li_placement .hotspotbuy .WishIcon,
.sdDetails .SuggestedProduct .QuickLookIcon,
.sdDetails .AltProdDet #piThumbs #sPrevImg span::before,
.sdDetails .AltProdDet #piThumbs #sNextImg span::before,
.lillBasket .PreviousEnable,
.lillBasket .NextEnable,
.lillBasket .PreviousDisable,
.lillBasket .NextDisable,
.BasketNew .BasketQuantBut::before,
.BasketNew .BasketQuantBut.s-basket-plus-button::before,
.Responsive .BasketNew #BasketDiv .NewUpdateQuant:hover .updateIcon,
.sdlProdList .catTemplateNew .viewMore span.OpenView::after,
.sdlProdList .catTemplateNew .viewMore span.ClosedView::after,
.FooterHeader span,
.sdlProdList .catTemplate .viewMore span.OpenView::after,
.sdlProdList .catTemplate .viewMore span.ClosedView::after,
.HelpIco,
.wishlist-summary .items-nav {
    background-image: url("/images/core/sd-new-main-sprite-15-v12.svg");
}

.ControlWrap .accountBlock .login .ico,
.ControlWrap .WishList .wishQuantityContainer,
a.mp-back::before,
.mobMenuGroup #mob-myaccount .mp-back::before,
.shop > ul > li a.mp-back::before,
#liMobileLanguageSelector a.mp-back::before,
#liMobileCurrencySelector a.mp-back::before,
.am-menu .am-level a.MobMenChevron::after,
.mp-menu .mp-level a.MobMenChevron::after,
#MobcmdSearch::before,
.FooterGroup4 .FooterGroupLink a::before,
.FooterWrap .footerContainer .footerInfoSection .footerStoreFinder a::before,
.FooterWrap .footerContainer .footerInfoSection .footerCustServ a::before,
#topLinkMenu ul.TopLinkMenu .iconsStores a::before,
#topLinkMenu ul.TopLinkMenu .iconsCS a::before,
.mobMenuGroup li .mp-level .mp-back::before,
.lillBasket #aCheckout span::after,
#topLinkMenu ul li.TopLink > a > .ico,
#topLinkMenu ul li.TopLinkDrop ul li a .logoutTxt::after,
.lillBasket a.removeClass::before,
.lillBasket a.bagMoveToWishlistClass::before,
.dvSearch .TextBoxClear::before,
.ToplinksGroup #mobSearchContainer #mobileSearchTriggerBtn > span,
.sitewideBanner .closingIcon div,
.s-basket-remove-button a .deleteItemIcon,
.FooterSubGroup .FooterHeader span,
.mobFilterAppIcon,
.productFilterTitleBox .glyphicon::before,
.selectedFilterToggle::after,
.hotspotbuy.hotspotwishlist .WishIcon,
.Responsive .mobDdClose .clsFilterIcon::before,
.sdlProdList #spnSearchIcon .glyphicon,
.productFilter .productFilterList #spnClearBrandFilter,
.PageNumber a.NextLink,
.PageNumber a.PrevLink {
    background-image: url("/images/core/sd-elevate-icons-v9.svg");
}

.u-vh {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    inline-size: 1px;
    block-size: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

.u-vh--focusable:active,
.u-vh--focusable:focus {
    position: static;
    overflow: visible;
    clip: auto;
    inline-size: auto;
    block-size: auto;
    margin: 0;
}

.global-icon-tick .tick-outer:not(.wishlist-share .global-icon-tick .tick-outer) {
    fill: #80e096;
}

.global-icon-tick .tick-inner {
    fill: #000;
}

.modal .close {
    background-image: url("/images/core/sd-elevate-icons-v9.svg") !important;
    content: "";
    display: inline-block;
    width: 39px;
    height: 39px;
    background-size: 900px 900px !important;
    background-position: -535px -426px !important;
    cursor: pointer;
    background-color: transparent !important;
    border: none;
    right: 5px;
    top: 5px;
}

a.bx-prev,
a.bx-next {
    width: 45px;
    height: 45px;
    background-position: -13.2em -46.6em;
    background-size: 75em 75em;
}

.bx-prev::after,
.bx-prev::before,
.bx-next::after,
.bx-next::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    background-color: transparent;
}

a.bx-prev {
    left: 10px;
}

a.bx-next {
    background-position: -19.4em -46.6em;
    right: 10px;
}

.bx-prev:hover,
.bx-next:hover {
    background-color: transparent;
}

.bx-wrapper .bx-pager.bx-default-pager a {
    background: #fff;
    width: 16px;
    height: 16px;
    border-radius: 0;
    border: solid 1px #666;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
    background: #0054a4;
}

.pagination {
    padding: 0 5px;
    margin: 0;
}

.s-maincontent-container .pagination {
    padding: 0;
}

.hmeSlideImg,
.divCTA,
.divSpacer {
    top: 0;
}

.hmeSlideImg {
    z-index: 0;
    transition: 0.5s ease-in-out;
    opacity: 1;
}

.product-sash {
    font-family: var(--brand-font);
    font-weight: var(--fw-bold);
}

.divAdTable {
    width: 100%;
    display: block;
    height: 100%;
    float: left;
    z-index: 2;
    position: absolute;
    bottom: 0;
}

.divAdTable a {
    color: #fff;
}

.divCTA {
    text-align: center;
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 2% 0 0;
}

.aCTAlink {
    background: rgba(51, 51, 51, 0.6);
    padding: 10px 20%;
    text-transform: uppercase;
    color: #fff;
    transform: translate3d(0, 0, 0);
    width: 100%;
}

a.aCTAlink:hover {
    color: #fff;
}

.imgCopy {
    text-transform: uppercase;
    color: #fff;
    margin: 10% 0 1%;
}

li:hover > .hmeSlideImg {
    transition: 0.5s ease-in-out;
    opacity: 0.8;
}

/*-- three callout row --*/
.callOutAd {
    display: block;
    position: relative;
}

.callOutInnerWrap {
    overflow: hidden;
    position: relative;
}

.callOut img {
    padding-bottom: 10px;
    z-index: 0;
}

.callOutimg {
    transition: 0.5s ease-in-out;
    opacity: 1;
}

.callOutCTA,
.sliderTwo .callOutCTA {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 80px;
    z-index: 2;
    position: absolute;
    bottom: 10%;
    left: 0;
    background: rgba(51, 51, 51, 0.6);
    padding: 10px 0;
}

.callOutCTA a,
.sliderTwo .callOutCTA a {
    display: block;
    width: 100%;
    height: 100%;
}

.callOutAd:hover > .callOutInnerWrap > .callOutimg,
.sliderTwo li div:hover > .callOutInnerWrap > .callOutimg,
.sliderThree li .slideCell:hover > div > .callOutimg {
    transition: 0.5s ease-in-out;
    opacity: 0.6;
    cursor: pointer;
}

.callOutCTA span,
.sliderTwo .callOutCTA span {
    width: 100%;
    text-transform: uppercase;
    float: left;
    text-align: center;
    transform: translate3d(0, 0, 0);
}

.callOutCat {
    font-size: 2.3em;
    line-height: 1em;
}

.callOutCat,
.callOutSubTxt {
    color: #fff;
}

.sliderTwo img {
    padding-bottom: 10px;
}

.tab {
    position: relative;
}

span.prodPrice {
    font-size: 1.1em;
}

.hpTrendingTxt {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5em;
    font-weight: var(--fw-bold);
    letter-spacing: 1px;
    color: #747474;
    margin-top: 10px;
}

.tabSwitch .inActive {
    background: transparent;
    color: #747474;
}

.tabSwitch .inActive:hover {
    background-color: transparent;
    color: #747474;
    text-decoration: none;
}

.tabSwitch .active {
    background: transparent;
    color: #0054a4;
}

.tabSwitch .active::before,
.tabSwitch .inActive::before {
    content: "";
    display: block;
    height: 5px;
    width: 50%;
    position: absolute;
    background-color: #0054a4;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.tabSwitch .inActive::before {
    background-color: transparent;
}

.tabSwitch .inActive:hover::before {
    background-color: #747474;
}

.hotspotTooltip {
    display: block !important;
}

.hotspotTop,
.hotspotBottom {
    text-align: center;
}

#One,
#Two {
    width: auto;
    float: left;
    padding: 10px 60px;
    border: 0;
    font-size: 1.2em;
}

#One:hover,
#Two:hover {
    cursor: pointer;
}

h3.prodTitle:hover {
    color: #0054a4;
}

.carouselIcon {
    padding-right: 4px;
}

.bxCarouselA li,
.bxCarouselB li {
    width: 20%;
    float: left;
}

.hpSlideInner {
    display: block;
    overflow: hidden;
}

.slideCell {
    padding-bottom: 9999px;
    margin-bottom: -9999px;
}

.noPadding {
    padding: 0;
}

.botMargin10 {
    margin-bottom: 0;
}

.sliderThree img {
    padding-bottom: 10px;
}

.sliderThree .lgeImgWrap .callOutimg {
    margin-bottom: 0;
}

.lgeImgWrap {
    width: 100%;
    float: left;
    position: relative;
    min-height: 1px;
    margin-bottom: -10px;
}

.s-productthumbtext .s-producttext-price .finance-monthly-payment {
    flex: 1 0 auto;
    max-width: 100%;
    color: #005a54;
}

@media (max-width: 767px) {
    #retSlideArrow,
    #advSlideArrow,
    #retSlide2Arrow,
    #advSlide2Arrow,
    #retSlide3Arrow,
    #advSlide3Arrow {
        display: none;
    }

    .bx-wrapper .bx-pager.bx-default-pager a {
        margin: 0 10px;
        border-radius: 0;
    }

    .slideCell {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    #mp-menu .mp-level ul li.level2.disablelink > a.MobMenChevron {
        pointer-events: none;
    }

    .lillBasket #divBagItemsChild {
        max-height: calc(100vh - 85px) !important;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
}

@media (min-width: 768px) {
    .divCTA {
        text-align: left;
        display: block;
        height: 100%;
        padding: 11% 0 0 7%;
    }

    .imgCopy {
        margin: 0 0 18%;
    }

    .largeTxt.imgCopy {
        margin-top: 11%;
    }

    .aCTAlink {
        width: auto;
    }

    .leftSlide {
        padding-left: 0;
    }

    .rightSlide {
        padding-right: 0;
    }

    .sliderTwo img {
        padding-bottom: 0;
    }

    .botMargin10 {
        margin-bottom: 10px;
    }

    .sliderThree img {
        padding-bottom: 0;
    }

    .sliderThree .lgeImgWrap .callOutimg {
        margin-bottom: -5px;
    }

    .nlPadding {
        padding-left: 0;
    }

    .nrPadding {
        padding-right: 0;
    }
}

.SD_NEW_ROWFIVE .callOut {
    margin-top: 10px;
}

@media (max-width: 767px) {
    .noPadding {
        padding: 0;
    }

    .SD_NEW_ROWFIVE .callOut {
        margin-top: 0;
    }

    .SD_NEW_ROWTHREE .sliderWrapper {
        padding: 0;
        margin-bottom: 0;
    }

    .sliderThree .lgeImgWrap .callOutimg {
        margin-bottom: 10px;
    }

    .bxslider2,
    .bxslider2 li {
        width: 100% !important;
    }
}

#Body {
    font-size: 11px !important;
    font-weight: var(--fw-normal);
    font-style: normal;
}

:focus {
    outline: 1px dotted #999;
}

.content {
    border: 12px solid #fff;
}

.byPostGroup,
#contactuscontent select,
#Cancelled-popup-container,
#InProgress-popup-container,
#NewOrder-popup-container,
#Rejected-popup-container,
#Shipped-popup-container,
#Submitted-popup-container,
.csHomeTable,
#divBagItems {
    border: 1px solid #ccc;
}

.prc_commentpostingpanelcommentsectiontextboxcomment,
.prc_ModShow_Textbox {
    border: 2px solid #ccc;
}

.DontForgetText {
    border-bottom: 1px solid #b2b2b2;
}

.RadTabStripTop_Vista .rtsLevel .rtsLink,
.RadTabStripTop_Vista .rtsLevel .rtsOut,
.RadTabStripBottom_Vista .rtsLevel .rtsLink {
    background-image: none !important;
}

.whiteBoxBrand .greyBgBrand.boxContentBrand,
.whiteBoxBrand {
    background: none repeat scroll 0 0 #fff !important;
}

.whiteBoxBrand .boxContentBrand {
    border: none !important;
}

.c_BlackTitle h3 {
    border-bottom: 2px solid #ccc;
}

.relatedquestions h4 {
    border-bottom: 1px dotted #ccc;
}

hr {
    border-top: 1px solid #ccc;
}

.ui-slider .ui-slider-handle {
    border: 1px solid #999;
}

.productFilter-slider {
    border: 0;
}

#pnlWebPercentOff {
    padding-top: 5px;
}

.weWrap {
    font-size: 1em;
}

.lillBasket #divBagItems,
#divAjaxLoaderImage,
#divEmptyErrorMessage {
    box-shadow: none;
    border: 1px solid #efefef;
    z-index: 10;
    bottom: 0;
    top: auto;
    border-radius: 4px 4px 0 0;
}

.lillBasket .innerdivBagItems {
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.lillBasket #divButtons {
    padding: 10px 25px 25px;
}

.lillBasket #aCheckout {
    padding: 16px 5px;
    border-radius: 4px;
    background-color: #80e096;
    margin-top: 15px;
}

.lillBasket #aCheckout:hover {
    background-color: #96eaa9;
}

.lillBasket #aCheckout span {
    color: #000;
}

.lillBasket #divBagItemsChild {
    padding: 0;
    max-height: calc(100vh - 117px) !important;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.lillBasket .ColrandSize {
    display: flex;
    width: 65%;
    flex-basis: 65%;
    flex-wrap: wrap;
    order: 3;
    padding-right: 0;
    padding-top: 0;

    .BaskColr,
    .BaskSize {
        display: flex;
        flex-basis: 100%;
        flex-grow: 1;
        width: 100%;
    }
}

.lillBasket .BaskQuant {
    padding: 0;
    display: flex;
    order: 4;
    flex-basis: 35%;
    width: 35%;
    justify-content: flex-end;
    text-align: right;
}

#divBagItems .fulfilledBy {
    width: 70%;
    clear: left;
}

.lillBasket .BaskName {
    height: auto;
    width: 65%;
    display: flex;
    flex-basis: 65%;
    order: 1;
    color: #000;
}

.lillBasket .liPrdLnk:hover .BaskName,
.lillBasket .liPrdLnk:focus .BaskName {
    text-decoration: underline;
    color: #000;
}

.lillBasket #ulBag li span {
    line-height: 1.4;
    color: #000;
    height: auto;
}

.lillBasket #ulBag li .maxText {
    float: right;
    padding-right: 0;
    clear: right;
    max-width: 35%;
}

.lillBasket #ulBag li .lineProblems {
    clear: left;
    max-width: 65%;
}

.lillBasket .PriceandCross {
    width: auto;
    float: none;
    display: flex;
    flex-basis: 100%;
    order: 6;

    .removeClass {
        justify-content: center;
        align-items: center;
        justify-items: center;
        display: flex;
    }
}

.lillBasket .bagMoveToWishlistContainer {
    width: auto;
    float: none;
    display: flex;
    flex-basis: 100%;
    order: 5;
    margin-top: 10px;
}

.lillBasket #divBagTotalDiscount {
    font-size: 14px;
    color: #fe0000;
    text-align: left;
}

.lillBasket #divButtons .SubBask {
    display: flex;
}

.lillBasket #divButtons .SubBask > div {
    width: 50%;
    flex: 1 1 auto;
}

.lillBasket .PriceandCross,
.lillBasket .bagMoveToWishlistContainer,
.lillBasket .BaskName,
.lillBasket .BaskQuant,
.lillBasket .ColrandSize .BaskColr,
.lillBasket .ColrandSize .BaskSize {
    color: #000;
}

.lillBasket #lblBagSubTotal,
.lillBasket #spanBagSubTotalValue {
    font-size: 14px;
    font-weight: inherit;
}

.lillBasket .global-icon-tick {
    margin-left: 10px;
}

.lillBasket .basketTotalItemsHeader {
    padding-left: 10px;
}

.lillBasket .BaskPrice {
    display: flex;
    flex-basis: 35%;
    justify-content: flex-end;
    order: 2;
    color: #000;
}

.lillBasket .chkouterror {
    margin: 6px 0 16px;
    background-color: hsl(3 79% 95% / 1);
    padding: 8px 16px;
    text-align: center;
    font-weight: var(--fw-bold);
}

#divulBagParent {
    flex: 1 1 auto;
    overflow: auto !important;
    scrollbar-width: thin;
    max-height: 50vh;
    scrollbar-color: #0000ed #ddd;
}

.Responsive #divulBagParent {
    overflow: auto !important;
}

#divulBagParent::-webkit-scrollbar {
    width: 5px;
}

#divulBagParent::-webkit-scrollbar-track {
    background: #ddd;
}

#divulBagParent::-webkit-scrollbar-thumb {
    background: #0000ed;
    border-radius: 5px;
    border: none;
}

.lillBasket .navPage {
    display: block !important;
    min-height: 0;
}

.lillBasket .BaskColr::before {
    content: "Colour:";
    display: inline-block;
    margin-right: 5px;
}

.lillBasket .BaskSize::before {
    content: "Size:";
    display: inline-block;
    margin-right: 5px;
}

.lillBasket #aViewBag {
    width: 100%;
    line-height: unset;
    border: 0;
    padding: 0;
    color: #000 !important;
    margin-bottom: 0;
}

.lillBasket #aViewBag:hover {
    background-color: #f8f8f8 !important;
    color: #000 !important;
}

.lillBasket #aViewBag span {
    display: block;
    border: solid 1px #000;
    border-radius: 4px;
    padding: 15px;
}

.lillBasket #spanCheckout {
    width: 100%;
}

.lillBasket #aCheckout,
.lillBasket #aViewBag {
    font-size: 16px;
    font-family: var(--brand-font);
    font-weight: var(--fw-bold);
    text-transform: none;
}

.lillBasket .summaryWrapCTA {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.lillBasket #ulBag {
    font-size: 1em;
}

.lillBasket #ulBag a {
    width: auto;
    float: none;
    color: #000;
    display: flex;
    padding: 10px 0;
    align-items: center;
}

.lillBasket #ulBag a:hover {
    text-decoration: underline;
}

.lillBasket #ulBag li {
    border-bottom: solid 1px #e7ebed;
    padding: 20px 0 10px;
    position: relative;
    margin: 0 24px;
}

.lillBasket #ulBag li:last-of-type {
    border-bottom: 0;
}

.lillBasket .bagContentItemWrap {
    font-size: 14px;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    overflow-y: auto;
    padding-bottom: 0;
}

.lillBasket .PreviousEnable,
.lillBasket .NextEnable,
.lillBasket .PreviousDisable,
.lillBasket .NextDisable,
.wishlist-summary .items-nav {
    font-size: 11px;
    background-size: 1000px 1000px;
    opacity: 0.3;
    background-position: -228px -560px;
}

.lillBasket .NextEnable,
.lillBasket .NextDisable,
.wishlist-summary .items-nav[data-action="next"] {
    background-position: -228px -521px;
}

.lillBasket .PreviousEnable,
.lillBasket .NextEnable,
.wishlist-summary .items-nav:hover,
.wishlist-summary .items-nav:focus,
.wishlist-summary .items-nav.PreviousEnable,
.wishlist-summary .items-nav.NextEnable {
    opacity: 1;
}

.lillBasket a.removeClass::before,
.s-basket-remove-button a .deleteItemIcon {
    width: 20px;
    display: inline-block;
    vertical-align: middle;
    height: 20px;
    font-size: 11px;
    background-size: 700px 700px;
    background-position: -519px -337px;
    margin-right: 4px;
    zoom: 1.1;
}

.lillBasket a.bagMoveToWishlistClass::before {
    width: 20px;
    display: inline-block;
    vertical-align: middle;
    height: 20px;
    font-size: 11px;
    background-size: 700px 700px;
    background-position: -210px -370px;
    content: "";
    margin-right: 4px;
    zoom: 1.1;
}

.s-basket-remove-button a .deleteItemIcon {
    text-indent: -9999px;
}

.lillBasket a.removeClass::before {
    content: "";
}

.s-basket-remove-button a:hover .deleteItemIcon,
.lillBasket a.removeClass::before:hover,
.lillBasket a.bagMoveToWishlistClass::before:hover {
    background-position: -394px -307px;
}

.updateIcon,
.BasketNew .BasketQuantBut::before,
.BasketNew .BasketQuantBut.s-basket-plus-button::before,
.Responsive .BasketNew #BasketDiv .NewUpdateQuant:hover .updateIcon {
    background-size: 770px 770px;
    background-position: -204px -318px;
}

.BasketNew .BasketQuantBut::before {
    background-position: -155px -351px;
}

.BasketNew .BasketQuantBut.s-basket-plus-button::before {
    background-position: -199px -351px;
}

@media (min-width: 768px) {
    .lillBasket #divBagItems,
    #divAjaxLoaderImage,
    #divEmptyErrorMessage {
        border-radius: 0;
        bottom: auto;
        top: 48px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    }

    .lillBasket #divBagItems {
        background-color: transparent;
        border: 0;
    }

    .lillBasket #divBagItems::before {
        content: "";
        display: block;
        width: 24px;
        height: 12px;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid #fff;
        position: absolute;
        right: 17px;
        top: 0;
    }

    .lillBasket .innerdivBagItems {
        background-color: #fff;
        margin-top: 12px;
        max-height: calc(95vh - 85px) !important;
        overflow: hidden;
    }

    .lillBasket #divBagItemsChild {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
}

.infoTabPage h2,
.infoaccordion .panel-body h2 {
    font-size: 1.1em;
}

.currencySelector li {
    position: relative;
    width: 20%;
}

.currencySelector li label {
    font-size: 1.1em;
}

.currencySelector .languageSwitcherRadioButton {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    margin: 13px !important;
}

.currencySelector .languageSwitcherLabel {
    position: relative;
    padding-left: 32px;
    font-size: 14px;
}

.currencySelector .languageSwitcherLabel::before,
.currencySelector .languageSwitcherLabel::after,
.languageSelector li a::before,
.languageSelector li a::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 50%;
}

.currencySelector .languageSwitcherLabel::before,
.languageSelector li a::before {
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #d0cfcd;
}

.currencySelector .languageSwitcherLabel::after,
.languageSelector li a::after {
    top: 3px;
    left: 3px;
    width: 14px;
    height: 14px;
    opacity: 0;
    background-color: #0000ed;
}

.currencySelector .languageSwitcherRadioButton:hover + .currencySelector .languageSwitcherLabel::after,
.currencySelector .languageSwitcherLabel:hover::after,
.languageSelector li a:hover::after {
    opacity: 0.5;
}

.currencySelector .activeHover .languageSwitcherLabel::before,
.currencySelector .languageSwitcherLabel:hover::before,
.languageSelector li a.isActive::before,
.languageSelector li a:hover::before {
    border: 1px solid #0000ed;
}

.currencySelector .activeHover .languageSwitcherLabel,
.currencySelector .languageSwitcherLabel:hover {
    text-decoration: underline;
    color: #0000ed;
}

.currencySelector .languageSwitcherRadioButton:checked + .currencySelector .languageSwitcherLabel::after,
.currencySelector .activeHover .languageSwitcherLabel::after,
.languageSelector li a.isActive::after {
    opacity: 1;
}

.currencyCountryHeader {
    font-size: 22px;
    text-transform: uppercase;
    padding-bottom: 10px;
}

.currencyLanguageSlider #divCurrencySelector {
    border-bottom: 1px solid #e7ebed;
    padding-bottom: 20px;
}

.currencyLanguageSlider #divCurrencySelector .currencyHeader,
#divLanguageSelector span.languageHeader {
    font-size: 16px;
    text-transform: inherit;
    border-bottom: none;
    display: block;
}

.languageSelector li {
    width: 20%;
}

.HelpIco {
    background-position: -11px -194px;
    background-size: 500px 500px;
}

.s-checkout-fix .secondary a,
.BagandCheckoutBtn .secondary2 a {
    border: 0;
}

.SliderContain .ui-slider-horizontal .ui-slider-handle {
    margin-left: -3px;
    margin-right: 0;
}

.fieldcheckbox {
    width: 62%;
}

#address-sec {
    margin: 0;
}

.dontForget,
.dontForget img {
    width: 100%;
    height: auto;
}

.ui-state-default a,
.ui-widget-content .ui-state-default a,
.ui-widget-header .ui-state-default a {
    border: 1px solid #0463c4;
}

.ui-state-active a,
.ui-widget-content .ui-state-active a,
.ui-widget-header .ui-state-active a {
    border: 1px solid #000;
}

#navigation-container {
    margin: 1% 0 2%;
}

.wrapper h1 {
    overflow: hidden;
}

#HeaderGroup {
    box-shadow: none;
    background-color: transparent;
    top: 0;
    height: 114px;
    z-index: 11 !important;
}

.ContentWrapper {
    padding: 0;
}

.Basket .ContentWrapper,
.Profile .ContentWrapper,
.Login .ContentWrapper,
.Registration .ContentWrapper,
.wishlist-page,
.StoreLocator .ContentWrapper {
    width: 100%;
    max-width: 1440px;
    padding: 10px 15px 15px;
    margin: 0 auto;
}

#BodyWrap .ContentWrapper {
    padding-bottom: 46px;
}

.ToplinksGroup,
.FooterGroup,
.dbc,
.FooterPane,
.FooterPane2 {
    margin: 0 auto;
    max-width: 1440px;
    display: block;
    float: none;
    position: relative;
    padding: 0 10px;
}

.ToplinksGroup {
    max-width: 1920px;
    padding: 0;
}

.ToplinksGroup .container-fluid > .row > .search {
    transition: none;
    transform: none;
}

#languageCurrencySelectModel .modal-dialog {
    width: 100%;
    max-width: 970px;
}

#languageCurrencySelectModel .modal-header {
    background-color: transparent;
    border: 0;
}

#languageCurrencySelectModel .modal-body {
    padding: 0 15px 30px;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.languageSelector li a {
    position: relative;
    padding: 0;
}

.languageSelector a:hover {
    text-decoration: underline;
    color: #0000ed;
}

.languageSelector .isActive .countryName {
    text-decoration: underline;
    color: #0000ed;
}

.languageSelector span.countryName {
    font-size: 14px;
    padding-left: 32px;
    width: 100%;
}

.languageSelector .flag {
    display: none;
    background: none;
}

#divCurrencyLanguageSelector {
    height: 100%;
    float: none;
    width: 100%;
}

#divCurrencyLanguageSliding {
    width: 100%;
}

.currencyLanguageSlider {
    top: 0;
    border: 0;
    box-shadow: none;
    position: relative;
}

.MenuGroupGAME {
    display: block;
}

.MenuGroupUSC {
    display: block;
}

#topMenuWrapper {
    top: 60px;
    background-color: #40454a;
    z-index: 1 !important;
}

.menu-trigger {
    background-image: none;
    background-color: transparent !important;
    height: 60px;
    position: absolute;
}

.menu-trigger:hover {
    background-color: transparent !important;
}

.menu-trigger .global-icon {
    display: inline;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.menu-trigger .global-icon-menu svg {
    width: 24px;
    height: 24px;
}

.menu-trigger .global-icon-cross {
    display: none;
}

.menu-trigger .global-icon-cross svg {
    width: 20px;
    height: 20px;
}

.PullMenuActive .menu-trigger .global-icon-cross {
    display: inline;
}

.PullMenuActive .menu-trigger .global-icon-menu {
    display: none;
}

.TopNavBar {
    background-color: #1c1c1c;
    height: 60px;
    z-index: 2;
}

.ToplinksGroup .bsLogo {
    height: 100%;
}

#currencyLanguageSelector {
    background: none transparent;
    border: 0;
    height: 100%;
    min-width: 100%;
}

.ToplinksGroup .search {
    padding: 0 5px;
}

.ToplinksGroup .search .dvSearch {
    background-color: #e6eaec;
    border-radius: 4px;
    z-index: 100;
    transition: background-color 0.25s linear 0s;
}

.ToplinksGroup .search.activeHover .dvSearch {
    background-color: #fff;
}

.ToplinksGroup .search #cmdSearch {
    width: 38px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
}

.ToplinksGroup .search #cmdSearch .global-icon-search {
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: middle;
}

.ToplinksGroup .search #txtSearch {
    border: none;
    position: relative;
    width: 100%;
    height: 44px;
    font-size: 16px;
    transition: none;
    padding: 5px 5px 5px 38px;
    background-color: transparent;
    color: #0000ed;
}

.ToplinksGroup .search #txtSearch:focus,
.ToplinksGroup .search.activeHover #txtSearch {
    border: none;
    background-color: transparent;
    padding-right: 47px;
}

.ToplinksGroup .search #txtSearch::placeholder {
    color: #888 !important;
}

.dvSearch .TextBoxClear {
    top: 0;
    right: 0;
    height: 44px;
    text-align: center;
    width: 38px;
    line-height: 44px;
    border: none;
    box-shadow: none;
    font-size: 11px;
    font-family: inherit;
    transform: none;
}

.dvSearch .TextBoxClear::before {
    content: "";
    display: inline-block;
    width: 30px;
    font-size: 11px;
    vertical-align: middle;
    height: 30px;
    background-size: 800px 800px;
    background-position: -478px -381px;
}

.LanguageCurrency {
    margin: 0;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector {
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector span {
    display: none;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .languageRoot {
    padding: 0 8px;
    font-size: 15px;
    color: #fff;
    display: inline-block;
    order: 1;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .editCountryCurrency {
    display: inline-block;
    font-size: 15px;
    color: #888;
    order: 3;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector .editCountryCurrency:hover {
    text-decoration: underline;
    text-decoration-color: #888;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector p {
    float: none;
    font-size: 15px;
    padding: 0 10px 0 0;
    order: 2;
    line-height: normal;
    color: #fff;
}

.currencyLanguageSelector .spanCurrencyLanguageSelector p::before {
    content: "(";
}

.currencyLanguageSelector .spanCurrencyLanguageSelector p::after {
    content: ")";
}

.ControlWrap,
.ControlWrap > div {
    float: right;
}

.ControlWrap .bagWishBlock,
.ControlWrap .accountBlock,
.ControlWrap .accountBlock .login,
.accountBlock .AccountLink,
.accountBlock .SignInLink {
    height: 100%;
}

.ControlWrap .bagWishBlock {
    text-align: center;
}

.bagWishBlock .WishIcon {
    background: none;
    width: auto;
}

.ControlWrap .accountBlock {
    line-height: 60px;
    text-align: center;
    width: 60px;
}

.ControlWrap .WishList {
    width: 60px;
    height: 100%;
    font-size: 1em;
}

.ControlWrap .WishList #aWishListLink {
    width: 100%;
    line-height: 60px;
    overflow: visible;
}

.ControlWrap .WishList .wishQuantityContainer {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 11px;
    float: none;
    background: none;
}

.ControlWrap .WishList .wishQuantityContainer .global-icon-wishlist svg {
    height: 28px;
    width: 30px;
}

#divWishList .wishlist-summary .wishlist-summary-footer .ctaBtn {
    font-size: 16px;
    color: #000;
}

.basket-summary-continue-button-container .ContinueOn,
.Basket .elevated-cart .PromoCodeBut {
    text-transform: capitalize;
}

.Basket .elevated-cart.elevated-cart-is-empty .cart-page__primary-button,
.wishlist-page__primary-button {
    text-transform: none;
    letter-spacing: normal;
}

.SignInLink .login .userLoginContainer {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.ControlWrap .WishList #lblWishListCount {
    background-color: #80e096;
    color: #000;
    min-height: 22px;
    border-radius: 50%;
    height: auto;
    margin: 0;
    min-width: 22px;
    position: absolute;
    top: -8px;
    left: 60%;
    width: auto;
    padding: 0;
    text-align: center;
    font-size: 12px;
    line-height: 1;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ControlWrap .WishList #lblWishList,
.ControlWrap .WishList #lblWishListCount.hide-wishlist {
    display: none;
}

.ControlWrap .accountBlock .login {
    display: block;
}

.ControlWrap .accountBlock .login .ico {
    display: inline-block;
    vertical-align: middle;
    font-size: 11px;
}

.ControlWrap .accountBlock .login .hidden-xs {
    display: none;
}

.ControlWrap .storeGroup #topLinkMenu .TopLink {
    line-height: normal;
    float: none;
}

.ControlWrap .storeGroup #topLinkMenu .TopLink a {
    min-width: 55px;
}

.ControlWrap .storeGroup #topLinkMenu .TopLink a::before {
    content: "";
    display: block;
    height: 28px;
    width: 26px;
    margin: 0 auto;
    background-size: 630px 630px;
    background-position: -46px -163px;
    font-size: 12px;
}

.ControlWrap .storeGroup #topLinkMenu .TopLink a span {
    color: #fff;
    line-height: normal;
    display: block;
    margin-top: 3px;
}

.FooterPane,
.FooterPane2 {
    padding: 0;
}

ul.StoreList li {
    width: auto;
    padding: 0 6px;
    float: left;
}

ul.StoreList {
    height: 26px;
    display: inline-block;
    padding-right: 17px;
}

ul.StoreList li a {
    color: #0051a1;
}

ul.StoreList li a:hover {
    text-decoration: underline;
    color: #ef2010;
}

.TopLinkMenu {
    display: flex;
    align-items: center;
    justify-content: center;
}

#topLinkMenu {
    width: 100%;
}

#topLinkMenu ul li {
    text-align: left;
}

#topLinkMenu:hover .TopLinkDrop .TopSubLinkMenu {
    max-height: 600px;
}

#topLinkMenu.disable-transitions .TopSubLinkMenu {
    display: none;
    transition: none;
}

#topLinkMenu .TopLinkMenu .TopLink a .loggedoutTxt {
    display: none;
}

#topLinkMenu ul li.TopLinkDrop ul {
    width: 400px;
    display: none;
    padding: 0;
    right: 0;
    position: absolute;
    top: 48px;
}

#topLinkMenu ul li.TopLinkDrop ul::before {
    content: "";
    display: none;
    position: absolute;
    width: 24px;
    height: 13px;
    top: 0;
    right: 18px;
    border-bottom: 12px solid #fff;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
}

#topLinkMenu ul li.TopLinkDrop ul:hover::before,
#topLinkMenu ul li.TopLinkDrop a:hover + ul::before {
    display: block;
}

#topLinkMenu ul li.TopLinkDrop ul li,
#topLinkMenu ul li.TopLinkDrop ul.TopSubLinkMenu li a {
    width: 100%;
    white-space: nowrap;
}

#topLinkMenu .TopSubLinkMenu .user-name {
    font-size: 16px;
}

#topLinkMenu .TopSubLinkMenu .user-name a {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

#topLinkMenu ul li.TopLinkDrop ul li {
    background-color: #fff !important;
    border-bottom: 1px solid #efefef;
    border-left: 1px solid #efefef;
    border-right: 1px solid #efefef;
    line-height: 2.5em;
}

#topLinkMenu ul li.TopLinkDrop ul > li a .logoutTxt::after {
    content: "";
    display: block;
    height: 24px;
    width: 24px;
    background-size: 600px;
    background-position: -310px -376px;
    float: right;
}

#topLinkMenu ul li.TopLinkDrop ul li.FirstTopSub {
    border-top: 1px solid #efefef;
    margin-top: 12px;
}

#topLinkMenu ul li.TopLinkDrop ul li.AccOrderIcon {
    border-bottom: 1px solid #9a9a9a;
}

#topLinkMenu ul li.TopLinkDrop ul li.SignOut {
    text-align: center;
}

#topLinkMenu ul li.TopLinkDrop ul li.SignOut a {
    display: inline-block;
    padding: 15px;
    letter-spacing: 0.1em;
    text-align: left;
}

#topLinkMenu ul.TopLinkMenu > li > a {
    font-size: 1em;
    line-height: 1em;
}

#topLinkMenu ul.TopLinkMenu > li > a span.logoutTxt {
    display: none;
    height: auto;
    margin-top: 3px;
    color: transparent;
}

#topLinkMenu ul.TopLinkMenu > li {
    width: auto;
    line-height: 42px;
    border: 0;
    position: relative;
    flex: 1 1 auto;
}

#topLinkMenu ul li.TopLink a {
    padding: 0;
    background-image: none;
    background-position: initial;
    background-repeat: no-repeat;
    height: 100%;
    display: block;
    color: #333;
}

#topLinkMenu ul li.TopLink > a {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
}

@media (min-width: 768px) {
    .mp-container .mp-menu {
        display: none;
    }

    .footerInfoSection #topLinkMenu {
        width: 66.666667%;
    }

    .footerLanguageSwitcher {
        width: 33.333333%;
    }

    #topLinkMenu ul li.TopLink > a {
        flex-direction: row;
    }

    .ControlWrap .accountBlock .login:hover,
    .ControlWrap .accountBlock:hover,
    .ControlWrap .WishList #aWishListLink:hover,
    #divBagTotalLink a#aBagLink:hover {
        background-color: #000 !important;
    }
}

@media (min-width: 1022px) {
    #topLinkMenu ul li.iconsCS a {
        justify-content: flex-start;
        margin-left: 50px;
    }

    .currencyLanguageSelector .spanCurrencyLanguageSelector {
        justify-content: flex-end;
        padding-right: 50px;
    }
}

@media (min-width: 1336px) {
    #topLinkMenu ul li.iconsCS a {
        margin-left: 80px;
    }

    .currencyLanguageSelector .spanCurrencyLanguageSelector {
        padding-right: 80px;
    }
}

@media (min-width: 1920px) {
    #topLinkMenu ul li.iconsCS a {
        margin-left: 150px;
    }

    .currencyLanguageSelector .spanCurrencyLanguageSelector {
        padding-right: 150px;
    }
}

#topLinkMenu ul.TopLinkMenu .iconsStores a {
    padding-top: 20px;
    padding-bottom: 20px;
}

@media (min-width: 768px) {
    #topLinkMenu ul.TopLinkMenu .iconsStores a {
        border-left: 1px solid #34373b;
        border-right: 1px solid #34373b;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

#topLinkMenu ul.TopLinkMenu > li span,
.RightBarLinks div.TopLinkMenu > a span {
    color: #000;
    font-size: 14px;
    display: inline-block;
    line-height: 1.4em;
}

#topLinkMenu ul.TopLinkMenu .TopSubLinkMenu > li span {
    width: 100% !important;
    vertical-align: middle;
    line-height: 1.5em;
}

#topLinkMenu ul li.TopLink > a > .ico {
    height: 30px;
    background-size: 800px 800px;
    background-position: -23px -429px;
    background-repeat: no-repeat;
    width: 30px;
    display: inline-block;
    vertical-align: middle;
}

#topLinkMenu .TopSubLinkMenu .ico {
    display: none;
}

#topLinkMenu .TopLinkMenu .TopSubLinkMenu li a {
    padding: 15px;
    background: none;
    background-color: #fff;
    transition: background-color 0.3s ease-in;
}

#topLinkMenu .TopLinkMenu .TopSubLinkMenu li a:hover {
    background-color: #f5fafc;
}

#topLinkMenu ul li.TopLinkDrop ul li.SignOut a span {
    color: #ed0000;
    text-align: left;
}

span#bagTotal {
    min-width: 60px;
}

#divBagTotalLink a#aBagLink {
    width: 100%;
    display: block;
    height: 100%;
    background: none;
    padding: 0;
    min-width: 60px;
    line-height: 60px;
}

#divBagTotalLink a#aBagLink #bagQuantityContainer {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    margin-bottom: 1px;

    .global-icon-bag {
        width: 28px;
        height: 28px;

        svg {
            height: 28px;
            width: auto;
        }
    }
}

.active #divBagTotalLink a#aBagLink:hover + .lillBasket #divBagItems {
    display: block;
}

#divBagTotalLink a#aBagLink #bagTotalContainer,
#divBagTotalLink a#aBagLink .bagIcon {
    display: none;
}

.Responsive #divBag {
    width: 60px;
    padding: 0;
    font-size: 1em;
}

.ASCdelivery2 .FullAdvert {
    margin-bottom: 5px;
}

.FourHovers ul li > a {
    margin-bottom: 0;
}

.FourHovers ul li > div {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}

.advert .swiper-container {
    margin: 0 auto 20px;
}

.lillBasket #aBagLink span#bagQuantity {
    background: none;
    background-color: #80e096;
    color: #000;
    min-height: 22px;
    border-radius: 50%;
    margin: 0;
    padding: 0;
    min-width: 22px;
    height: auto;
    position: absolute;
    top: -8px;
    left: 54%;
    width: auto;
    text-align: center;
    font-size: 12px;
    line-height: 1;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lillBasket #aBagLink span#bagQuantity.empty,
.lillBasket #aBagLink span#bagQuantity.hide-count {
    display: none;
}

.basketLink .langlogin .login {
    display: block;
    padding-top: 12px;
    padding-left: 35px;
    height: 100%;
    font-size: 1.1em;
}

.Home .ContentWrapper {
    padding: 0;
    max-width: 1920px;
}

section.mainBody > .row > div {
    min-height: 0;
}

#findAstore a {
    padding-top: 12px;
    display: block;
    padding-left: 22px;
    height: 100%;
    font-size: 1.1em;
}

.storefind {
    display: block;
    height: 40px;
}

.basketLink {
    height: 40px;
    padding: 0;
}

#divCookieAcceptance {
    background-color: #1c1c1c;
}

#cookieContentContainer {
    max-width: 2000px;
}

#divCookieAcceptance #inputAcceptCookies {
    background-color: #1c1c1c !important;
    height: 20px;
    font-size: 14px;
}

#divCookieAcceptance #cookieContentContainer p {
    color: #fff;
    text-align: left;
    font-size: 14px;
}

@media (min-width: 768px) {
    #divCookieAcceptance #cookieContentContainer p {
        text-align: center;
    }
}

.PopToRight,
.CardsSocial,
#dnn_footerPaneR {
    display: none;
}

.coprightmega {
    text-align: left;
}

.Responsive .coprightmega {
    padding-top: 7px;
}

.FooterSubGroup .FooterHeader {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: var(--fw-bold);
    color: #fff;
    border: none;
    float: none;
}

.FooterGroupLink li {
    line-height: 1.6em;
    padding: 0;
}

.FooterGroup4 .FooterGroupLink a::before {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 10px;
    background-size: 766px 922px;
    width: 20px;
    height: 20px;
}

.FooterGroup4 .FooterGroupLink li:nth-child(1) a,
.FooterGroup4 .FooterGroupLink li:nth-child(2) a,
.FooterGroup4 .FooterGroupLink li:nth-child(3) a,
.FooterGroup4 .FooterGroupLink li:nth-child(4) a,
.FooterGroup4 .FooterGroupLink li:nth-child(5) a {
    padding-left: 38px;
    position: relative;
}

.FooterGroup4 .FooterGroupLink li:nth-child(1) a::before {
    background-position: -651px -404px;
}

.FooterGroup4 .FooterGroupLink li:nth-child(2) a::before {
    background-position: -685.5px -403.5px;
}

.FooterGroup4 .FooterGroupLink li:nth-child(3) a::before {
    background-position: -685.5px -403.5px;
}

.FooterGroup4 .FooterGroupLink li:nth-child(4) a::before {
    background-position: -722px -404px;
}

.FooterGroup4 .FooterGroupLink li:nth-child(5) a::before {
    background-position: -722px -491px;
}

.FooterGroupLink li a {
    padding: 5px 10px;
    font-size: 14px;
    color: #fff;
    line-height: 1.5;
}

.FooterGroupLink li a:hover {
    text-decoration: underline;
}

#FootcmdSearch {
    padding: 7px 4% 7px 6%;
}

li.shopAllLink a {
    font-size: 1.3em;
    text-transform: uppercase;
    font-weight: var(--fw-bold) !important;
}

nav#topMenu > ul .NewMenuWrap li.sdmColHeader,
nav#topMenu > ul .NewMenuWrap li.sdmColSubHeader,
nav#topMenu > ul .NewMenuWrap li.sdmColHeaderHidden {
    height: 32px;
    border-bottom: 1px solid #bbb;
}

li.sdmColTopHeader a {
    font-weight: var(--fw-bold) !important;
    font-size: 1.3em !important;
    text-decoration: underline;
}

#divBag #divBagTotalLink {
    padding: 0;
    font-size: 1em;
}

#divBag #bagName {
    display: none;
}

.megacards span {
    margin: 10px 0;
}

.back-to-top {
    display: none !important;
}

.productFiltersReset a:hover {
    background-color: #333;
    color: #fff;
}

nav#topMenu ul li.root .SubMenuWrapper {
    width: 100%;
    position: absolute;
    top: auto;
    text-align: center;
    font-size: 1.1em;
    border: 0;
    padding: 0;
    left: 0;
}

nav#topMenu ul li.root .SubMenuWrapper > ul {
    width: 100%;
    max-width: 1920px;
    text-align: center;
    margin: 0 auto;
    background-color: #fff;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
}

.s-breadcrumbs-container .s-breadcrumbs-bar {
    font-size: 1.1em;
}

.s-breadcrumbs-bar > span ol li {
    color: #636363;
}

.s-breadcrumbs-bar > span ol li.last {
    padding: 0;
}

nav#topMenu ul li.root .SubMenuWrapper > ul::before,
nav#topMenu ul li.root .SubMenuWrapper > ul::after {
    content: "";
    display: table;
}

nav#topMenu ul li.root .SubMenuWrapper > ul::after {
    clear: both;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.Center {
    width: 100%;
    padding: 5px 0 20px;
    float: none;
    max-width: 1500px;
    margin: 0 auto;
    text-align: left;
}

nav#topMenu ul li.root.promoEnabled .SubMenuWrapper ul li.Center::before,
nav#topMenu ul li.root.promoEnabled .SubMenuWrapper ul li.Center::after {
    content: "";
    display: table;
}

nav#topMenu ul li.root.promoEnabled .SubMenuWrapper ul li.Center::after {
    clear: both;
}

nav#topMenu ul li.root.promoEnabled .SubMenuWrapper ul li.Center > div.Center {
    width: 16.666667%;
    float: right;
}

nav#topMenu ul li.root.promoEnabled .SubMenuWrapper ul li.Center > ul {
    width: 83.333333%;
    float: left;
}

nav#topMenu ul li.root .SubMenuWrapper ul li .twoStackFlex {
    display: flex;
    align-items: center;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.logoswithText .twoStackFlex {
    align-items: normal;
}

nav#topMenu ul li.MenuGroupBrands.promoEnabled .SubMenuWrapper ul li.Center > div.Center .twoStackFlex {
    width: 50%;
}

nav#topMenu ul li.MenuGroupBrands.promoEnabled .SubMenuWrapper ul li.Center > div.Center {
    padding: 0 6% 0 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

nav#topMenu ul li.root .SubMenuWrapper ul li .twoStackItem {
    width: 50%;
    padding: 0 10px 30px;
}

nav#topMenu ul li.root .SubMenuWrapper > ul li.promoEnabled .twoStackFlex .twoStackItem a {
    padding: 0;
    display: block;
    width: 100%;
}

nav#topMenu ul li.root .SubMenuWrapper ul li .twoStackItem img {
    max-height: 75px;
    margin: 0 auto;
    max-width: 100px;
}

nav#topMenu ul li.root .SubMenuWrapper ul li .twoStackItem .twoStackCTA {
    display: none;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.logoswithText .twoStackItem .twoStackCTA {
    display: block;
    text-align: center;
    padding: 5px 0;
    visibility: hidden;
    opacity: 0;
    transition: 0.25s linear;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.logoswithText .twoStackItem a:hover .twoStackCTA {
    opacity: 1;
    visibility: visible;
}

nav#topMenu ul li.MenuGroupBrands.promoEnabled .SubMenuWrapper ul li.Center > ul,
nav#topMenu ul li.MenuGroupBrands.promoEnabled .SubMenuWrapper ul li.Center > div.Center {
    width: 50%;
    float: left;
}

nav#topMenu li.root .SubMenuWrapper li.BrandsBottom.Bottom .fourPromoItem a::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    width: 25%;
    transform: translateX(-50%);
    display: block;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center center;
    font-size: 12px;
}

nav#topMenu li.root .SubMenuWrapper li.BrandsBottom.Bottom .fourPromoItem.whiteNikeLogo a::after {
    background-image: url("/images/marketing/sd-lv8-logo-nike.svg");
}

nav#topMenu li.root .SubMenuWrapper li.BrandsBottom.Bottom .fourPromoItem.whiteAsicsLogo a::after {
    background-image: url("/images/marketing/sd-lv8-asics-white.svg");
    width: 35%;
}

nav#topMenu li.root .SubMenuWrapper li.BrandsBottom.Bottom .fourPromoItem.whiteAdidasLogo a::after {
    background-image: url("/images/marketing/sd-lv8-logo-adidas.svg");
}

nav#topMenu li.root .SubMenuWrapper li.BrandsBottom.Bottom .fourPromoItem.whitePumaLogo a::after {
    background-image: url("/images/marketing/sd-lv8-logo-puma.svg");
}

nav#topMenu li.root .SubMenuWrapper li.BrandsBottom.Bottom .fourPromoItem.whiteKarrimorLogo a::after {
    background-image: url("/images/marketing/sd-lv8-logo-karrimor.svg");
}

nav#topMenu li.root .SubMenuWrapper li.BrandsBottom.Bottom .fourPromoItem.whiteUnderArmourLogo a::after {
    background-image: url("/images/marketing/sd-lv8-logo-ua.svg");
}

nav#topMenu li.root .SubMenuWrapper li.BrandsBottom.Bottom .fourPromoItem a .fourPromoCTA {
    display: none;
}

nav#topMenu
    li.root
    .SubMenuWrapper
    li.BrandsBottom.Bottom
    .BottomPromoRight
    .fourPromoItem.shopAllPromo
    a
    .fourPromoCTA,
.uscSite nav#topMenu li.root .SubMenuWrapper li.BrandsBottom.Bottom .fourPromoItem a .fourPromoCTA {
    display: block;
}

nav#topMenu li.root .SubMenuWrapper > ul li.level2 a {
    display: inline-block;
    width: auto;
    position: relative;
}

nav#topMenu li.root .SubMenuWrapper > ul li.level2 a::before,
nav#topMenu ul li.root .SubMenuWrapper > ul li.sdmColHeader a::before,
nav#topMenu ul li.root.MenuGroupBrands .SubMenuWrapper > ul li.sdmColHeader.level1 > a::after {
    border-bottom: 1px solid transparent;
    content: "";
    display: block;
    position: absolute;
    bottom: 5px;
    left: 5px;
    width: calc(100% - 10px);
    transition: 0.25s ease-out;
}

nav#topMenu li.root .SubMenuWrapper > ul li.level2 a:hover::before,
nav#topMenu ul li.root .SubMenuWrapper > ul li.sdmColHeader a:hover::before,
nav#topMenu ul li.root.MenuGroupBrands .SubMenuWrapper > ul li.sdmColHeader.level1 > a:hover::after {
    border-bottom: 1px solid #0000ed;
}

nav#topMenu ul li.root.MenuGroupBrands .SubMenuWrapper > ul li.sdmColHeader.level1 > a::before {
    content: "Popular ";
    display: inline;
    position: static;
    border: 0;
    bottom: auto;
    left: auto;
    width: auto;
    transition: none;
}

nav#topMenu ul li.root .SubMenuWrapper > ul li.level1.sdmColHeader li.level2 > a:hover {
    color: #0000ed;
}

@media (min-width: 1022px) {
    nav #topMenu ul li.MenuGroupBrands.promoEnabled .SubMenuWrapper ul li.Center > div.Center {
        padding: 0 10% 10px 10px;
    }
}

nav#topMenu ul li.root .SubMenuWrapper > ul > li.Top,
nav#topMenu ul li.root .SubMenuWrapper > ul > li.Left,
nav#topMenu ul li.root .SubMenuWrapper > ul > li.Right {
    display: none !important;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.Center > ul > li {
    width: 20%;
    padding: 0 5px;
}

nav#topMenu ul li.root.twoCol .SubMenuWrapper ul li.Center > ul > li {
    width: 50%;
}

nav#topMenu ul li.root.threeCol .SubMenuWrapper ul li.Center > ul > li {
    width: 33.333333%;
}

nav#topMenu ul li.root.fourCol .SubMenuWrapper ul li.Center > ul > li {
    width: 25%;
}

nav#topMenu ul li.root.fiveCol .SubMenuWrapper ul li.Center > ul > li {
    width: 20%;
}

nav#topMenu ul li.root.sixCol .SubMenuWrapper ul li.Center > ul > li {
    width: 16.666667%;
}

nav#topMenu ul li.root.GAME .SubMenuWrapper ul li.Center,
nav#topMenu ul li.root.USCFashion .SubMenuWrapper ul li.Center {
    width: 100%;
}

nav#topMenu li.root .SubMenuWrapper li.Bottom {
    width: 100%;
    display: block !important;
    float: none;
}

nav#topMenu li.root .SubMenuWrapper li .fourPromoSlot,
nav#topMenu li.root .SubMenuWrapper li .fourPromoItem {
    width: 50%;
    float: left;
    display: flex;
}

nav#topMenu li.root .SubMenuWrapper li .fourPromoItem:hover img {
    transform: scale(1.075);
}

nav#topMenu li.root .SubMenuWrapper li .fourPromoItem img {
    width: 100%;
    transition: transform 0.25s linear;
}

nav#topMenu ul li.root .SubMenuWrapper > ul li .fourPromoSlot .fourPromoItem a {
    padding: 0;
    position: relative;
    overflow: hidden;
    width: 100% !important;
    display: block !important;
}

nav#topMenu ul li.root .SubMenuWrapper > ul li .fourPromoSlot .noText .fourPromoCTA {
    display: none;
}

nav#topMenu li.root .SubMenuWrapper li .fourPromoItem a .fourPromoCTA,
nav#topMenu li.root .SubMenuWrapper li .fourPromoSlot.BottomPromoRight .fourPromoItem.shopAllPromo .fourPromoCTA {
    position: absolute;
    left: 16px;
    bottom: 16px;
    font-size: 1.05em;
    line-height: 1em;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #000;
    background-color: #fff;
    padding: 2px 3px;
    max-width: calc(100% - 40px);
    z-index: 10;
    text-align: left;
}

nav#topMenu li.root .SubMenuWrapper li .fourPromoSlot.BottomPromoRight .fourPromoItem.shopAllPromo a::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    transition: 0.8s;
    z-index: 1;
}

nav#topMenu li.root .SubMenuWrapper li .fourPromoSlot.BottomPromoRight .fourPromoItem.shopAllPromo a:hover::before {
    background-color: rgba(0, 0, 237, 0.7);
}

nav#topMenu li.root .SubMenuWrapper li .fourPromoSlot.BottomPromoRight .fourPromoItem.shopAllPromo .fourPromoCTA {
    left: 50%;
    top: 50%;
    bottom: auto;
    transform: translate(-50%, -50%);
    width: 100%;
    color: #fff;
    background-color: transparent;
    max-width: none;
    z-index: 2;
    text-align: center;
}

nav#topMenu
    li.root
    .SubMenuWrapper
    li
    .fourPromoSlot.BottomPromoRight
    .fourPromoItem.shopAllPromo
    .fourPromoCTA
    > span {
    display: inline-block;
    padding: 2px 5px;
    background-color: #fff;
    margin: 0 0 0 5px !important;
    color: #000;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.Right .rightAdsMenu h3,
nav#topMenu ul li.root .SubMenuWrapper ul li.Right .rightAdsMenu a {
    float: left;
    width: 20%;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.Right .rightAdsMenu {
    position: relative;
    overflow: hidden;
    padding: 20px 0;
    background-color: #e0e9f0;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.Right .rightAdsMenu h3 {
    padding: 5% 0 0;
    font-size: 1.2em;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.Right .rightAdsMenu h3::after {
    content: "";
    display: inline-block;
    width: 40px;
    height: 20px;
    vertical-align: middle;
    margin-left: 5px;
    background-position: -150px -107px;
    background-size: 500px 500px;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.Right .rightAdsMenu a div {
    display: inline-block;
    position: relative;
    text-align: left;
    font-weight: var(--fw-bold);
}

nav#topMenu ul li.root .SubMenuWrapper ul li.Right .rightAdsMenu a div::after,
nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li .singlecolumnadvert .view-button::after,
nav#topMenu li.root.USCFashion .SubMenuWrapper ul li.Center a.postWrap h3 span::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #000;
    bottom: 3px;
    left: 0;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.level1 {
    padding: 10px 20px 5px;
    text-align: left;
    position: relative;
    border-right: none;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.sdmViewAll,
nav#topMenu ul li.root .SubMenuWrapper ul li.menuAccHeader {
    width: 50%;
    text-align: center;
    padding: 20px 0 10px;
}

nav#topMenu ul li.root.threeCols .SubMenuWrapper ul li.level1 {
    width: 33.333333%;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.ClearIt {
    clear: left;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.level1 > ul {
    text-align: left;
    display: inline-block;
    width: 100%;
    padding-top: 5px;
}

nav#topMenu li.root ul li ul li ul li ul {
    border-top: none;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.largeLi {
    width: 50%;
    text-align: left;
    padding-left: 20px;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.gradBorder::after {
    background-size: 1px 100%;
    background-position: 100% 0;
    background-repeat: no-repeat;
    background-image: linear-gradient(
        to bottom,
        hsla(0, 0%, 0%, 0) 0%,
        hsla(0, 0%, 0%, 0.3) 50%,
        hsla(0, 0%, 0%, 0) 100%
    );
    content: "";
    height: 100%;
    top: 0;
    right: 0;
    position: absolute;
    width: 1px;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.splitFifty {
    width: 25%;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.splitThree {
    width: 33.333333%;
}

nav#topMenu li.root .SubMenuWrapper > ul .rightAdsMenu > h3 {
    font-size: 1em;
    text-align: center;
    letter-spacing: 0.1em;
    padding: 0 10px 10px;
    line-height: 1.4em;
}

nav#topMenu li.root .SubMenuWrapper > ul .rightAdsMenu > a {
    padding: 5px 10px;
    color: #000;
}

nav#topMenu li.root .SubMenuWrapper > ul .rightAdsMenu > a div {
    text-align: center;
    padding: 5px 0;
    font-size: 1em;
    line-height: 1.4em;
    font-weight: var(--fw-bold);
    letter-spacing: 0.1em;
}

nav#topMenu li.root .SubMenuWrapper > ul .rightAdsMenu > a img {
    width: 100%;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.level1.CatFinish {
    padding-right: 3%;
}

nav#topMenu ul li.root .SubMenuWrapper ul li.level1.CatStart {
    padding-left: 3%;
}

nav#topMenu ul li.root.sportsMenu .SubMenuWrapper,
nav#topMenu ul li.root.brandsMenu .SubMenuWrapper {
    padding: 0;
}

nav#topMenu ul li.root.sportsMenu .SubMenuWrapper ul li.Left,
nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.Left {
    display: none !important;
}

nav#topMenu ul li.root.sportsMenu .SubMenuWrapper ul li.Center,
nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.Center {
    width: 100%;
    float: none;
    padding: 15px 0 30px;
}

.rightAdsMenu h3,
.rightAdsMenu a div {
    text-transform: uppercase;
}

nav#topMenu ul li.root.promoMenu .SubMenuWrapper {
    padding: 8px 3%;
}

nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.PromoCenter {
    width: 20%;
}

nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.PromoCenter ul li.level1 {
    width: 100%;
}

nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.PromoCenter ul li.level1 ul li.level2 {
    border-top: 1px solid #eee;
}

nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.PromoCenter ul li.level1 ul li.level2:first-child {
    border: none;
}

nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li .singlecolumnadvert .view-button {
    display: inline-block;
    position: relative;
    background-color: transparent;
    text-align: left;
    font-size: 1em;
    font-weight: var(--fw-bold);
    letter-spacing: 0.05em;
    color: #000;
    padding: 15px 0;
}

nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li .singlecolumnadvert .view-button::after {
    bottom: 12px;
}

nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li .singlecolumnadvert a:hover .view-button {
    color: #0000ed;
}

nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.PromoRight {
    width: 80%;
}

nav#topMenu ul li.root .SubMenuWrapper > ul li.level1.sdmColHeader a {
    color: #000;
    display: inline-block;
    width: auto;
}

nav#topMenu ul li.root .SubMenuWrapper > ul li.level1.shopbySize > a::after {
    content: " Shop by Size";
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .level2.leftHeader.sdmColHeader > a {
    text-transform: uppercase;
}

nav#topMenu ul li.root .SubMenuWrapper .MensCenter > ul li.level1,
nav#topMenu ul li.root .SubMenuWrapper .LadiesCenter > ul li.level1,
nav#topMenu ul li.root .SubMenuWrapper .KidsCenter > ul li.level1,
nav#topMenu ul li.root .SubMenuWrapper .FootballCenter > ul li.level1 {
    width: 16.666667%;
}

nav#topMenu ul li.root .SubMenuWrapper .Center > ul li.level1.sdmColHeader {
    width: 100%;
    padding: 20px 0 5px;
}

nav#topMenu ul li.root .SubMenuWrapper .Center > ul li.level1.sdmColHeader.BlackItalicFont {
    padding: 10px 0 5px;
}

nav#topMenu ul li.root .SubMenuWrapper .Center > ul li.level1.colGroupStart.sdmColHeader.BlackItalicFont {
    padding: 20px 0 5px;
}

nav#topMenu ul li.root .SubMenuWrapper .Center > ul li.level1.sdmColHeader.BlackItalicFont > a {
    text-transform: uppercase;
    font-size: 1.05em !important;
    line-height: 1.2em;
}

nav#topMenu li.MenuGroupChristmas .SubMenuWrapper > ul .level1.sdmColHeader > a {
    line-height: 1.2em;
    font-size: 1.05em !important;
}

.giftCardCategory #divColour .s-productextras-column-1::before {
    content: "Occasion:";
    font-family: var(--brand-font);
    font-weight: var(--fw-bold);
    font-size: 1.2em;
}

.giftCardCategory #divSize .s-productextras-column-1::before {
    content: "Amount:";
    font-family: var(--brand-font);
    font-weight: var(--fw-bold);
    font-size: 1.2em;
}

.giftCardCategory .s-productextras-column-1 #BuyColourText,
.giftCardCategory .s-productextras-column-1 .BuySizeText,
.giftCardCategory #divPopupSizeGuide,
.giftCardCategory .AltProdDet #productDetails .addtoBagWrap .addToBasketContainer a span {
    display: none;
}

.giftCardCategory .AltProdDet #productDetails .addtoBagWrap .addToBasketContainer a::before {
    content: "select an amount";
}

.giftCardCategory .AltProdDet #productDetails .addtoBagWrap .addToBasketContainer.sizeSelected a::before {
    content: "ADD TO BAG";
}

@media (max-width: 1370px) {
    nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.PromoCenter {
        width: 30%;
    }

    nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.PromoRight {
        width: 70%;
    }
}

@media (max-width: 1021px) {
    nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.PromoCenter {
        width: 40% !important;
    }

    nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.PromoCenter li.sdmColHeaderHidden {
        padding-top: 0;
    }

    nav#topMenu ul li.root.promoMenu .SubMenuWrapper ul li.PromoRight {
        display: block !important;
        width: 60%;
    }
}

/* USC Shop */
nav#topMenu ul li.root.USCFashion .SubMenuWrapper ul li.Center {
    padding: 20px 10px;
}

nav#topMenu ul li.root.USCFashion .SubMenuWrapper ul li.Center .Center::before,
nav#topMenu ul li.root.USCFashion .SubMenuWrapper ul li.Center .Center::after {
    content: "";
    display: table;
}

nav#topMenu ul li.root.USCFashion .SubMenuWrapper ul li.Center .Center::after {
    clear: both;
}

nav#topMenu ul li.root.USCFashion .SubMenuWrapper ul li.Center a.postWrap .postTitle,
nav#topMenu li.root.USCFashion .SubMenuWrapper ul li.Center a.postWrap .postImage {
    padding: 10px;
    text-align: center;
}

nav#topMenu li.root.USCFashion .SubMenuWrapper ul li.Center a.postWrap .postImage > img {
    margin: 0 auto;
    display: inline-block;
}

nav#topMenu ul li.root.USCFashion .SubMenuWrapper ul li.Center a.postWrap .postTitle {
    text-align: center;
    line-height: 1.4em;
    font-weight: var(--fw-bold);
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000;
}

nav#topMenu ul li.root.USCFashion .SubMenuWrapper ul li.Center a.postWrap .postTitle span {
    position: relative;
    padding: 5px 0;
}

nav#topMenu ul li.root.USCFashion .SubMenuWrapper ul li.Center a.postWrap:hover .postTitle {
    color: #0000ed;
}

/* GAME and USC Images in Navigation */
nav#topMenu ul li.root.GAME .SubMenuWrapper ul li.Center {
    padding: 20px 10px;
}

nav#topMenu ul li.root.GAME .SubMenuWrapper ul li.Center .Center::before,
nav#topMenu ul li.root.GAME .SubMenuWrapper ul li.Center .Center::after {
    content: "";
    display: table;
}

nav#topMenu ul li.root.GAME .SubMenuWrapper ul li.Center .Center::after {
    clear: both;
}

nav#topMenu ul li.root.GAME .SubMenuWrapper ul li.Center a.postWrap .postTitle {
    text-align: center;
    line-height: 1.4em;
    font-weight: var(--fw-bold);
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000;
}

nav#topMenu ul li.root.GAME .SubMenuWrapper ul li.Center a.postWrap:hover .postTitle {
    color: #0000ed;
}

nav#topMenu li.root.GAME .SubMenuWrapper ul li.Center a.postWrap .postImage,
nav#topMenu ul li.root.GAME .SubMenuWrapper ul li.Center a.postWrap .postTitle {
    padding: 0 0 10px;
}

#topMenu li.navImg.GAME > a {
    background-image: url("/images/core/game-logo-elevate.svg");
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-size: 35px auto;
    background-position: center;
    min-width: 80px;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    line-height: 40px;
    font-size: 12px;
}

#topMenu li.navImg.USCFashion > a {
    background-image: url("/images/core/usc-logo-elevate.svg");
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-size: 30px auto;
    background-position: center;
    min-width: 72px;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    line-height: 40px;
    font-size: 12px;
}

#topMenu li.navImg.nvend > a::before {
    height: 70%;
    display: block;
    width: 2px;
    background: #c3cbd4;
    content: "";
    position: absolute;
    left: 0;
    z-index: 0;
    top: 15%;
    border: none;
}

#topMenu li.navImg.nvend.sdHover > a::before,
#topMenu li.navImg.nvend > a:hover::before {
    height: 100%;
}

@media (min-width: 768px) {
    .SubMenuWrapper li .col-sm-3.col-5g {
        width: 20%;
    }
}

@media (max-width: 1021px) {
    nav#topMenu ul li.root .SubMenuWrapper > ul li.level1.sdmColHeader::before {
        left: 10px;
        right: 10px;
    }

    nav#topMenu ul li.root .SubMenuWrapper ul li.level1 {
        padding-left: 5px;
        padding-right: 5px;
    }

    #topMenu li.navImg.GAME > a {
        background-size: 32px auto;
        min-width: 65px;
    }

    #topMenu li.navImg.USCFashion > a {
        background-size: 27px auto;
        min-width: 60px;
    }

    .sdDetails .RadTabStrip .rtsLevel .rtsIn {
        padding: 23px 15px;
    }
}

nav#topMenu li.root .SubMenuWrapper > ul .indentSub {
    padding-left: 20px;
}

nav#topMenu li.root .SubMenuWrapper > ul .sdmColHeader > a,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColOther > a {
    position: relative;
    color: #000;
    letter-spacing: 0.1em;
    font-size: 1em !important;
    font-weight: var(--fw-bold);
    border-top: 0;
    margin-top: 0;
    min-height: 0;
    padding: 5px;
    min-width: 0;
    max-width: 100%;
}

nav#topMenu li.root .SubMenuWrapper > ul .level2.sdmColHeader > a {
    display: inline-block;
    width: auto;
    font-size: 1em;
    letter-spacing: 0.03em;
}

nav#topMenu li.root .SubMenuWrapper > ul .level1.sdmColHeader > a,
.stackFlexHeader h3 {
    font-size: 1.15em !important;
    letter-spacing: 0.03em;
    line-height: 1.4em;
    text-align: left;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper ul li.Center > div.Center {
    display: none;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper {
    overflow: visible !important;
    height: auto;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper ul li.Center > ul {
    width: 100%;
    display: flex;
    justify-content: center;
    float: none;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul::before,
nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul::after {
    position: absolute;
    display: block;
    height: 100%;
    content: "";
    left: 0;
    top: 0;
    border-left: 1px solid #ddd;
    width: 1px;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul::after {
    right: 0;
    left: auto;
}

nav#topMenu li.root.sidebarSubMenu .SubMenuWrapper li.Bottom {
    display: none !important;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul > li.Center::before,
nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul > li.Center::after {
    content: none;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul {
    border: 0;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul > li.Center {
    padding: 0;
    display: block !important;
    position: relative;
    max-width: none;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul li.mmHasChild.level1 > ul {
    width: 100%;
    left: 0;
    top: 70px;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul li.mmHasChild.level1 > ul.open {
    display: flex;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul li.mmHasChild.level1.sdmColHeader > ul {
    padding: 0;
    height: auto;
    justify-content: center;
    background-color: #fff;
    flex-wrap: wrap;
    border-top: 1px solid #ddd;
    max-height: calc(100vh - 170px);
    overflow-y: auto;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul li.mmHasChild.level1:hover > ul > li > ul,
nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper > ul li.mmHasChild.level1 > ul li > ul:hover {
    display: block;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .Center > ul li.level1.sdmColHeader {
    height: auto;
    padding: 25px 20px 0 0;
    width: auto;
    position: static;
    border: 0;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper li.Left {
    display: none !important;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper li.columnGroup,
nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .promoEnabled li.Left {
    width: 16.666667%;
    padding: 20px 5px 25px;
    max-width: 250px;
    float: none;
    order: 1;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .fiveCol li.columnGroup,
nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .fiveCol li.Left {
    width: 20%;
    max-width: 300px;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .fourCol li.columnGroup,
nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .fourCol li.Left {
    width: 25%;
    max-width: 375px;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .threeCol li.columnGroup,
nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .threeCol li.Left {
    width: 33.333333%;
    max-width: 500px;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .twoCol li.columnGroup,
nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .twoCol li.Left {
    width: 50%;
    max-width: 750px;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .promoEnabled li.Left {
    order: 2;
    padding: 0;
    display: inline-block !important;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper li.Right {
    width: 100%;
    flex-basis: 100%;
    float: none;
    order: 3;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper li.Right > .Right {
    display: block !important;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .Center > ul li.level1.sdmColHeader.allSportsMenu {
    padding-left: 40px;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .level1.sdmColHeader > a {
    text-align: center;
    text-transform: uppercase;
    padding: 10px 0;
    color: #aaa;
    font-size: 1.4em !important;
    border-bottom: solid 2px transparent;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .level1.sdmColHeader > a::before,
nav#topMenu ul li.root .SubMenuWrapper > ul li .fourPromoSlot .fourPromoItem a::before,
nav#topMenu ul li.root .SubMenuWrapper > ul li.promoEnabled .twoStackFlex .twoStackItem a::before {
    content: none;
}

nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .level1.sdmColHeader.activeItem > a {
    border-bottom-color: #0000ed;
    color: #000;
}

@media (min-width: 1366px) {
    nav#topMenu li.root .SubMenuWrapper > ul .level2.sdmColHeader > a {
        font-size: 1.05em !important;
    }

    nav#topMenu li.root .SubMenuWrapper li .fourPromoItem a .fourPromoCTA,
    nav#topMenu li.root .SubMenuWrapper li .fourPromoSlot.BottomPromoRight .fourPromoItem.shopAllPromo .fourPromoCTA {
        font-size: 1.35em !important;
    }
}

@media (max-width: 1280px) {
    nav#topMenu ul li.root.promoEnabled .SubMenuWrapper ul li.Center > ul {
        width: 100%;
    }

    nav#topMenu ul li.root.promoEnabled .SubMenuWrapper ul li.Center > div.Center,
    nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .promoEnabled li.Left {
        display: none !important;
    }

    nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .promoEnabled li.columnGroup {
        width: 20%;
        max-width: 300px;
    }

    nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .promoEnabled.fiveCol li.columnGroup {
        width: 25%;
        max-width: 375px;
    }

    nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .promoEnabled.fourCol li.columnGroup {
        width: 33.333333%;
        max-width: 500px;
    }

    nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .promoEnabled.threeCol li.columnGroup {
        width: 50%;
        max-width: 750px;
    }

    nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .promoEnabled.twoCol li.columnGroup {
        width: 100%;
        max-width: 150px;
    }
}

.stackFlexHeader {
    padding: 20px 15px 30px;
    width: 100%;
}

.stackFlexHeader h3 {
    margin: 0;
    padding: 5px 0;
}

nav#topMenu li.mmHasChild.level1.sdmColHeader.sdmColHeaderHidden > div {
    padding-top: 29px;
}

nav#topMenu li.root .SubMenuWrapper > ul .disablelink a,
.am-menu ul li.level2.disablelink a {
    pointer-events: none;
}

nav#topMenu li.root .SubMenuWrapper > ul .disablelink a:hover {
    text-decoration: none;
    color: #000;
}

nav#topMenu li.root .SubMenuWrapper > ul .adiBoots > a,
nav#topMenu li.root .SubMenuWrapper > ul .botList > a {
    text-transform: none;
}

nav#topMenu li.root .SubMenuWrapper > ul .adiBoots > a::after {
    content: " BOOTS";
}

nav#topMenu ul li.root .SubMenuWrapper ul li.sdmViewAll a {
    padding: 0 5px;
}

nav#topMenu li.root .SubMenuWrapper > ul .level2.sdmColGap > a {
    margin-top: 15px;
}

nav#topMenu .root li.sdmColHeaderHidden {
    padding-top: 10px;
}

nav#topMenu li.root .SubMenuWrapper > ul li a {
    line-height: 1.2em;
    padding: 5px;
    color: #000;
    letter-spacing: 0.05em;
    font-size: 1em;
    font-family: var(--brand-font);
}

nav#topMenu > ul li a {
    font-family: var(--brand-font);
}

nav#topMenu li.root .SubMenuWrapper > ul li a:hover,
nav#topMenu ul li.root .SubMenuWrapper > ul li.sdmColHeader a:hover {
    color: #0000ed;
}

nav#topMenu > ul li.newTag::after,
.mp-menu > .mp-level ul li.newTag .menuitemtext::after,
nav#topMenu > ul li.saleTag::after,
.mp-menu > .mp-level ul li.saleTag .menuitemtext::after,
nav#topMenu > ul li.hotTag::after,
.mp-menu > .mp-level ul li.hotTag .menuitemtext::after,
nav#topMenu > ul li.fiftyTag::after,
.mp-menu > .mp-level ul li.fiftyTag .menuitemtext::after,
nav#topMenu > ul li.seventyTag::after,
.mp-menu > .mp-level ul li.seventyTag .menuitemtext::after,
nav#topMenu > ul li.ninetyTag::after,
.mp-menu > .mp-level ul li.ninetyTag .menuitemtext::after {
    content: "NEW";
    padding: 3px;
    vertical-align: middle;
    font-size: 0.8em;
    line-height: 1em;
    color: #fff;
    background: #0000ed;
    display: inline-block;
}

nav#topMenu > ul li.saleTag::after,
.mp-menu > .mp-level ul li.saleTag .menuitemtext::after {
    content: "SALE";
    background: #ed0000;
}

nav#topMenu > ul li.hotTag::after,
.mp-menu > .mp-level ul li.hotTag .menuitemtext::after {
    content: "HOT";
    background: #ed0000;
}

nav#topMenu > ul li.fiftyTag::after,
.mp-menu > .mp-level ul li.fiftyTag .menuitemtext::after {
    content: "Up To 50% Off";
    background: #000;
    color: #fff;
}

nav#topMenu > ul li.seventyTag::after,
.mp-menu > .mp-level ul li.seventyTag .menuitemtext::after {
    content: "Up To 70% Off";
    background: #000;
    color: #fff;
}

nav#topMenu > ul li.ninetyTag::after,
.mp-menu > .mp-level ul li.ninetyTag .menuitemtext::after {
    content: "Up To 90% Off";
    background: #feff43;
    color: #000;
}

.mp-menu > .mp-level ul li.newTag .menuitemtext::after,
.mp-menu > .mp-level ul li.saleTag .menuitemtext::after,
.mp-menu > .mp-level ul li.hotTag .menuitemtext::after,
.mp-menu > .mp-level ul li.fiftyTag .menuitemtext::after,
.mp-menu > .mp-level ul li.seventyTag .menuitemtext::after,
.mp-menu > .mp-level ul li.ninetyTag .menuitemtext::after {
    vertical-align: text-bottom;
}

nav#topMenu > ul li.level1 a,
nav#topMenu > ul li.level2 a {
    max-width: none;
    min-width: 0;
}

nav#topMenu li.root .SubMenuWrapper .level2.sdmColFeature a {
    color: #333;
}

/* Drop Sub Menu */
nav#topMenu ul li.root.sportsMenu .SubMenuWrapper ul li.Center ul li > ul {
    border: none;
    padding: 0;
}

nav#topMenu ul li.root.sportsMenu .SubMenuWrapper ul li.level1 {
    width: 16.666667%;
    border-left: 1px solid #ddd;
}

nav#topMenu ul li.root.sportsMenu .SubMenuWrapper ul li.level1:first-child {
    border: none;
}

nav#topMenu ul li.root.sportsMenu .SubMenuWrapper ul li.level1 ul li.level2 a,
nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1 ul li.level2 a {
    position: relative;
    padding: 5px 20px;
    max-width: 100%;
    min-width: 0;
}

nav#topMenu ul li.root.sportsMenu .SubMenuWrapper ul li.level1 ul li.level2.sdmColHeader a::before {
    display: none;
}

nav#topMenu ul li.root.sportsMenu .SubMenuWrapper ul li.level1 ul li.level2.sportsViewAll a::before {
    background-image: url("/images/core/desktop_menu_brands_sprite_version_7-01.svg");
    background-size: 1540px 1540px;
    background-position: -39.4em -125.5em;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1 ul li.level2.brandsViewAll a {
    font-weight: var(--fw-bold);
}

nav#topMenu .sportsMenu li.sdmColHeaderHidden {
    padding-top: 0;
}

@media (min-width: 768px) and (max-width: 889px) {
    nav#topMenu ul li.root.sportsMenu .SubMenuWrapper ul li.level1 ul li.level2.s_MMA.longDesc a {
        padding: 10px 0 9px 40px;
    }

    nav#topMenu ul li.root.sportsMenu .SubMenuWrapper ul li.level1 ul li.level2.s_fitnessexercise.longDesc a {
        padding: 10px 0 9px 40px;
    }
}

/* Brands Menu */
nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1 {
    position: relative;
    float: left;
    width: 100%;
    border-left: 1px solid #eee;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1:first-child {
    border: none;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1.sdmColHeaderHidden {
    padding: 0;
}

nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1 a {
    text-align: left;
    position: relative;
    padding: 10px 5px;
}

nav#topMenu ul li.root.MenusaleRed > a,
nav#topMenu ul li.root.MenusaleRed.sdHover > a,
#mp-menu .mp-level .MenusaleRed,
#mp-menu .mp-level .MenusaleRed > .menuitemtext.MobMenChevron {
    background-color: #ed0000;
    color: #fff;
}

.mp-menu .mp-level .MenusaleRed > a.MobMenChevron::after {
    background-position: -393px -318px;
}

@media (max-width: 1021px) {
    nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1 a {
        padding-left: 20px;
    }
}

/* New footer */
.FooterGroupLink {
    width: 100%;
    float: none;
    padding: 0;
}

.FontBold {
    font-weight: var(--fw-bold);
}

.emailSignupPane {
    padding: 0;
}

h2.FooterHeader {
    font-size: 1.4em;
}

.FooterMenuWrap {
    float: none;
}

.FooterGroup {
    padding: 0;
    position: relative;
    overflow: visible;
    border-top: 1px solid #323232;
    border-bottom: 1px solid #323232;
}

.FooterGroup::before,
.FooterGroup::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-top: 1px solid #323232;
    border-bottom: 1px solid #323232;
    display: block;
    top: 0;
}

.FooterGroup::before {
    right: 100%;
}

.FooterGroup::after {
    left: 100%;
}

.FooterWrap {
    border-top: none;
    z-index: 10 !important;
    overflow: hidden;
    background-color: #1d1d1d;
    padding: 0;
}

.FooterWrap::after {
    content: "";
    border: 0;
    border-top: 6px solid #0000ed;
    border-image-source: linear-gradient(to left, #ee3424 50%, #0000ed 50%);
    border-image-slice: 1;
    bottom: 0;
    left: 0;
    position: absolute;
    height: 1px;
    width: 100%;
}

.FooterWrap .FooterTop {
    background-color: #1c1c1c;
    display: table;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
}

.FooterWrap .footerContainer .footerInfoSection .footerLangaugeSwitcher {
    width: 200px;
}

.FooterWrap .footerContainer .footerInfoSection .footerStoreFinder a::before,
.FooterWrap .footerContainer .footerInfoSection .footerCustServ a::before,
#topLinkMenu ul.TopLinkMenu .iconsStores a::before,
#topLinkMenu ul.TopLinkMenu .iconsCS a::before {
    content: "";
    display: inline-block;
    width: 40px;
    height: 40px;
    background-size: 934px 950px;
}

.FooterWrap .footerContainer .footerInfoSection #topLinkMenu ul.TopLinkMenu .iconsStores a::before,
.footerInfoSection #topLinkMenu ul.TopLinkMenu .iconsCS a::before {
    margin-right: 10px;
}

.FooterWrap .footerContainer .footerInfoSection .footerStoreFinder a::before,
#topLinkMenu ul.TopLinkMenu .iconsStores a::before {
    background-position: -175px -396px;
}

.FooterWrap .footerContainer .footerInfoSection .footerCustServ a::before,
#topLinkMenu ul.TopLinkMenu .iconsCS a::before {
    background-position: -24px -396px;
}

.FooterWrap .FooterTop .SubTitle {
    display: block;
    color: #fff;
    font-size: 16px;
}

.FooterWrap .FooterTop span.hidden-xs {
    display: block;
    color: #fff;
    margin-top: 5px;
    font-size: 1.2em;
}

.FooterWrap .FooterTop span.hidden-xs span {
    text-decoration: underline;
}

#topLinkMenu ul.TopLinkMenu .iconsCS a span,
#topLinkMenu ul.TopLinkMenu .iconsStores a span {
    display: block;
    font-size: 15px;
    color: #fff;
}

@media only screen and (min-width: 768px) {
    .FooterWrap .footerContainer .SubTitle {
        display: block;
        font-size: 0.9rem;
    }
}

.FooterWrap .footerContainer span.hidden-xs {
    display: block;
    color: #fff;
    margin-top: 5px;
    font-size: 1.2em;
}

.FooterWrap .footerContainer span.hidden-xs span {
    text-decoration: underline;
}

.socialMediaContainer {
    border-top: 1px solid #ddd;
    margin-top: 20px;
}

.socialSitesWrapper {
    margin: 40px auto;
    width: 60%;
    max-width: 500px;
    border-bottom: 0;
}

.socialSitesWrapper ul {
    overflow: hidden;
    margin: 0;
}

.socialSitesWrapper li {
    display: block;
    float: left;
    height: 50px;
    width: 20%;
    text-align: center;
    position: relative;
}

.socialSitesWrapper li a {
    background-image: url("/images/core/sd-new-footer-icons.svg");
    background-repeat: no-repeat;
    text-indent: -9999px;
    display: inline-block;
    height: 100%;
    width: 100%;
    max-width: 45px;
    background-size: 1400px 1400px;
    opacity: 0.8;
}

.socialSitesWrapper li a:hover {
    opacity: 1;
}

.socialSitesWrapper li.socInstagram a {
    background-position: -63px -428px;
}

.socialSitesWrapper li.socFacebook a {
    background-position: -104px -428px;
}

.socialSitesWrapper li.socPinterest a {
    background-position: -147px -428px;
}

.socialSitesWrapper li.socTwitter a {
    background-position: -16px -425px;
}

.socialSitesWrapper li.socYouTube a {
    background-position: -195px -428px;
    max-width: 55px;
}

.txtLink {
    height: 100%;
    vertical-align: middle;
    display: table-cell;
    padding-left: 5px;
}

.txtLink:hover {
    text-decoration: underline;
}

#footApp,
#footAppGooglePlay {
    width: 50%;
    float: left;
    text-align: center;
}

#footApp a:hover > .ico {
    background-position: -662px -275px;
    width: 120px;
}

#footApp .ico {
    background-position: -662px -237px;
    width: 120px;
}

#footAppGooglePlay a:hover > .ico {
    background-position: -605px -1214px;
    width: 120px;
}

#footAppGooglePlay .ico {
    background-position: -605px -1174px;
    width: 120px;
}

.footerMenu .FooterSubGroup {
    background-color: transparent;
    min-height: 0;
    margin: 0;
    height: auto;
}

.footerPane .FooterHeader {
    padding: 10px 0;
    display: block;
    margin-bottom: 6px;
    width: 100%;
}

.max-width,
.FooterPane2 {
    margin: 0 auto;
    max-width: 1400px;
}

.sitewideBanner .closingIcon {
    height: 40px;
    width: 40px;
    position: absolute;
    right: 0;
    top: 5px;
}

.sitewideBanner .closingIcon div {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-size: 700px 700px;
    background-position: -411px -286px;
    cursor: pointer;
}

.sitewideBanner .swiper-container-sitewideBanner {
    border: 0;
    height: 64px;
    width: 100%;
    z-index: 10;
    position: fixed;
    bottom: 0;
    right: 0;
    transition: all 0.25s ease;
    transform: translateZ(0);
    will-change: tranform;
    backface-visibility: hidden;
    margin: 0 auto;
    overflow: hidden;
    list-style: none;
    padding: 0;
}

@media (min-width: 768px) {
    .sitewideBanner .swiper-container-sitewideBanner {
        width: 50%;
        max-width: 550px;
        height: 46px;
    }
}

.sitewideBanner .swiper-slide:nth-child(odd) {
    background-color: #ed0000;
}

.sitewideBanner .swiper-slide:nth-child(even) {
    background-color: #0900ed;
}

.sitewideBanner .swiper-slide .swiper-text {
    color: #fff;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 12px;
}

.sitewideBanner .swiper-slide a {
    width: 100%;
    height: 100%;
    padding: 14px 40px 4px;
    display: flex;
    align-items: center;
}

@media (min-width: 768px) {
    .sitewideBanner .swiper-slide .swiper-text {
        text-align: left;
        font-size: 14px;
    }

    .sitewideBanner .swiper-slide a {
        justify-content: space-between;
        padding: 0 40px 4px 15px;
    }

    .sitewideBanner .closingIcon {
        top: 1px;
    }
}

@media (max-width: 767px) {
    .sitewideBanner .swiper-slide a {
        padding: 0 40px 4px;
    }

    .sitewideBanner .swiper-slide .swiper-text {
        display: flex;
        flex-direction: column;
    }

    .sitewideBanner .closingIcon {
        top: 10px;
    }

    .Responsive #divulBagParent {
        height: 35vh;
    }
}

.promotionBanner .swiper-slide::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    transform: translate(0, 0);
}

.sitewideBanner .swiper-slide:nth-child(even)::after {
    background-color: hsla(0, 100%, 46%, 1);
}

.sitewideBanner .swiper-slide:nth-child(odd)::after {
    background-color: hsla(244, 100%, 46%, 1);
}

.sitewideBanner .swiper-slide::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    transform: translate(-100%, 0);
}

.sitewideBanner .swiper-slide-active::after {
    transition: transform 5s linear;
    transform: translate(0, 0);
}
/*end of sitewide*/

/*Sign up*/
.signup-wrapper .signupInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    float: none;
    border: none;
    max-width: 1000px;
    margin: 10px auto 0;
    padding: 20px 0;
    font-size: 1.2em;
}

.signup-wrapper .signupInner .signupInputBox {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    margin: 0;
    overflow: hidden;
    width: 100%;
    margin-bottom: 10px;
}

.signup-wrapper input#signupEmail {
    padding: 5px 15px;
    font-size: 14px;
    opacity: 0.9;
    text-align: center;
    height: 48px;
    border: 1px solid #fff;
    border-right: none;
    border-radius: 3px;
    transition: border-color 0.3s linear;
}

@media (min-width: 768px) {
    .signup-wrapper input#signupEmail {
        text-align: left;
    }
}

.signup-wrapper input#signupEmail:focus {
    border-color: #000;
}

.signup-wrapper input.signbut {
    font-size: 14px;
    font-weight: var(--fw-normal);
    height: 48px;
    padding: 5px;
    border-radius: 3px;
}

.signup-wrapper .signupInner .join-us-text {
    text-align: left;
}

.signup-wrapper .signupInner .join-us-text label {
    font-size: 1.3em;
    letter-spacing: 0.05em;
    margin-bottom: 0;
    text-transform: uppercase;
}

.signup-wrapper .signupInner .SubTitle {
    color: var(--site-white);
    font-size: 16px;
    margin-bottom: 18px;
    font-weight: var(--fw-bold);
}

.signup-wrapper .signupInner .sub-buttons {
    padding: 0;
}

.signup-wrapper .signupInner .NewsletterPadding {
    padding-bottom: 8px;
}

.signup-wrapper .signupInner .disclaimerText {
    display: none;
}

.signup-wrapper .signupInner .signupEmailSuccess {
    max-width: 657px;
    text-align: center;
}

.signup-wrapper .signupInner .signupEmailSuccess p,
.signup-wrapper .signupInner .signupEmailError {
    color: var(--site-white);
}

.signup-wrapper .signupInner .signupEmailSuccess a {
    color: #bbb;
    text-decoration: underline;
    display: inline-block;
}

#userEmailtext {
    word-break: break-word;
}

.signup-wrapper .signupInner .signupEmailSuccess .global-icon-tick {
    width: 20px;
    height: 20px;
    margin: 0 auto 10px;
}

@media (min-width: 768px) {
    .signup-wrapper .signupInner .signupEmailError {
        margin-left: 4%;
    }
}

.Copyright .footerCopyLine {
    width: 100%;
    margin-bottom: 35px;
}

.Copyright .footerCopyLine a {
    display: none;
    color: #888;
    font-size: 12px;
    text-decoration: underline;
    padding: 0 5px;
}

.Copyright .footerCopyLine span {
    color: #888;
    padding-right: 5px;
}

@media (min-width: 768px) {
    .Copyright .footerCopyLine a {
        display: inline-block;
    }
}

/*Footer search for stores*/
.CountrySelection > div,
.CountryNoSelection > div,
.CountryNoEnglish > div {
    width: 100%;
}

#SearchStores a,
#SearchStores li {
    width: 100%;
    height: 198px;
    display: block;
    margin: 0 auto;
    max-width: 165px;
}

#SearchStores li {
    height: 180px;
    background: url("/images/core/footer-stores-background-v2.png") no-repeat;
}

.hotspotbuy.hotspotwishlist,
.hotspotbuy.hotspotquickbuy {
    width: 50%;
    height: 35px;
    background-color: rgba(220, 220, 220, 0.9);
    overflow: hidden;
    font-size: 1.1em;
}

.CountrySelection p,
.CountryNoSelection p,
.CountryNoEnglish p {
    color: #686868;
    font-size: 1.4em;
    border-bottom: 1px solid #ddd;
    font-weight: var(--fw-bold);
    margin-bottom: 6px;
    line-height: 1.2em;
    padding-bottom: 6px;
}

.footerLogo {
    text-align: center;
    padding-top: 28px;
}

#SearchStores li .FooterHeader {
    color: #686868;
    font-size: 1.3em;
    border-bottom: 1px solid #ddd;
    font-weight: var(--fw-bold);
    width: 45%;
}

.FooterSearchCountry .FooterHeader h2 {
    color: #686868;
    font-size: 1.4em;
    border-bottom: 1px solid #ddd;
    font-weight: var(--fw-bold);
}

.footerInfoSection {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #323232;
}

/*------------nav -------------*/

nav#topMenu li.columnGroup > ul {
    padding: 0 5px;
}

nav#topMenu .NewMenu > ul {
    min-height: 477px;
}

nav#topMenu ul li.root > a {
    color: #fff;
    font-size: 14px;
    line-height: 40px;
    padding: 0 10px;
    position: relative;
    overflow: hidden;
}

nav#topMenu ul.RootGroup li.root > a {
    overflow: visible;
}

nav#topMenu > ul .NewMenuWrap li.level1 > a {
    color: #636363;
    float: left;
    width: 17%;
    padding: 5px 15px;
    font-weight: var(--fw-bold);
    margin: 0;
    max-width: 17%;
    border-right: 1px solid #ddd;
    text-transform: uppercase;
}

nav#topMenu ul li.root.sdHover > a {
    background-color: #1c1c1c;
    z-index: 2;
}

nav#topMenu ul li.root.GAME > a:hover,
nav#topMenu ul li.root.GAME.sdHover > a {
    background-color: #bd1a8d;
}

nav#topMenu > ul .NewMenuWrap li.level2 > a:hover,
nav#topMenu > ul .NewMenuWrap li.level1.sdHover > a {
    color: #fff;
}

nav#topMenu ul li.root.ViewAllDeals .SubMenuWrapper ul li.Center {
    width: 100%;
}

nav#topMenu ul li.root.ViewAllDeals .SubMenuWrapper ul li.Right {
    display: none !important;
}

nav#topMenu ul li.root.ViewAllDeals .SubMenuWrapper li ul li.level1 {
    width: 25%;
    float: left;
    padding: 20px;
}

nav#topMenu ul li.root.ViewAllDeals .SubMenuWrapper li ul li.level1 a {
    text-indent: -9999px;
    display: block;
    width: 100%;
    padding: 0;
    background-size: 100% auto;
    height: 352px;
    max-width: 100%;
    background-color: transparent !important;
    background-repeat: no-repeat;
}

nav#topMenu ul li.root.ViewAllDeals .SubMenuWrapper li ul li.ClearanceImage a {
    background-image: url("/images/marketing/sd-square-clear.jpg");
}

nav#topMenu ul li.root.ViewAllDeals .SubMenuWrapper li ul li.FlashImage a {
    background-image: url("/images/marketing/sd-square-flash.jpg");
}

nav#topMenu ul li.root.ViewAllDeals .SubMenuWrapper li ul li.PreorderImage a {
    background-image: url("/images/marketing/sd-square-pre.jpg");
}

nav#topMenu ul li.root.ViewAllDeals .SubMenuWrapper li ul li.WeeklyImage a {
    background-image: url("/images/marketing/sd-menu-week-offer.jpg");
}

nav#topMenu > ul .NewMenuWrap li.sdmColHeader a {
    text-transform: uppercase;
    font-size: 1em !important;
}

div.NewMenuWrap {
    top: 80px;
    border-bottom: 1px solid #ddd;
    height: auto;
}

nav#topMenu ul.RootGroup {
    text-align: center;
    display: flex;
    margin: 0 auto;
    justify-content: center;
}

nav#topMenu ul.RootGroup li.root {
    position: static;
    flex: 1 1 auto;
}

div.NewMenu {
    padding: 0;
    width: 100%;
    max-width: 1410px;
    height: 100%;
}

div.NewMenuWrap ul > li.level1:hover {
    background-color: transparent !important;
}

div.NewMenuWrap ul > li.sdHover > a,
.NewMenuWrap li.level2 a:hover {
    background-color: rgba(0, 84, 164, 0.9) !important;
}

div.NewMenu > ul > li > a {
    padding: 0 45px;
}

nav#topMenu {
    max-width: 900px;
    padding: 0 15px;
    position: static;
}

nav#topMenu .NewMenu ul li.level1 {
    padding: 0;
    width: 100%;
    height: 100%;
    float: left;
    position: static;
}

div.NewMenu > ul > li.level1 > a {
    padding: 0 20px 0 40px;
}

nav#topMenu > ul .NewMenuWrap li.TopGap {
    padding: 9px 0 3px;
    border-top: 1px solid #bbb;
    margin-top: 31px;
}

li.menuImg {
    background: url("/images/marketing/menuimage.jpg") no-repeat;
    min-height: 340px;
    display: block;
    height: 100%;
    text-indent: -9999px;
}

.DropdownWrap {
    left: 17% !important;
    width: 83% !important;
    padding: 10px;
    top: 0;
    position: absolute;
    height: 100%;
    overflow: hidden !important;
}

.Dropdown {
    box-shadow: none;
    border: none;
    margin-bottom: 0;
    padding: 0;
    max-width: 100%;
    background: transparent;
}

.Dropdown .Center {
    width: 100%;
    background: transparent;
    padding: 0;
}

.Dropdown .Center .columnGroup {
    width: 20%;
}

.Dropdown .Center > ul > li:first-child + li + li + li {
    width: 20%;
    clear: none;
}

nav#topMenu > ul .NewMenuWrap li.level2 a {
    line-height: 1em;
    padding: 5px;
    max-width: 100%;
    font-size: 1.3em;
}

/* Popular Menu */
.popularMenu h3 {
    font-size: 0.8em;
    line-height: 1.1em;
    text-transform: uppercase;
    padding: 15px 0 15px 16px;
    margin: 0;
}

.popularMenu h3,
nav#topMenu li.root .SubMenuWrapper > ul li .popularMenu a,
nav#topMenu li.root .NewMenuWrap .popularMenu a {
    color: #fff;
}

nav#topMenu li.root .SubMenuWrapper > ul li .popularMenu a,
nav#topMenu li.root .NewMenuWrap .popularMenu a {
    text-transform: initial;
    line-height: 1em;
    font-weight: var(--fw-normal);
}

nav#topMenu .popularMenu ul li {
    margin-bottom: 10px;
}

nav#topMenu .popularMenu.popular_brands ul li.itemView.All.Brands a {
    font-weight: var(--fw-bold);
}

@media (min-width: 1022px) and (max-width: 1199px) {
    nav#topMenu li.root .SubMenuWrapper > ul li .popularMenu a,
    nav#topMenu li.root .NewMenuWrap .popularMenu a {
        padding: 1px 8px;
        font-size: 0.9em;
    }

    .popularMenu h3 {
        padding: 13px 8px;
    }
}

@media (min-width: 1200px) {
    nav#topMenu li.root .SubMenuWrapper > ul li .popularMenu a,
    nav#topMenu li.root .NewMenuWrap .popularMenu a {
        padding: 4px 12px;
    }

    .popularMenu h3 {
        padding: 13px 12px;
    }
}

.ui-autocomplete {
    max-height: 70vh;
    overflow-y: auto;
    position: absolute;
    border-radius: 4px !important;
    top: 58px !important;
    border: 0 !important;
    background: #fff !important;
    box-shadow: none;
    padding: 5px;
}

#searchSuggestionsDropdown[data-visible="yes"] {
    @media (max-width: 1021px) {
        border-radius: 5px;
        width: calc(100vw - 30px);
        left: 15px;
    }

    @media (max-width: 767px) {
        top: calc(var(--top) + -5px);
        max-height: calc(100vh - var(--top) + 5px);
    }
}

#divBagItemsChild .bagHeader {
    flex: 0 0 auto;
}

#divBagItemsChild .bagHeader p {
    display: flex;
    text-transform: capitalize;
    font-size: 1rem;
    border-bottom: 1px solid #ddd;
    text-align: left;
    padding: 30px 24px;
    margin: 0;
}

#divBagItemsChild .bagHeader #clsBasketMob {
    font-size: 1em;
    width: 42px;
    right: 10px;
    font-family: initial;
    top: 18px;
    height: 42px;
    line-height: 30px;

    .global-icon-cross {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.glyphicon-remove::before {
        content: none !important;
    }
}

/*--new sdl layout --*/

.sdlProdList .productFilterList .SelectableFilter,
.sdlProdList .productFilterList .SelectedFilter {
    background: none;
    padding: 0;
    line-height: 1.1em;
    display: flex;
    align-items: center;
}

.sdlProdList .productFilterList .FilterValue,
.sdlProdList .productFilterList .FilterName {
    float: none;
    vertical-align: middle;
    display: inline;
    line-height: 18px;
    width: auto;
}

.sdlProdList .productFilterList .FilterValue {
    font-size: 10px;
    letter-spacing: 0.05em;
    background: #dde1e4;
    color: #0000ed;
    font-weight: var(--fw-bold);
    padding: 2px 4px 3px;
    border-radius: 37px;
    margin-left: 8px;
}

.sdlProdList .productFilterList .FilterName {
    font-size: 15px;
    color: #161616;
    padding-left: 12px;
    line-height: 24px;
}

.sdlProdList .productFilterList .FilterName:hover {
    text-decoration: none;
}

.sdlProdList .productFilterList .SelectableFilter::after,
.sdlProdList .productFilterList .SelectedFilter::after,
.sdlProdList .productFilterList .FilterAnchor .MobSortSelectionCheckbox::before {
    content: none;
}

.sdlProdList .layout li {
    height: 35px;
    padding: 0;
}

.sdlProdList .layout li a {
    height: 100%;
    width: 30px;
}

.sdlProdList .layout li.colopt3 a {
    background-position: -97px -20px;
}

.sdlProdList .layout li.colopt3:hover a,
.sdlProdList .layout li.colopt3.selected a {
    background-position: -97px -51px;
}

.sdlProdList .layout li.colopt4 a {
    background-position: -131px -20px;
}

.sdlProdList .layout li.colopt4 a:hover,
.sdlProdList .layout li.colopt4.selected a {
    background-position: -131px -51px;
}

.PSPlacementWrapper .PSPlacementTitle,
#pnlRecentlyViewedProducts .AltStratTitle {
    text-align: left;
    text-transform: uppercase;
    line-height: 1.2em;
    font-weight: var(--fw-bold);
    padding: 10px 0 30px;
    border: 0;
    margin: 0;
    font-size: 1.7em;
    letter-spacing: 0.05em;
    color: #000;
}

.sdDetails .PSPlacementWrapper,
.sdDetails #pnlRecentlyViewedProducts {
    border: 0;
    margin: 4px 0 0;
}

.sdDetails .PSPlacementWrapper .PSPlacementHorizontal,
.sdDetails #pnlRecentlyViewedProducts .AltVertOne {
    margin: 0;
}

#pnlRecentlyViewedProducts .AltVertOne .AltStratProdImg {
    display: block;
    padding: 0;
}

#pnlRecentlyViewedProducts .AltVertOne .AltStratProdImg img {
    max-width: none;
    width: 100%;
}

.PSPlacementWrapper .ProductSuggestionsListing li a {
    display: block;
}

.PSPlacementWrapper .SuggestedProduct > h4,
#pnlRecentlyViewedProducts h4 {
    text-align: left;
    font-size: 1em;
    font-weight: var(--fw-normal);
    padding: 0;
}

.PSPlacementWrapper .SuggestedProduct > h4 a .PSProdBrand,
#pnlRecentlyViewedProducts .AltStratBrand {
    font-size: 12px;
    font-weight: var(--fw-normal);
    text-transform: none;
}

.PSPlacementWrapper .SuggestedProduct > h4 a .PSProdTitle,
.PSPlacementWrapper .SuggestedProduct > h4 a .PSProdName,
#pnlRecentlyViewedProducts .AltStratDescription {
    font-size: 1.2em;
    color: #000;
}

.PSPlacementWrapper .SuggestedProduct .PSProdPrice,
#pnlRecentlyViewedProducts .AltStratRefSell {
    padding: 5px 5px 0;
    text-align: left;
    height: auto;
}

.PSPlacementWrapper .SuggestedProduct .productimage.s-productthumbimage {
    padding: 5px 10px;
}

.PSPlacementWrapper .productimage .PSImage .PSPimg-res {
    max-width: 180px;
    width: 100%;
}

.PSPlacementWrapper .SuggestedProduct .PSSellPrice,
#pnlRecentlyViewedProducts .AltStratSellPrice {
    letter-spacing: 0.05em;
    font-size: 1.2em;
    display: inline-block;
    line-height: 1.1em;
}

.PSPlacementWrapper .SuggestedProduct .PSFrom,
#pnlRecentlyViewedProducts .AltFrom {
    font-size: 1.1em;
    display: inline-block;
    line-height: 1.2em;
    padding-right: 2px;
}

.PSPlacementWrapper .SuggestedProduct .PSFromSell,
#pnlRecentlyViewedProducts .AltFromSell {
    display: inline-block;
    line-height: normal;
    padding-right: 5px;
}

#pnlRecentlyViewedProducts .AltStratProduct {
    margin-bottom: 0;
}

#pnlRecentlyViewedProducts .AltStratBrandDesc {
    display: block;
}

.PSPlacementWrapper .SuggestedProduct .PSTicketSell,
#pnlRecentlyViewedProducts .AltStratProduct .AltStratRefPriName {
    padding: 0;
    line-height: normal;
}

.PSPlacementWrapper .PSProdPrice .AdditionalPriceLabel,
#pnlRecentlyViewedProducts .AltStratRefSell .AdditionalPriceLabel {
    padding: 0 2px 0 0;
    line-height: 1.2em;
    font-size: 1.1em;
    color: #888;
    display: inline-block;
}

.PSPlacementWrapper .SuggestedProduct .ticketPricePrefix,
.PSPlacementWrapper .SuggestedProduct .PSRefPrice,
#pnlRecentlyViewedProducts .AltStratProduct .AltStratRefPrice {
    display: inline-block;
    font-size: 1.1em;
    line-height: 1.2em;
}

.sdDetails #pnlRecentlyViewedProducts .AltStratTitle,
.sdDetails #pnlRecentlyViewedProducts .recently-viewed-swiper-container .swiper-wrapper {
    padding-left: 15px;
}

.sdDetails #pnlRecentlyViewedProducts .AltVertOne {
    max-width: 1600px;
    margin: 0 auto;
}

.sdDetails #pnlRecentlyViewedProducts .recently-viewed-swiper-container .hotspotbuy.hotspotquickbuy,
.sdDetails #BodyWrap #pnlRecentlyViewedProducts .recently-viewed-swiper-container #divHotspotWishlist {
    display: none !important;
}

@media (min-width: 768px) and (max-width: 1021px) {
    .sdDetails #pnlRecentlyViewedProducts .AltStratTitle,
    .sdDetails #pnlRecentlyViewedProducts .recently-viewed-swiper-container .swiper-wrapper {
        padding-left: 40px;
    }
}
@media (min-width: 1022px) {
    .sdDetails #pnlRecentlyViewedProducts .recently-viewed-swiper-container {
        padding: 0 40px;
    }

    .sdDetails #pnlRecentlyViewedProducts .AltStratTitle {
        padding-left: 40px;
    }

    .sdDetails #pnlRecentlyViewedProducts .recently-viewed-swiper-container .swiper-wrapper {
        padding: 0 16px;
        transform: translate3d(-16.66667px, 0px, 0px) !important;
        width: auto !important;
        min-width: auto;
    }
}

.SuggestedProduct .hotspotbuy.hotspotquickbuy,
.SuggestedProduct .hotspotbuy.hotspotwishlist {
    top: 0;
    bottom: auto;
}

.sdlProdList .QuickLookIcon {
    background-position: -20px -533px;
    background-size: 800px 800px;
}

.sdlProdList .QuickLookIcon:hover {
    background-position: -65px -533px;
}

/* PLP update '19 */
.sdlProdList .pagination .layout {
    text-align: left;
}

.pagination .sortbyfilter {
    text-align: right;
}

.sdlProdList .pagination .layout,
.pagination .sortbyfilter {
    border: none;
}

@keyframes skeleton {
    0% {
        background-color: #d9d9d9;
    }

    40% {
        background-color: #eee;
    }

    80% {
        background-color: #d9d9d9;
    }

    100% {
        background-color: #d9d9d9;
    }
}

.s-productthumbbox .s-productthumbimage .ProductImageList img {
    width: 100%;
    height: 100%;
    transition: none;
}

.s-productthumbbox .s-productthumbimage .ProductImageList img::before {
    content: "";
    display: block;
    height: 100%;
}

.sdlProdList .s-productthumbbox .s-productthumbimage:hover .ProductImageList img {
    transform: none;
}

.sdDetails .productImageContentWrapper,
.evanDetails .productImageContentWrapper {
    z-index: 1;
}

.pagination .PageNumber {
    line-height: normal;
}

.PageNumber a,
.PageNumber span.PageSelector {
    height: 48px;
    min-width: 0;
    line-height: 48px;
    margin: 0 5px;
    padding: 0 8px;
}

.PageNumber a.NextLink,
.PageNumber a.PrevLink {
    height: 35px;
    line-height: 35px;
}

@media (max-width: 374px) {
    .PageNumber a,
    .PageNumber span.PageSelector {
        padding: 0 4px;
    }
}

.sdlProdList .s-productthumb-extra {
    font-size: 1em;
    padding: 0;
}

.sdlProdList .s-productthumbbox .s-productthumb-extra .s-productsize {
    padding: 0;
}

.sdlProdList .s-productthumb-extra .sizeText,
.layout li.colopt5 {
    display: none;
}

.pagination-bottom .PageNumber,
.sdlProdList .pagination-bottom .layout {
    text-align: center;
}

.sdlProdList .pagination-bottom .layout,
.Checkout22 .OrderCompleteRegistration .ContinueOn.OrderCompleteRegisteredSignUpButton {
    margin-top: 30px;
}

#lblSellingPrice.productHasRef,
.s-producttext-withticket .s-largered,
.productPrice .nowprice,
.sdlProdList .s-producttext-price.s-producttext-withfrom .s-largered .curprice {
    color: #ed0000;
}

.sdlProdList [data-descfilter="gift voucher"] .s-producttext-price.s-producttext-withfrom .s-largered .curprice,
.sdlProdList [li-name="E Voucher Gift Card"] .s-producttext-price.s-producttext-withfrom .s-largered .curprice {
    color: #000;
}

.s-producttext-top-wrapper,
.columns3 .s-producttext-top-wrapper,
.columns4 .s-producttext-top-wrapper {
    padding: 5px;
}

.sdlProdList .catTemplateNew .viewMore {
    padding-top: 15px;
    text-align: right;
}

.sdlProdList .catTemplateNew .viewMore a {
    cursor: pointer;
}

.sdlProdList .catTemplateNew .viewMore .collapsed span.ClosedView,
.sdlProdList .catTemplateNew .viewMore a,
.sdlProdList .catTemplateNew .viewMore span.OpenView {
    display: inline-block;
}

.sdlProdList .catTemplateNew .viewMore a span {
    font-size: 1.2em;
    display: inline-block;
    vertical-align: top;
}

.sdlProdList .catTemplateNew .viewMore .collapsed span.OpenView,
.sdlProdList .catTemplateNew .viewMore span.ClosedView {
    display: none;
}

.sdlProdList .catTemplateNew .viewMore span.OpenView::after,
.sdlProdList .catTemplateNew .viewMore span.ClosedView::after {
    content: "";
    width: 17px;
    height: 17px;
    margin-left: 5px;
    vertical-align: top;
    display: inline-block;
    background-size: 720px 720px;
    background-position: -165px -374px;
}

.sdlProdList .catTemplateNew .viewMore span.OpenView::after {
    background-position: -165px -402px;
}

.sdlProdList .catTemplateNew .textDesc {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 10px;
    font-size: 1em;
}

.sdlProdList .catTemplateNew .siblingLinks span {
    display: inline-block;
    border: 1px solid #000;
    border-radius: 25px;
}

.sdlProdList .catTemplateNew .siblingLinks span a {
    letter-spacing: 0.1em;
    font-size: 1em;
    padding: 10px 15px;
    display: inline-block;
    transition: all 0.3s ease-out;
}

.sdlProdList .catTemplateNew .siblingLinks span a:hover {
    text-decoration: underline;
}

/* PLP Category Template - FWC - Full Width with CTAs */
.catTemplate-FWC .topSection {
    padding: 0;
}

.catTemplate-FWC .textDesc {
    margin: 0;
    color: #333;
    font-size: 14px;
}

.catTemplate-FWC .siblingLinks {
    padding-top: 15px;
    font-size: 1em;
}

.catTemplate-FWC.inverted {
    background-color: #e1e1e1;
}

.catTemplate-FWC.inverted .siblingLinks a {
    background-color: #fff;
}

.catTemplate-FWC.inverted .siblingLinks a:hover {
    background-color: #f1f1f1;
}

.catTemplate-FWC .imgRight a {
    display: block;
}

.catTemplate-FWC .imgRight img {
    margin: 0;
}

@media (min-width: 768px) and (max-width: 950px) {
    .ControlWrap .storeGroup {
        display: none;
    }
}

@media (min-width: 1366px) {
    .TopNavBar .LogoWrap,
    .TopNavBar .ControlWrap {
        width: 30% !important;
    }

    .ToplinksGroup .search {
        width: 40% !important;
    }
}

@media (min-width: 768px) {
    .ui-autocomplete {
        position: fixed !important;
    }

    .ToplinksGroup .search {
        width: 50%;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .TopNavBar .LogoWrap,
    .TopNavBar .ControlWrap {
        width: 25%;
    }

    .TopNavBar .LogoWrap {
        padding: 8px 5px 8px 20px;
    }

    .PageNumber a,
    .PageNumber span.PageSelector {
        padding: 0 12px;
    }

    .LogoWrap .LogoImg {
        display: block;
        width: auto;
        line-height: 44px;
    }

    .LogoWrap .LogoImg img {
        max-height: 44px;
        display: inline-block;
        max-width: 98px;
    }

    .LogoWrap .LogoInline,
    .CountryRedirectPopupLogoWrapper .LogoInline,
    .LogoCheck .LogoInline {
        width: auto;
        line-height: 44px;
        display: flex;
        align-items: center;
    }

    .CountryRedirectPopupLogoWrapper .LogoInline,
    .LogoCheck .LogoInline {
        justify-content: center;
    }

    .LogoWrap .LogoInline svg,
    .CountryRedirectPopupLogoWrapper .LogoInline svg,
    .LogoCheck .LogoInline svg {
        height: 44px;
        width: 98px;
    }

    .uscSite .LogoWrap img {
        max-width: 164px;
    }

    .FooterWrap .FooterTop .footerStoreFinder,
    .FooterWrap .FooterTop .footerCustServ {
        text-align: left;
    }

    .FooterWrap .FooterTop .footerStoreFinder a,
    .FooterWrap .FooterTop .footerCustServ a {
        display: block;
        position: relative;
        overflow: hidden;
    }

    .FooterWrap .FooterTop .footerStoreFinder a::before,
    .FooterWrap .FooterTop .footerCustServ a::before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .FooterWrap .FooterTop .footerStoreFinder a div,
    .FooterWrap .FooterTop .footerCustServ a div {
        display: block;
        padding-left: 55px;
    }

    .FooterWrap .FooterTop > div {
        display: table-cell;
        float: none;
        vertical-align: middle;
        padding: 0 3%;
    }

    .FooterWrap .FooterTop > div.signup-wrapper {
        padding: 0 3% 0 2%;
    }

    .footerContainer .footerMenu {
        display: flex;
        justify-content: space-between;
        padding: 40px 15px;
        width: 100%;
    }

    .footerContainer .footerMenu::before,
    .footerContainer .footerMenu::after {
        display: none;
    }

    .footerContainer .footerMenu > div {
        width: auto;
    }

    .Copyright .footerCopyLine {
        width: auto;
        border: none;
        display: flex;
        align-items: center;
        padding: 0;
        flex: 1 1 auto;
    }

    .sdlProdList .catTemplateNew .collapse {
        display: block !important;
        height: auto !important;
    }

    .catTemplate .textLeft {
        margin: 35px 0 15px;
        color: #999;
        line-height: 1.8em;
        padding: 0 5%;
        letter-spacing: 0.1em;
    }

    .catTemplate-FWC .siblingLinks a {
        padding: 12px 20px;
    }

    #delivery_Nextday .TitleDel,
    #NextDayDelivery .TitleDel {
        width: calc(100% - 40px);
    }
}

@media (min-width: 768px) and (max-width: 1050px) {
    .FooterWrap .FooterTop > div {
        padding: 0 10px;
    }

    .footerContainer .footerMenu {
        justify-content: space-around;
    }
}

@media (max-width: 767px) {
    .ToplinksGroup .container-fluid > .row > .search {
        height: 54px;
        padding: 0 15px 10px;
        z-index: 4;
        width: 100%;
    }

    .searchFocus .ToplinksGroup .container-fluid > .row > .search,
    .body-menu-open .ToplinksGroup .container-fluid > .row > .search {
        z-index: 105;
    }

    #BodyWrap .ContentWrapper {
        padding-bottom: 64px;
    }

    .catTemplate.catTemplateNew .siblingLinks {
        padding-top: 15px;
    }

    .catTemplate-FWC .siblingLinks a {
        margin: 0 10px 10px 0;
    }
}

.ui-autocomplete .header {
    margin-bottom: 5px;
}

.ui-autocomplete > li > .ui-corner-all {
    font-size: 1.2em;
    padding: 10px;
    font-weight: var(--fw-normal);
}

.Login .newlogin .myAccLogin .logIcon {
    background-position: -11px -214px;
}

.paginationWrapper {
    padding-bottom: 10px;
}

.sdlProdList .layout span.ViewbyCols,
.sdlProdList .pagination .sortbyfilter label {
    font-size: 1.2em;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.sdlProdList .dropprods_Order {
    max-width: 70%;
    width: auto;
    font-size: 1.2em;
    padding: 0 8px;
    min-width: 200px;
}

.RightBarLinks div.TopLinkMenu > a,
.Responsive .IconBagandWish {
    transition: background-color 0.3s ease-in;
}

.ToplinksGroup #mobSearchContainer {
    display: none;
    background-color: #0e0e0e;
    width: 60px;
    height: 60px;
    float: right;
    position: absolute;
    right: 5px;
    top: 0;
    padding: 0;
}

.ToplinksGroup #mobSearchContainer #mobileSearchTriggerBtn {
    background: none;
    text-indent: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.ToplinksGroup #mobSearchContainer #mobileSearchTriggerBtn > span {
    display: inline-block;
    height: 30px;
    width: 30px;
    font-size: 12px;
    background-size: 800px 800px;
    background-position: -193px -333px;
}

/*--media queries--*/
@media (max-width: 1260px) and (min-width: 1022px) {
    nav
        #topMenu
        > ul
        .NewMenuWrap
        .sportsDropdown
        .Dropdown
        .Center
        > ul
        > li:first-child
        + li
        + li
        + li
        > ul
        > li.sportsEquestrian
        > a,
    nav#topMenu
        > ul
        .NewMenuWrap
        .sportsDropdown
        .Dropdown
        .Center
        > ul
        > li:first-child
        + li
        + li
        + li
        > ul
        > li.sportsFootball
        > a,
    nav#topMenu
        > ul
        .NewMenuWrap
        .sportsDropdown
        .Dropdown
        .Center
        > ul
        > li:first-child
        + li
        + li
        + li
        > ul
        > li.sportsRunning
        > a {
        font-size: 1em;
        padding: 72px 0 0;
    }

    nav#topMenu
        > ul
        .NewMenuWrap
        .sportsDropdown
        .Dropdown
        .Center
        > ul
        > li:first-child
        + li
        + li
        + li
        > ul
        > li.sportsEquestrian
        > a::before,
    nav#topMenu
        > ul
        .NewMenuWrap
        .sportsDropdown
        .Dropdown
        .Center
        > ul
        > li:first-child
        + li
        + li
        + li
        > ul
        > li.sportsFootball
        > a::before,
    nav#topMenu
        > ul
        .NewMenuWrap
        .sportsDropdown
        .Dropdown
        .Center
        > ul
        > li:first-child
        + li
        + li
        + li
        > ul
        > li.sportsRunning
        > a::before {
        font-size: 12.87px;
    }
}

@media (min-width: 1022px) {
    .FooterInner > div {
        margin-bottom: 10px;
    }

    #topLinkMenu ul li.TopLinkDrop ul.TopSubLinkMenu {
        opacity: 1;
    }

    .ResponsiveSwitch {
        display: none;
    }

    /* New PLP '19 */
    #innerfiltercontainer {
        padding: 0 10px 0 0;
    }

    .sdlProdList .s-productscontainer2 > li {
        margin-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
    }

    #divBag .HeaderBagEmptyMessage,
    #divBag .HeaderCheckoutLink {
        font-size: 1.1em;
    }

    /* PDP Elevation 21 */
    #productImages #productImageContainer .productImage.productImageGrid .innerImageContainer a {
        flex: 0 0 calc(50% - 5px);
    }

    #productImages #productImageContainer .productImage.productImageGrid .gridDisplayLess3 .innerImageContainer a,
    #productImages
        #productImageContainer
        .productImage.productImageGrid
        .gridDisplay3
        .innerImageContainer
        a:nth-of-type(1) {
        flex: 0 0 100%;
    }
}

@media (min-width: 768px) {
    #HeaderGroup {
        height: 100px;
    }

    .ToplinksGroup .container-fluid {
        padding: 0;
    }

    .signup-wrapper .signupInner .join-us-text {
        padding-top: 0;
        overflow: hidden;
    }

    .signup-wrapper .signupInner .signupInputBox {
        flex-direction: row;
        justify-content: center;
        margin-bottom: 0;
    }

    .signup-wrapper .signupInner .NewsletterPadding,
    #signupEmail {
        width: 500px;
        padding-right: 8px;
    }

    .signup-wrapper .signupInner .newsLetterButton,
    #enterNewsLetter {
        width: 200px;
        font-weight: var(--fw-normal);
    }

    .signup-wrapper .signupInner .disclaimerText {
        display: block;
        margin-top: 10px;
        color: #bbb;
    }

    .signup-wrapper .signupInner .disclaimerText a {
        color: #bbb;
    }

    .signup-wrapper .signupInner .disclaimerText p {
        color: #bbb;
        font-size: 0.7rem;
    }

    nav#topMenu ul li.mobOnly {
        display: none;
    }

    .HeaderTopSpacer {
        height: 100px;
    }

    nav#topMenu li.root .SubMenuWrapper > ul .botList > a::before {
        content: "> ";
    }

    nav#topMenu li.root .SubMenuWrapper > ul .viewAllBold > a {
        font-weight: var(--fw-bold);
    }

    .FooterSubGroup {
        border-bottom: 0;
    }

    .FooterArea {
        margin-left: 20px;
    }

    .footerMenu > .row {
        width: 25%;
        float: left;
    }

    .bsIconsBagAndWish {
        height: 40px;
    }

    .bsASB {
        padding-top: 11px;
    }

    .bsASB > div {
        padding-left: 0;
        padding-right: 0;
    }

    .Responsive .IconBagandWish {
        border: 1px solid #ddd;
        background-color: #f5f5f5;
    }

    .Responsive .IconBagandWish:hover {
        border: 1px solid #eee;
        background-color: #fafafa;
    }

    .footerPane {
        width: 50%;
    }
}

@media (min-width: 1022px) and (max-width: 1199px) {
    nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1::before {
        padding-bottom: 45%;
    }
}

@media (max-width: 767px) {
    #TopPaginationWrapper.sticky .paginationWrapper {
        top: 114px;
    }
}

@media (max-width: 1021px) {
    .searchFocus .ToplinksGroup #mobSearchContainer {
        display: block;
        z-index: 11;
    }

    .recently-viewed-swiper-container {
        overflow: visible;
    }

    #pnlRecentlyViewedProducts .AltVertOne .AltStratProduct {
        width: 200px;
    }

    .ChildCategoriesListWrapper + .refineByLeftHeader .mobDdClose {
        top: auto;
        bottom: 0;
    }

    .Responsive .mobDdClose {
        width: 40px;
        line-height: 40px;
        height: 40px;
        padding: 0;
        border: 0;
    }

    #TopPaginationWrapper {
        min-height: 62px;
        top: auto !important;
        width: 100% !important;
    }

    #TopPaginationWrapper.sticky .paginationWrapper {
        position: fixed;
        width: 100%;
        z-index: 1;
        top: 100px;
        left: 0;
        padding: 8px 16px;
        background-color: #fff;
        box-shadow: 0 0 1px #333;
    }

    nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1::before {
        padding-bottom: 55%;
    }

    nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1.brandsViewAll a {
        padding-top: 19%;
    }

    .desktopNav {
        display: none;
    }

    nav#topMenu ul li.root .SubMenuWrapper ul li.gradBorder.finalGroup::after {
        background-image: none;
        background-size: 0 0;
        content: none;
    }

    nav#topMenu ul li.root > a {
        font-size: 13px;
    }
}

@media (min-width: 768px) and (max-width: 1021px) {
    .FooterWrap .txtLink,
    .FooterColLink2 {
        display: none;
    }

    .ToplinksGroup .container-fluid {
        padding: 0;
    }

    #topMenuWrapper #topMenu {
        padding: 0;
    }

    .currencyLanguageSelector .spanCurrencyLanguageSelector {
        width: 100%;
        text-align: center;
    }

    .Responsive .currencyLanguageSlider {
        right: 5px;
    }

    #divBag .HeaderBagEmptyMessage {
        font-size: 1em;
    }

    #divBag .HeaderCheckoutLink {
        font-size: 1.1em;
        letter-spacing: 0.1em;
    }

    .footerMenu > .row {
        width: 32.5%;
        float: left;
    }

    .footPopLink ul {
        width: 100%;
    }

    .socialMediaContainer li {
        padding: 4px 0 !important;
    }

    .login::before {
        width: 80%;
        background-position: -6px -202px;
    }

    .login:hover::before {
        background-position: -70px -201px;
    }

    .bsLogo {
        padding: 0;
    }

    .basketLink .langlogin .login,
    #findAstore a {
        text-indent: -9999px;
    }

    #navigation-container {
        margin: 2% 0 4%;
    }

    .LanguageCurrency {
        float: right;
    }

    .storefind:hover::before {
        background-position: -97px -99px;
    }

    .storefind::before {
        left: 50%;
        transform: translateX(-50%);
        background-position: -32px -99px;
    }
}

@media (max-width: 767px) {
    .TopNavBar .ToplinksGroup .row > .LogoWrap {
        padding-top: 10px;
        padding-bottom: 10px;
        width: calc(100% - 210px);
    }

    .LogoWrap .LogoImg {
        line-height: 40px;
    }

    .LogoWrap .LogoImg img {
        max-height: 40px;
        max-width: 100px;
    }

    .LogoWrap .LogoInline,
    .CountryRedirectPopupLogoWrapper .LogoInline {
        line-height: 40px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .LogoWrap .LogoInline svg,
    .CountryRedirectPopupLogoWrapper .LogoInline svg {
        height: 40px;
        width: 89px;
    }

    #mp-menu {
        height: calc(100% - 113px);
        width: 100%;
        border-right: 1px solid #a6b9cc;
        z-index: 5895 !important;
        top: 113px !important;
        max-width: none;
    }

    .MenuCloseActive {
        position: fixed;
        width: 46px;
        height: 50px;
        padding: 0;
        right: -46px;
        top: 113px;
        background-color: #4480bf;
        background-size: 600px 600px;
        background-position: -131px -118px;
        border-bottom: 1px solid #a6b9cc;
        font-size: 11px;
        cursor: pointer;
        z-index: 10071;
        transition: 0.5s ease-in-out;
    }

    .PullMenuActive .MenuCloseActive,
    .body-menu-open .MenuCloseActive {
        right: 0;
    }

    .ToplinksGroup .container-fluid > .row > div {
        background-color: #1c1c1c;
        height: 60px;
    }

    #mobMenuContainer,
    .ToplinksGroup .container-fluid .ControlWrap {
        width: 105px;
    }

    #mobMenuContainer {
        padding-right: 0;
    }

    .ToplinksGroup .container-fluid .ControlWrap {
        padding-left: 0;
    }

    .menu-trigger,
    .ControlWrap .WishList,
    .Responsive #divBag {
        width: 50px;
    }

    #divBagTotalLink a#aBagLink {
        min-width: 50px;
    }

    .FooterWrap .FooterTop .footerStoreFinder::after {
        display: none;
    }

    .footerQuickLinks {
        padding: 15px 15px 0;
    }

    .FooterGroup {
        border: none;
    }

    .FooterGroup::before,
    .FooterGroup::after {
        content: none;
        border: none;
        display: none;
    }

    .footerInfoSection #topLinkMenu ul.TopLinkMenu .iconsStores a::before,
    .footerInfoSection #topLinkMenu ul.TopLinkMenu .iconsCS a::before {
        margin: 0;
    }

    .FooterGroupLink li {
        text-align: left;
    }

    .FooterGroupLink li a {
        padding: 6px 0;
    }

    .FooterGroupLink li a:first-of-type {
        padding: 0 0 6px;
    }

    .FooterGroupLink {
        width: 100%;
        margin: 0 auto;
        padding-bottom: 15px;
    }

    .footerInfoSection {
        border: none;
    }

    .sub-buttons {
        margin: 0;
    }

    .shop > ul > li.root.mobOnly.appExcl > a {
        background-color: #f9f210;
        font-weight: var(--fw-bold);
        text-transform: uppercase;
        font-size: 1.2em;
    }

    .shop > ul > li.root.mobOnly.appExcl > a > .menuitemtext::after {
        color: #ed0000;
        content: " 20% off everything";
    }

    .am-menu ul li.footShirts > a > .menuitemtext::after {
        content: " & Kit";
    }

    .am-menu ul li.surfGround > a > .menuitemtext::after {
        content: " Ground";
    }

    .am-menu ul li.adiBoots > a > .menuitemtext::after {
        content: " Boots";
    }

    .am-menu ul li.level2.botList a {
        background-color: #fff;
    }

    .HeaderTopSpacer {
        height: 114px;
    }

    .FooterWrap .FooterTop .footerStoreFinder div::after {
        display: none;
    }

    .FooterWrap .FooterTop .footerStoreFinder,
    .FooterWrap .FooterTop .footerCustServ {
        text-align: center;
        padding-top: 10px;
    }

    .FooterWrap .FooterTop .footerCustServ::after {
        top: 15px;
        bottom: 0;
    }

    .FooterWrap .FooterTop .footerStoreFinder div,
    .FooterWrap .FooterTop .footerCustServ div {
        padding: 0;
    }

    .signup-wrapper {
        margin-top: 15px;
    }

    .signup-wrapper .signupInner {
        padding: 20px 15px;
    }

    .signup-wrapper .signupInner .join-us-text {
        margin-bottom: 0;
        padding-top: 0;
        width: 100%;
    }

    .FooterSubGroup {
        border-bottom: solid 1px #ddd;
        text-align: center;
    }

    .bsASB {
        padding-top: 6px;
        border-bottom: 2px solid #323232;
        text-align: center;
    }

    .Responsive .IconBagandWish:hover {
        background-color: #fafafa;
    }

    #divBagTotalLink span#bagQuantity {
        width: 100%;
        margin: 0;
        padding: 0;
        line-height: 1.5em;
    }

    #divBagTotalLink a::before {
        background-position: -20px -339px;
    }

    .coprightmega {
        text-align: center;
    }

    .lillBasket #divBagItems,
    #divAjaxLoaderImage,
    #divEmptyErrorMessage {
        right: 0;
        position: fixed !important;
        bottom: 0;
        top: auto;
    }

    .Basket .ads {
        display: none;
    }

    .Responsive .categoryListSide h2 {
        background: #000;
    }

    .csHomeTable {
        min-height: 275px;
    }

    .TopNavBar {
        height: 100%;
        background-color: transparent;
    }

    .TopNavBar .ToplinksGroup .container-fluid .row {
        background-color: #0e0e0e;
    }

    .ToplinksGroup .top-right {
        padding: 8.5px 0 0 !important;
    }

    .footerelements {
        padding: 0;
    }

    .FooterHeader span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 18px;
        font-size: 11px;
        float: none;
        width: 30px;
        background-size: 850px 850px;
        background-position: -400px -385px;
    }

    .FooterHeader span.glyphicon-chevron-down {
        background-position: -439px -385px;
    }

    .FooterHeader span::before {
        display: none;
    }

    .FooterSubGroup .FooterHeader {
        padding: 15px 0;
        text-align: left;
        width: 100%;
        float: none;
        position: relative;
        display: inline-block;
    }

    .FooterSubGroup .FooterHeader span {
        padding: 0;
        right: 0;
        width: 20px;
        height: 18px;
        background-position: -400px -385px;
        background-size: 900px 900px;
    }

    .FooterSubGroup .FooterHeader span.glyphicon-chevron-down {
        background-position: -439px -385px;
    }

    .FootdvSearch {
        padding: 0 2%;
    }

    .socialtext {
        display: none;
    }

    .MobileMenuContentWrap .MobMenIcon {
        display: none;
    }

    .MobileMenuContentWrap > ul li,
    .MobileMenuContentWrap .has-dropdown li.level1 > a,
    .MobileMenuContentWrap #liMobileLanguageSelector,
    .MobileMenuContentWrap #liMobileCurrencySelector,
    .MobileMenuContentWrap > ul li:hover,
    .MobileMenuContentWrap #liMobileLanguageSelector:hover,
    .MobileMenuContentWrap #liMobileCurrencySelector:hover {
        background-color: transparent;
    }

    li#mob-signout a {
        color: #ed0000 !important;
    }

    .logged-in .mp-container .mp-menu .mp-level ul li#mob-signout {
        display: block;
    }

    li#mob-signout {
        display: none;
    }

    .MobileMenuContentWrap #liMobileLanguageSelector span,
    .MobileMenuContentWrap #liMobileCurrencySelector span,
    .mp-menu .mob-login {
        color: #000;
        font-size: 16px;
    }

    .MobileMenuContentWrap #liMobileLanguageSelector .flag {
        display: none;
        background: none;
    }

    .MobileMenuContentWrap .shop > ul > li.root a,
    .MobileMenuContentWrap > .mobMenuGroup li.root a,
    .MobileMenuContentWrap > .mobMenuGroup ~ div li a,
    .MobileMenuContentWrap > .mobMenuGroup ~ ul li a,
    .mp-menu > .mp-level .account-menu a.menuitemtext,
    .mp-menu > .mp-level #divCurrencyLanguageMobile a,
    #liMobileCurrencySelector .currencyOption {
        padding: 18px 25px;
    }

    .mp-menu > .mp-level .account-menu li.panel,
    .mp-menu > .mp-level #divCurrencyLanguageMobile li.panel {
        margin: 0;
        border: 0;
        border-radius: 0;
        box-shadow: none;
    }

    .mp-menu > .mp-level .account-menu .has-dropdown li a,
    .mp-menu > .mp-level #divCurrencyLanguageMobile .has-dropdown li a,
    #liMobileCurrencySelector .currencyOption {
        background-color: #fff;
        padding-left: 35px;
    }

    .MobileMenuContentWrap li#mob-myaccount > a + .am-level ul li a {
        background-color: #fff;
    }

    .MobileMenuContentWrap .shop > ul li.sdmMobViewAll a {
        font-weight: var(--fw-bold);
    }

    .MobileMenuContentWrap > .mobMenuGroup > li.root > a::before,
    .MobileMenuContentWrap > .mobMenuGroup ~ div li.has-dropdown > a::before,
    .MobileMenuContentWrap > .mobMenuGroup ~ ul li a.mob-login::before {
        content: "";
        display: none;
        width: 20px;
        height: 20px;
        vertical-align: middle;
        margin-right: 5px;
        background-size: 500px 500px;
    }

    .MobileMenuContentWrap > .mobMenuGroup ~ ul li a.mob-login::before {
        margin-right: 8px;
        background-position: -15px -107px;
    }

    .mobMenuGroup ~ ul li a.mob-login {
        display: none;
    }

    .MobileMenuContentWrap .shop > ul > li.root.mobSection > a::before,
    .uscSite .MobileMenuContentWrap .shop > ul > li.root.uscxsdBackSD > a::before {
        content: "";
        border: 0;
        border-top: 1px solid #0000ed;
        border-image-source: linear-gradient(to right, #ee3424 50%, #0000ed 50%);
        border-image-slice: 1;
        top: 0;
        left: 0;
        position: absolute;
        height: 1px;
        width: 100%;
    }

    .MobileMenuContentWrap .shop > ul > li.root.mobSection.open > a::before {
        content: none;
    }

    .MobileMenuContentWrap li#mob-myaccount > a::before {
        background-position: -16px -108px;
    }

    .MobileMenuContentWrap li#mob-help > a::before {
        background-position: -16px -241px;
    }

    .MobileMenuContentWrap li#mob-jobs > a::before {
        background-position: -16px -265px;
    }

    .MobileMenuContentWrap li#mob-storelocator > a::before {
        background-position: -16px -131px;
    }

    .MobileMenuContentWrap li#mob-wishlist > a::before {
        background-position: -112px -193px;
    }

    .MobileMenuContentWrap li#liMobileLanguageSelector > a::before {
        display: none;
    }

    .MobileMenuContentWrap li#liMobileCurrencySelector > a::before {
        background-position: -43px -240px;
    }

    .currency-eur .MobileMenuContentWrap li#liMobileCurrencySelector > a::before {
        background-position: -73px -240px;
    }

    .currency-aud .MobileMenuContentWrap li#liMobileCurrencySelector > a::before,
    .currency-usd .MobileMenuContentWrap li#liMobileCurrencySelector > a::before,
    .currency-nzd .MobileMenuContentWrap li#liMobileCurrencySelector > a::before,
    .currency-sgd .MobileMenuContentWrap li#liMobileCurrencySelector > a::before {
        background-position: -173px -240px;
    }

    .currency-czk .MobileMenuContentWrap li#liMobileCurrencySelector > a::before {
        background-position: -106px -240px;
    }

    .currency-huf .MobileMenuContentWrap li#liMobileCurrencySelector > a::before {
        background-position: -206px -240px;
    }

    .currency-isk .MobileMenuContentWrap li#liMobileCurrencySelector > a::before {
        background-position: -240px -240px;
    }

    .currency-myr .MobileMenuContentWrap li#liMobileCurrencySelector > a::before {
        background-position: -306px -240px;
    }

    .currency-pln .MobileMenuContentWrap li#liMobileCurrencySelector > a::before {
        background-position: -273px -240px;
    }

    #liMobileLanguageSelector a {
        color: #000;
    }

    #liMobileLanguageSelector a.mp-back,
    #liMobileCurrencySelector a.mp-back {
        display: inline-block;
        position: absolute;
        background-color: #fff;
        left: 0;
        top: 7px;
    }

    #divCurrencyLanguageMobile a.mp-back {
        padding: 5px;
        left: 22px;
        top: 14px;
    }

    #liMobileLanguageSelector a.mp-back::before,
    #liMobileCurrencySelector a.mp-back::before {
        position: absolute;
        left: 20px;
        top: 50%;
        background-size: 600px 600px;
        background-position: -300px -130px;
    }

    #liMobileLanguageSelector a.mp-back span,
    #liMobileCurrencySelector a.mp-back span {
        font-size: 20px;
        color: #000;
        display: none;
    }

    #liMobileCurrencySelector .currencyOption label,
    #liMobileCurrencySelector .currencyOption input {
        display: inline-block;
        margin: 0;
        vertical-align: middle;
        padding-right: 5px;
        font-weight: var(--fw-normal);
        font-size: 16px;
    }

    #liMobileCurrencySelector .currencyOption label {
        margin-left: 14px;
    }

    .MobileMenuContentWrap > .mobMenuGroup,
    #divCurrencyLanguageMobile,
    .MobileMenuContentWrap > .mobMenuGroup ~ ul,
    .mp-menu > .mp-level .mobile-menu-footer-content > .account-menu > li.root {
        background-color: #e7ebed;
    }

    #mp-menu .shop > ul > li#liAllDepts {
        display: none;
    }

    .am-menu ul li.level2 a {
        padding-left: 30px;
    }

    .am-menu ul li.level2.indentSub a {
        padding-left: 35px;
    }

    .am-menu ul li.level2.sdmColHeader a {
        padding-left: 25px;
        background-color: #f0f0f0;
    }

    #mp-menu .mp-level .mp-level .mp-level .MobMenChevron {
        margin: 0;
        padding-left: 40px !important;
        width: 100%;
    }

    #mp-menu .mp-level .mp-level .mp-level .mobMenToplink > .MobMenChevron {
        padding-left: 0 !important;
    }

    #mp-menu .mp-level .mp-level .mp-level .sdmColHeader > .MobMenChevron {
        font-weight: var(--fw-bold);
        padding-left: 25px !important;
    }

    .mp-menu .mp-level #mobile-menu-footer a.MobMenChevron::after,
    .mp-menu .mp-level #mobile-menu-footer-content a.MobMenChevron::after {
        background-size: 750px 750px;
        background-position: -333px -362px;
        top: 50%;
        transform: translateY(-50%);
    }

    .am-menu .am-level a.MobMenChevron::after,
    .mp-menu .mp-level a.MobMenChevron::after {
        background-size: 750px 750px;
        background-position: -393px -362px;
        top: 50%;
        transform: translateY(-50%);
    }

    ul.mobMenuGroup.account-menu,
    .mp-menu > .mp-level .languageSelectorMobile,
    .mp-menu > .mp-level #divCurrencyLanguageMobile .currencySelectorMobile {
        background-color: #fff;
    }

    .mp-menu .mp-level #mobile-menu-footer a.MobMenChevron.open::after,
    .mp-menu .mp-level #mobile-menu-footer-content a.MobMenChevron.open::after {
        background-position: -366px -362px;
    }

    .shop #mob-newsletter {
        display: none;
    }

    .ui-autocomplete {
        z-index: 10071 !important;
        right: 15px !important;
        left: 15px !important;
        width: calc(100% - 30px) !important;
        top: 110px !important;
        max-width: none;
        max-height: calc(100% - 125px);
    }

    .ui-autocomplete > li > .ui-corner-all {
        padding-left: 20px;
    }

    #mp-menu .mp-level .mobMenSplit,
    #mp-menu .am-level .mobMenSplit {
        position: relative;
    }

    #mp-menu .mp-level .mobMenSplit::before,
    #mp-menu .mp-level .mobMenSplit::after,
    #mp-menu .am-level .mobMenSplit::before,
    #mp-menu .am-level .mobMenSplit::after {
        content: "";
        display: block;
        position: absolute;
        top: -1px;
        left: 0;
        right: 0;
        height: 1px;
        background: linear-gradient(to right, #ee3424 50%, #0000ed 50%);
    }

    #mp-menu .mp-level .mobMenSplit::before,
    #mp-menu .am-level .mobMenSplit::before {
        background: #fff !important;
        z-index: 0;
    }

    #mp-menu ul li.mobMenToplink {
        position: absolute;
        margin: 0;
        top: 18px;
        right: 25px;
        width: auto;
        background-color: transparent;
        height: auto !important;
    }

    #mp-menu ul li.mobMenToplink a.menuitemtext {
        color: #0000ed;
        padding: 0;
        box-shadow: none;
        background-color: transparent;
        font-size: 14px;
        border-bottom: 2px solid #0000ed;
        line-height: 1.4em;
        margin: 0;
        font-weight: var(--fw-normal);
        width: auto;
    }

    .MenuGroupBrands > .mp-level > ul {
        display: flex;
        flex-wrap: wrap;
    }

    .MenuGroupBrands .mp-level ul li {
        width: 100%;
    }

    .MenuGroupBrands > .mp-level ul li.brandsGrid {
        width: 50%;
    }

    .MenuGroupBrands .mp-level ul li.brandsGrid > a {
        text-indent: -9999px;
        padding: 0;
        font-size: 0 !important;
        margin-left: 0;
        width: 100%;
    }

    .brandsGrid .mobImg::before {
        z-index: 2;
    }

    .brandsGrid .mobImg .overlayMenuBrands {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.3);
    }
}

nav#topMenu ul li.root.saleRed > a,
nav#topMenu ul li.root.saleRed > a:hover {
    background-color: #f00;
    color: #fff;
}

#mp-menu .mp-level .saleRed,
#mp-menu .mp-level .saleRed > .menuitemtext {
    background-color: #f00;
    color: #fff;
}

.mp-menu .mp-level .saleRed a.MobMenChevron::after {
    background-position: -394px -319px;
}

/*--menu advert --*/
.singlecolumnadvert .view-button {
    display: block;
    font-size: 0.9em;
    background-color: #ccc;
    padding: 15px;
    text-align: center;
}

.modal .modal-header {
    padding: 10px 44px;
}

.modal .close:hover {
    background-color: transparent !important;
}

#advertPopup .close {
    background-position: -259px -487px !important;
}

#advertPopup .close:hover {
    background-position: -302px -487px !important;
}

.MagazineModal .close {
    width: 39px;
    height: 39px;
}

#hotspotModal .modal-header {
    position: absolute;
    height: 49px;
}

#hotspotModal .close {
    background-size: 700px 700px !important;
    background-position: -416px -330px !important;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    right: 8px;
    top: 8px;
}

.MagazineModal .close,
#advertPopup .close,
#hotspotModal .close {
    background-color: #fff !important;
}

.MagazineModal .close:hover,
#advertPopup .close:hover,
#hotspotModal .close:hover {
    background-color: #fff !important;
}

/* App forced product */
#BasketModal.appPromoMug .modal-dialog {
    max-height: 500px;
    max-width: 400px;
}

#BasketModal.appPromoMug .modal-body {
    height: 450px;
    max-height: 450px;
    overflow-x: visible;
    overflow-y: visible;
    padding: 15px 25px 20px;
}

#BasketModal.appPromoMug #workarea {
    border: none;
}

#BasketModal.appPromoMug .modal-header .header-text {
    display: none;
}

.forcedProductInnerContent {
    padding-top: 100px;
}

.forcedProductInnerContent .forcedProductImage {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    background-image: url("/images/marketing/mugshot.png");
    height: 200px;
    width: 200px;
    background-size: contain;
    position: absolute;
    top: -95px;
    left: 50%;
    margin-left: -119px;
}

.forcedProductInnerContent .forcedProductContentTitle {
    text-transform: uppercase;
    font-size: 2.7em;
    font-weight: var(--fw-normal);
    letter-spacing: 2px;
}

.forcedProductInnerContent .forcedProductContentTitle span {
    font-weight: var(--fw-bold);
}

.forcedProductInnerContent .forcedProductContentTitle .rStar,
.forcedProductInnerContent .forcedProductContentTitle .lStar {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
    vertical-align: top;
    background-image: url("/images/core/sd-prod-list-prod-detail-sprite-updated-v6.svg");
    background-size: 22em 22em;
    background-position: -2.2em -6.4em;
}

.forcedProductInnerContent .forcedProductContentTitle .rStar {
    margin-left: 10px;
}

.forcedProductInnerContent .forcedProductContentTitle .lStar {
    margin-right: 10px;
}

.forcedProductInnerContent .forcedProductContentTitle .lStar::before,
.forcedProductInnerContent .forcedProductContentTitle .rStar::before {
    content: " ";
    display: inline-block;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 0;
    background-image: url("/images/core/sd-prod-list-prod-detail-sprite-updated-v6.svg");
    background-size: 15em 15em;
    background-position: -1.55em -4.39em;
}

.forcedProductInnerContent .forcedProductContentTitle .lStar::before {
    left: -18px;
}

.forcedProductInnerContent .forcedProductContentTitle .rStar::before {
    right: -18px;
}

.forcedProductInnerContent p {
    font-size: 1.5em;
    margin-bottom: 45px;
}

.forcedProductInnerContent p.forcedProductAddMessage {
    font-weight: var(--fw-bold);
    font-style: normal;
    letter-spacing: 1px;
    margin-bottom: 5px;
}

.forcedProductInnerContent p.forcedProductAddMessage span {
    text-transform: uppercase;
}

.forcedProductInnerContent p.discountMessage {
    font-size: 1.1em;
    margin-bottom: 20px;
}

#BasketModal.appPromoMug .buttoncontainer {
    padding: 0;
}

#BasketModal.appPromoMug .buttoncontainer > .ImgButWrap {
    display: block;
    margin-top: 15px;
    padding: 0;
}

#BasketModal.appPromoMug .buttoncontainer > .ImgButWrap a {
    padding: 18px 15px;
    font-size: 1.6em;
    font-weight: var(--fw-bold);
    line-height: 1.5em;
}

#BasketModal.appPromoMug .buttoncontainer > .ImgButWrap a::before {
    content: "";
    display: inline-block;
    width: 27px;
    height: 20px;
    vertical-align: sub;
    margin-right: 10px;
    background-image: url("/images/core/sd-prod-list-prod-detail-sprite-updated-v6.svg");
    background-size: 30em 30em;
}

#BasketModal.appPromoMug .buttoncontainer > .ImgButWrap:first-child a::before {
    background-position: 9.95em 19.2em;
}

#BasketModal.appPromoMug .buttoncontainer > .ImgButWrap:first-child + .ImgButWrap a::before {
    background-position: 7.6em 17.2em;
}

@media (min-width: 768px) {
    #BasketModal.appPromoMug .modal-dialog {
        width: 400px;
    }
}

@media (max-width: 767px) {
    #BasketModal.appPromoMug .modal-body {
        height: 400px;
        max-height: 400px;
    }

    .forcedProductInnerContent {
        padding-top: 70px;
    }

    .forcedProductInnerContent .forcedProductImage {
        width: 150px;
        height: 150px;
        top: -78px;
        margin-left: -90px;
    }

    .forcedProductInnerContent p {
        margin-bottom: 25px;
    }
}

#advertPopup .modal-dialog {
    max-width: 634px;
    height: 100%;
    max-height: 634px;
}

#advertPopup .modal-body {
    max-width: 632px;
    height: 632px;
    max-height: 632px;
}

@media (max-width: 633px) {
    #advertPopup .modal-dialog {
        max-height: 633px;
    }

    #advertPopup .modal-body {
        height: 631px;
        max-height: 631px;
    }
}

@media (max-width: 594px) {
    #advertPopup .modal-dialog {
        max-height: 594px;
    }

    #advertPopup .modal-body {
        height: 592px;
        max-height: 592px;
    }
}

@media (max-width: 549px) {
    #advertPopup .modal-dialog {
        max-height: 549px;
    }

    #advertPopup .modal-body {
        height: 547px;
        max-height: 547px;
    }
}

@media (max-width: 504px) {
    #advertPopup .modal-dialog {
        max-height: 504px;
    }

    #advertPopup .modal-body {
        height: 502px;
        max-height: 502px;
    }
}

@media (max-width: 459px) {
    #advertPopup .modal-dialog {
        max-height: 459px;
    }

    #advertPopup .modal-body {
        height: 457px;
        max-height: 457px;
    }
}

@media (max-width: 414px) {
    #advertPopup .modal-dialog {
        max-height: 414px;
    }

    #advertPopup .modal-body {
        height: 412px;
        max-height: 412px;
    }
}

@media (max-width: 375px) {
    #advertPopup .modal-dialog {
        max-height: 375px;
    }

    #advertPopup .modal-body {
        height: 373px;
        max-height: 373px;
    }
}

@media (max-width: 348px) {
    #advertPopup .modal-dialog {
        max-height: 348px;
    }

    #advertPopup .modal-body {
        height: 346px;
        max-height: 346px;
    }
}

@media (max-width: 320px) {
    #advertPopup .modal-dialog {
        max-height: 320px;
    }

    #advertPopup .modal-body {
        height: 318px;
        max-height: 318px;
    }
}

.FastPayStatusBox p,
.FastPaySettingRowButtons div {
    color: #0154a4 !important;
}

/*--- new size guide override colours --*/
a.list-group-item:hover,
a.list-group-item:focus,
.primaryColor {
    background-color: #0054a4 !important;
    border-color: #0054a4 !important;
}

.list-group-item.list-group-submenu:hover,
.list-group-item.list-group-submenu:focus {
    background-color: #0054a4 !important;
}

.headRow .divtableCell {
    background-color: #0054a4 !important;
}

/* adi launch */
#superModal .modal-dialog {
    max-width: 902px;
    width: 100%;
    max-height: 370px;
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
}

#superModal .modal-body {
    height: 100%;
    width: 100%;
    padding: 0;
}

#superModal .modal-header {
    height: 0;
    border: 0;
    padding: 0;
}

#superModal #finalFrame {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

#superModal #finalFrame img {
    margin-bottom: 0;
}

.catTemplate #videoCover {
    cursor: pointer;
    z-index: 2;
    transition: all 0.7s ease-in;
}

/*-- parallax page --*/
.parallax .TopLinkBar {
    height: 5px;
}

.parallax .TopNavBar {
    top: 5px;
}

.productFilterList .FilterName {
    width: auto;
    font-size: 1.2em;
}

.productFilterList .FilterValue {
    float: left;
    width: auto;
    margin-left: 5px;
    font-size: 1.2em;
}

.productFilterList .SelectedFilter > span {
    font-weight: var(--fw-normal);
}

.productFilter .productFilterList::-webkit-scrollbar,
.popupFullscreen .modal-body::-webkit-scrollbar {
    width: 5px;
}

.productFilter .productFilterList::-webkit-scrollbar-track,
.popupFullscreen .modal-body::-webkit-scrollbar-track {
    background: #ddd;
}

.productFilter .productFilterList::-webkit-scrollbar-thumb,
.popupFullscreen .modal-body::-webkit-scrollbar-thumb {
    background: #0000ed;
    border-radius: 5px;
    border: none;
}

/** Firefox scrollbar */
.productFilter .productFilterList,
.popupFullscreen .modal-body {
    scrollbar-width: thin;
}

@media (min-width: 1022px) {
    .AltProdList ul.productFilters > li {
        padding: 0 0 25px;
    }

    .productFilterTitleBox,
    .productFilterTitleBoxCLOSED {
        margin-bottom: 10px;
    }

    .AltProdList ul.productFilters > li .mobFiltInnerWrap {
        position: relative;
    }

    #FiltersAndProductsWrapper #FilterContainer ul.productFilters .productFilter .FilterAnchor {
        padding: 5px 5px 5px 0;
    }

    .productFilterList {
        border: none;
        padding: 0;
    }

    .productFilter .productFilterList .brandSearchSort {
        padding: 0 10px 0 0;
    }
}

#accountMenu .sidebar-nav li.active a,
.cprefs section h2 {
    color: #0000ed;
}

#accountMenu .sidebar-nav li.active a::before,
#accountMenu ul.sidebar-nav li.SignOutAcc a,
.cprefs .yesnoSelect label.selected,
.cprefs .cprefsFrequency label.selected {
    background-color: #0000ed;
}

#accountMenu ul.sidebar-nav li.SignOutAcc a:hover {
    background-color: #015fb8;
}

.threeBox h2::before {
    background-position: -269px -284px;
}

.threeBox .cprefsSMS h2::before {
    background-position: -270px -320px;
}

.threeBox .cprefsPost h2::before {
    background-position: -350px -318px;
}

/* LIFT IGNITER */
.li-pr-home,
.li-pr-basket {
    display: block !important;
    padding: 0 0 20px;
}

#li_placement .hotspotbuy {
    position: absolute;
    top: 15px;
}

.li-pr-basket .swiper-container {
    padding-bottom: 10px;
}

#li_placement .hotspotbuy.hotspotquickbuy,
#li_placement .hotspotbuy.hotspotwishlist {
    right: 15px;
    height: 28px;
    width: 28px;
    background: transparent;
}

#li_placement .hotspotbuy.hotspotwishlist {
    right: 50px;
}

.SuggestedProduct h3 a:hover span {
    text-decoration: underline;
    color: #636363;
}

#li_placement .SuggestedProduct .QuickLookIcon,
#li_placement .SuggestedProduct .WishIcon,
#li_placement .hotspotbuy .QuickLookIcon,
#li_placement .hotspotbuy .WishIcon {
    width: 28px;
    height: 28px;
    background-size: 66em 45em;
}

#li_placement .SuggestedProduct .QuickLookIcon,
#li_placement .hotspotbuy .QuickLookIcon {
    background-position: -23px -953px;
}

#li_placement .SuggestedProduct .QuickLookIcon:hover,
#li_placement .hotspotbuy .QuickLookIcon:hover {
    background-position: -67px -953px;
}

#li_placement .SuggestedProduct .hotspotbuy .WishIcon,
#li_placement .hotspotbuy .WishIcon {
    background-position: -23px -1012px;
}

#li_placement .hotspotbuy .SuggestedProduct .WishIcon:hover,
#li_placement .hotspotbuy .WishIcon:hover {
    background-position: -67px -1012px;
}

.PSPlacementHorizontal,
.PSPlacementVertical {
    margin-top: 20px;
}

#li_placement .PSPlacementVertical {
    margin-top: 0;
}

#li_placement .PSPlacementVertical .swiper-wrapper {
    width: auto;
}

.Home .PSPlacementHorizontal .PSPlacementTitle {
    padding: 10px;
    color: #000;
    font-size: 1.6em;
    font-weight: var(--fw-bold);
    text-transform: uppercase;
    margin-bottom: 20px;
    border: none;
}

#li_placement .PSPlacementHorizontal #li_swiper.ProductSuggestionsListing .hotspotbuy {
    display: none !important;
}

.Home .PSPlacementHorizontal .ProductSuggestionsListing li {
    width: 25%;
    text-align: center;
}

.Home .PSPlacementHorizontal .ProductSuggestionsListing li .SuggestedProduct h4 {
    padding: 0;
}

.Home .PSPlacementHorizontal .ProductSuggestionsListing li .SuggestedProduct a {
    padding-bottom: 5px;
}

.Home .PSPlacementHorizontal .ProductSuggestionsListing li .SuggestedProduct a .PSProdBrand {
    font-weight: var(--fw-bold);
}

#li_placement .PSPlacementVertical .ProductSuggestionsListing li {
    display: block;
    padding: 5px;
    text-align: center;
    width: 100%;
}

#li_placement .ProductSuggestionsListing > li > div > a:hover {
    text-decoration: underline;
}

.Home .PSPlacementHorizontal .ProductSuggestionsListing li .SuggestedProduct > a {
    display: inline-block;
    width: 100%;
}

#li_placement .PSPlacementVertical .SuggestedProduct > a {
    display: none;
}

.Home .PSPlacementHorizontal .PSSellPrice {
    font-size: 1.8em;
    font-weight: var(--fw-bold);
    line-height: 1.2em;
    color: #000;
}

.Home .PSPlacementHorizontal .PSSellPrice.productHasRef {
    color: #ee3123;
}

.Home .PSPlacementHorizontal .PSProdPrice {
    padding: 0 5px;
}

.Home .PSPlacementHorizontal .PSProdPrice .PSRefPrice {
    font-size: 1.2em;
    text-decoration: line-through;
}

#li_placement .BlockDiv {
    display: block;
    clear: both;
}

#li_placement .productimage.s-productthumbimage {
    position: relative;
}

#li_placement .PSPlacementVertical .productimage {
    padding: 0 25%;
    margin-bottom: 5px;
}

#li_placement .QuickBuyButtonContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 42px;
}

.SuggestedProduct > h4 a:hover div {
    text-decoration: underline;
}

.Copyright {
    max-width: 1440px;
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}

.footerPaymentLineBorder {
    padding: 15px 5px;
    width: 100%;
}

@media (min-width: 768px) {
    .Copyright {
        flex-direction: row;
        justify-content: space-between;
        padding: 15px 20px;
    }

    .footerPaymentLineBorder {
        padding: 0;
        text-align: right;
        width: auto;
        flex: 1 1 auto;
    }
}

.Copyright .footerPaymentLineBorder img {
    height: 40px;
}

.Copyright span {
    color: #888;
    font-size: 12px;
    margin: 0;
}

.appLinks,
.appLinks div {
    background-color: #1c1c1c;
    text-align: center;
}

.appLinks .linkContainer {
    display: flex;
    border-bottom: 0.5px solid #414141;
}

.appLinks .appText {
    color: #fff;
    font-size: 16px;
    background-color: #1c1c1c;
    padding: 28.5px 43px 0;
    text-align: center;
}

.appLinks .copyrightText {
    font-size: 12px;
    color: #7c7c7c;
    margin: 0;
}

.appLinks a {
    display: flex;
}

.appLinks .mobMenuNewsletter .linkContainer {
    border-bottom: 0.5px solid #414141;
    background-color: #1c1c1c;
}

.appLinks::after {
    content: "";
    border: 0;
    border-top: 4px solid #0000ed;
    border-image-source: linear-gradient(to left, #ee3424 50%, #0000ed 50%);
    border-image-slice: 1;
    left: 0;
    position: absolute;
    height: 1px;
    width: 100%;
}

.mobMenuNewsletter .linkContainer {
    display: flex;
}

.downloadContainer {
    padding-bottom: 10px;
}

.storeLinks {
    display: flex;
}

.gameLink a,
.uscLink a,
.appImageApple a,
.appImageGoogle a {
    padding: 20px 5px !important;
}

.appImageApple a {
    justify-content: flex-end;
}

.appImageApple,
.appImageGoogle {
    width: 50%;
    padding: 0 5px;
}

.appImageApple .appleImage,
.appImageGoogle .googleImage {
    height: 36px;
}

.gameLink {
    border-right: 0.5px solid #414141;
    width: 50%;
    flex: 1 1 auto;
}

.uscLink {
    width: 50%;
    flex: 1 1 auto;
}

.gameLink .gameLogo,
.uscLink .uscLogo {
    height: 22px;
    align-self: center;
    margin: 0 auto;
}

.copyrightContainer {
    padding: 15px 0;
    border-top: 0.5px solid #414141;
}

@media (max-width: 320px) {
    .appImageApple span,
    .appImageGoogle span {
        background-size: 750px 900px;
    }

    .appImageApple span {
        background-position: 5px -469px;
        max-width: 145px;
    }

    .appImageGoogle span {
        background-position: -145px -469px;
        max-width: 145px;
    }
}

@media (min-width: 768px) {
    .mp-container .mp-menu {
        display: none;
    }

    .Home .PSPlacementHorizontal .ProductSuggestionsListing {
        flex-flow: row wrap;
        max-width: 1440px;
    }

    .Home .PSPlacementHorizontal .ProductSuggestionsListing li {
        padding: 20px 30px;
    }

    .Home .PSPlacementHorizontal .productimage .PSImage .PSPimg-res {
        width: 70%;
        padding-bottom: 15px;
    }
}

@media (min-width: 768px) and (max-width: 1021px) {
    .Home .PSPlacementHorizontal .ProductSuggestionsListing li {
        width: 33.333333% !important;
        max-width: none;
    }

    .Home .PSPlacementHorizontal .ProductSuggestionsListing li:nth-child(6),
    .Home .PSPlacementHorizontal .ProductSuggestionsListing li:nth-child(7) {
        display: none;
    }
}

@media (max-width: 767px) {
    .Home .PSPlacementHorizontal #li_swiper.ProductSuggestionsListing .hotspotbuy,
    .li-pr-basket .PSPlacementHorizontal .ProductSuggestionsListing .hotspotbuy {
        display: none !important;
    }

    .Home #li_placement .productimage.s-productthumbimage {
        padding: 0 50px;
    }
}

#lblCategoryCopy .textLeft p #catCopyMobileReadMoreLess,
.topheadbox .textLeft p #catCopyMobileReadMoreLess {
    cursor: pointer;
    font-weight: var(--fw-bold);
}

.Browse .searchWrapResults {
    font-size: 1.2em;
}

#lblCategoryCopy .catTemplate .textLeft {
    padding: 0 20px;
    font-size: 1em;
}

#lblCategoryCopy .catTemplate .subLink {
    display: block;
    text-decoration: underline;
    margin: 0 0 10px;
    letter-spacing: 0.1em;
    color: #636363;
}

#lblCategoryCopy .catTemplate .siblingMore {
    margin-top: 10px;
    font-weight: var(--fw-bold);
    display: block;
    letter-spacing: 0.1em;
}

#lblCategoryCopy .catTemplate .textLeft p {
    display: block !important;
    font-size: 1em;
}

#lblCategoryCopy .catTemplate .subLink,
#lblCategoryCopy .catTemplate .siblingMore {
    font-size: 1em;
}

@media (max-width: 1021px) {
    #lblCategoryCopy .catTemplate .textLeft {
        margin: 0;
        width: 100%;
    }

    #lblCategoryCopy .catTemplate .imgRight {
        display: none;
    }

    #lblCategoryCopy .catTemplate .siblingMore {
        display: block !important;
    }

    .sdlProdList #lblCategoryCopy,
    .sdlProdList .topheadbox {
        border: 0;
    }

    .Browse .categorycopyd4 #catHeader {
        position: static;
        width: auto;
        top: auto;
        left: auto;
    }
}

@media (max-width: 767px) {
    .sdlProdList.Browse #lblCategoryCopy,
    .sdlProdList.Browse .topheadbox {
        display: flex;
        flex-wrap: wrap;
    }

    #lblCategoryCopy .catTemplate .textLeft {
        padding: 0 10px;
    }

    #lblCategoryCopy .catTemplate .siblingMore {
        margin-top: 10px;
    }
}

/* MOBILE MENU MOVE IT */
.mp-container .mp-menu .mp-level #homeMenu li > a.mp-close,
.mp-container .mp-menu .mp-level ul > li a.mp-close {
    display: none;
}

.mp-container .mp-menu .mp-level {
    max-height: 100%;
    z-index: 2;
}

.menulevelheader {
    font-size: 1.3em;
    text-shadow: none;
    padding: 0 10px 0 58px;
    font-weight: var(--fw-normal);
}

.mobMenuGroup li .mp-level .menulevelheader a {
    font-size: 20px;
    text-decoration: none;
    padding: 15px 5px;
    display: inline-block;
    color: #000;
}

.mobMenuGroup li .mp-level .mp-back .mp-back-text {
    display: none;
}

.shop > ul > li a.mp-back,
.mobMenuGroup li .mp-back {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 !important;
    line-height: 58px;
    height: 58px;
    text-align: center;
    width: 58px;
    box-shadow: none;
    overflow: hidden;
    font-size: 1em;
}

.shop > ul > li a.mp-back .mp-back-text,
.mobMenuGroup #mob-myaccount .mp-back .mp-back-text {
    display: none;
}

.mobMenuGroup li .mp-back::before,
.shop > ul > li a.mp-back::before,
#liMobileLanguageSelector a.mp-back::before,
#liMobileCurrencySelector a.mp-back::before {
    content: "";
    position: static;
    display: inline-block;
    width: 20px;
    height: 20px;
    font-family: inherit;
    top: auto;
    vertical-align: middle;
    right: auto;
    font-size: 12px;
    background-size: 750px 750px;
    background-position: -267px -360px;
}

.MobileMenuContentWrap .shop > ul > li .menulevelheader > a,
.mobMenuGroup #mob-myaccount .menulevelheader a {
    font-size: 18px;
    text-decoration: none;
    padding: 18px 0 !important;
    display: inline-block !important;
    color: #000 !important;
}

#homeMenu > li > a {
    font-size: 1.6em;
    padding: 13px 25px;
    display: none;
}

.mp-container .mp-menu .mmHasChild .mp-level.show-level .mobMenGroup {
    background-color: #fff;
}

.FooterSubGroup,
.mp-container .mp-menu .mmHasChild .mp-level.show-level .mobMenGroup {
    display: block;
}

#mp-menu .mmHasChild.flexReorder > .mp-level > .mobMenGroup {
    display: flex;
    flex-wrap: wrap;
}

#mp-menu .mmHasChild.flexReorder > .mp-level > .mobMenGroup > li {
    width: 100%;
    order: 1;
}

#mp-menu .mmHasChild.flexReorder > .mp-level > .mobMenGroup > li.leftHeader {
    order: 2;
    margin-top: 0;
}

#mp-menu .mmHasChild.flexReorder > .mp-level > .mobMenGroup > li.leftHeader.altHeader {
    margin-top: 20px;
}

#mp-menu .mmHasChild.flexReorder > .mp-level > .mobMenGroup > li.mobMenToplink {
    width: auto;
}

.mobMenuGroup #mob-myaccount .menulevelheader a {
    color: #fff;
}

.headerStripes {
    display: none;
}

/* USCxSD */
.uscSite .TopNavBar {
    box-shadow: 0 1px 1px #16abd2;
}

.uscSite nav#topMenu ul li.root > a:hover,
.uscSite nav#topMenu ul li.root.sdHover > a,
.uscSite nav#topMenu li.root .SubMenuWrapper > ul li a:hover {
    color: #16abd2;
}

/*SD-USC menu Styling*/
.uscSite nav#topMenu ul li.root .SubMenuWrapper ul {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}

.uscSite nav#topMenu ul li.root .SubMenuWrapper ul li.Center > ul > li {
    width: 25%;
}

.uscSite nav#topMenu ul li.root .SubMenuWrapper .KidsCenter.Center > ul li.level1.sdmColHeader {
    width: 50%;
}

.uscSite nav#topMenu ul li.root .SubMenuWrapper > ul .KidsCenter.Center > ul {
    display: flex;
}

.uscSite nav#topMenu ul li.root .SubMenuWrapper .KidsCenter ul {
    display: block;
}

.uscSite nav#topMenu .KidsCenter > ul li.columnGroup {
    width: 50%;
    padding: 0 10px;
}

.uscSite nav#topMenu ul li.root .SubMenuWrapper > ul .KidsCenter li.level1.sdmColHeader > a {
    padding: 5px 15px;
}

.uscSite nav#topMenu .KidsCenter li.columnGroup > ul {
    padding: 0;
}

.uscSite nav#topMenu ul li.root .SubMenuWrapper > ul .KidsCenter li.level1.sdmColHeader > a::before {
    left: 15px;
    width: calc(100% - 30px);
}

@media (max-width: 767px) {
    .uscSite .headerStripes {
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        height: 100%;
        background-color: #23282b;
        display: block;
    }

    .uscSite .headerStripes::after {
        background: linear-gradient(125deg, #16abd2 15%, transparent 85%);
        content: "";
        height: 100%;
        width: 100%;
        display: block;
    }

    .uscSite .shop > ul > li#liAllDepts a,
    .uscSite .MenuCloseActive {
        background-color: #16abd2 !important;
    }
}

#mp-menu .mp-level {
    background-color: #fff !important;
}

#mp-menu > .mp-level {
    height: 100%;
}

#homeMenu,
.menulevelheader {
    background-color: #fff;
    text-align: left;
}

#mp-menu .mp-level .menuitemtext {
    color: #000;
    font-size: 16px;
    height: 100%;
}

#mp-menu .mp-level .menuitemtext > img {
    background-color: #f1f1f1;
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 10px;
    display: inline-block;
    overflow: hidden;
}

.mp-menu > .mp-level a.menuitemtext,
.mp-menu > .mp-level #divCurrencyLanguageMobile a,
.mp-menu > .mp-level #divCurrencyLanguageMobile .currencySelectorMobile > li > .currencyOption {
    margin-left: 25px;
    padding-left: 0 !important;
    width: calc(100% - 25px);
}

.mp-menu ul li > a,
.currencySelectorMobile li > .currencyOption,
.mp-menu .mobile-menu-footer-content ul li.has-dropdown:last-of-type > a {
    box-shadow: inset 0 -1px rgba(208, 207, 205, 0.8);
}

.mp-menu ul li:last-of-type > a,
.mp-menu .shop ul li.MenuGroupOutlet > a,
.currencySelectorMobile li:last-of-type > .currencyOption,
.lanugageSelectorMobile li a:last-of-type,
.mp-menu .mobile-menu-footer-content > ul > li.has-dropdown > a.open,
.mp-menu .mobile-menu-footer-content #divCurrencyLanguageMobile ul > li.has-dropdown > a.open {
    box-shadow: none;
}

#mp-menu .mp-level .shopbySize > a::before,
#mp-menu .mp-level .shopbySize > .mp-level > p > a::before {
    content: "Shop by Size - ";
}

#mp-menu .mp-level .sdmColGap,
#mp-menu .mp-level .sdmColMidHeader {
    margin-top: 20px;
}

#mp-menu .mp-level .MenuGroupOutlet > .mp-level > ul > .sdmColGap {
    margin-top: 0;
}

#mp-menu .mp-level .MenuGroupOutlet > .mp-level > ul {
    display: flex;
    flex-direction: column;
}

#mp-menu .mp-level .MenuGroupOutlet > .mp-level > ul > li {
    order: 5;
}

#mp-menu .mp-level .MenuGroupOutlet > .mp-level > ul .mens-sale-menu {
    order: 1;
}

#mp-menu .mp-level .MenuGroupOutlet > .mp-level > ul .womens-sale-menu {
    order: 2;
}

#mp-menu .mp-level .MenuGroupOutlet > .mp-level > ul .kids-sale-menu {
    order: 3;
}

#mp-menu .mp-level .MenuGroupOutlet > .mp-level > ul .size-sale-menu {
    order: 4;
}

#mp-menu .mp-level .MenuGroupOutlet > .mp-level > ul .size-sale-menu > a::before,
#mp-menu .mp-level .MenuGroupOutlet > .mp-level > ul .size-sale-menu p.menulevelheader > a::before {
    content: "Sale by ";
}

nav#topMenu ul li.MenuGroupOutlet .SubMenuWrapper > ul li.sdmColHeader.size-sale-menu > a::before {
    content: "Sale by ";
    display: inline;
    position: static;
    bottom: auto;
    left: auto;
    width: auto;
    transition: none;
}

.mobImg::before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 45%;
    height: 100%;
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
    font-size: 12px;
}

@media (min-width: 768px) {
    .mobImg {
        display: none;
    }
}

.adidasBrand .mobImg::before {
    background-image: url("/images/marketing/sd-lv8-logo-adidas.svg");
}

.asicsBrand .mobImg::before {
    background-image: url("/images/marketing/sd-lv8-asics-white.svg");
}

.KarrimorBrand .mobImg::before {
    background-image: url("/images/marketing/sd-lv8-logo-karrimor.svg");
    width: 35%;
}

.NBBrand .mobImg::before {
    background-image: url("/images/marketing/sd-lv8-logo-nb.svg");
}

.nikeBrand .mobImg::before {
    background-image: url("/images/marketing/sd-lv8-logo-nike.svg");
}

.SlazengerBrand .mobImg::before {
    background-image: url("/images/marketing/sd-lv8-logo-slazenger.svg");
}

.UABrand .mobImg::before {
    background-image: url("/images/marketing/sd-lv8-logo-ua.svg");
}

.usapBrand .mobImg::before {
    background-image: url("/images/marketing/sd-lv8-logo-usapro.svg");
    width: 55%;
}

/*Reviews*/
#productDetails .rating-container {
    margin-bottom: 0 !important;
    min-height: 19px;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container {
    margin: 0 !important;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container .bv_avgRating_component_container,
.reviews-container [data-bv-show="inline_rating"] .bv_main_container .bv_averageRating_component_container {
    display: none !important;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container .bv_numReviews_component_container {
    padding-top: 1px !important;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container .bv_main_container_row_flex {
    padding-right: 8px !important;
}

.rating-container [data-bv-show="rating_summary"] .bv_main_container .bv_stars_component_container {
    padding-right: 5px !important;
}

.ProdDetails div.bv_modal_component_container[data-bv-modal] {
    z-index: 6000 !important;
}

.reviews-container [data-bv-show="inline_rating"] > div {
    display: block !important;
}

.reviews-container [data-bv-show="reviews"],
.reviews-container [data-bv-show="questions"] {
    padding: 20px 0 !important;
}

.bv_modal_component_container .bv_histogram_row_container {
    height: auto !important;
    background-color: #f7f7f7 !important;
    padding: 8px !important;
    display: block !important;
}

.bv_modal_component_container .bv_histogram_row_container:hover {
    background-color: #efefef !important;
}

.bv_modal_component_container .bv_button_component_container {
    padding-top: 15px !important;
}

.bv_modal_component_container .bv_histogram_row_star {
    padding-left: 3px !important;
}

.bv_modal_component_container .bv_button_component_container button.bv_button_buttonFull {
    width: 100% !important;
    padding: 15px !important;
    transition: background-color 0.3s ease-in;
}

#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-mbox-wide .bv-mbox {
    background-color: #f0f0f0 !important;
}

#bv-mboxzone-lightbox.bv-cv2-cleanslate
    .bv-mbox-fullprofile
    .bv-sidebar
    .bv-author-profile-userinfo
    .bv-author-userstats-list
    .bv-author-userstats-value {
    background-color: rgba(25, 25, 25, 0.1) !important;
}

.reviews-container
    .bv-cv2-cleanslate
    .bv-content-pagination
    .bv-content-pagination-container
    .bv-content-btn-pages-load-more {
    background-color: #0000ed !important;
    color: #fff !important;
    padding: 10px !important;
    border: 0 !important;
    width: auto !important;
    min-width: 200px !important;
}

.reviews-container
    .bv-cv2-cleanslate
    .bv-content-pagination
    .bv-content-pagination-container
    .bv-content-btn-pages-load-more:hover {
    background-color: #0000da !important;
    color: #fff !important;
}

.reviews-container
    .bv-cv2-cleanslate
    .bv-content-pagination
    .bv-content-pagination-container
    .bv-content-btn-pages-load-more
    .bv-content-btn-pages-load-more-text {
    cursor: pointer !important;
    position: relative !important;
}

.reviews-container .bv-cv2-cleanslate .bv-content-header .bv-content-title-container {
    padding-top: 10px !important;
}

.reviews-container .bv-cv2-cleanslate .bv-header .bv-section-summary .bv-content-title {
    padding-top: 25px !important;
}

.reviews-container .bv-cv2-cleanslate {
    margin: 20px 0;
}

@media (max-width: 767px) {
    .reviews-container [data-bv-show="reviews"],
    .reviews-container [data-bv-show="questions"] {
        padding: 20px 15px !important;
    }

    .reviews-container .bv-cv2-cleanslate {
        margin: 20px 10px;
    }

    #productDetails .rating-container {
        padding: 0 15px;
    }
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-placeholder .bv-rating-stars-container.bv-rating-none {
    margin: 10px 5px 10px 10px !important;
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-placeholder {
    padding-bottom: 30px !important;
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-noreviews-label,
.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-placeholder .bv-write-review-label {
    vertical-align: middle !important;
    line-height: 20px !important;
    margin: 10px 5px 10px 10px !important;
    display: inline-block !important;
}

.reviews-container [data-bv-rating] .bv_main_container .bv_text,
.reviews-container [data-bv-show="inline_rating"] .bv_main_container .bv_text {
    line-height: 1.2em !important;
    font-size: 1.2em !important;
    padding: 0 !important;
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-slider-sublabel1,
.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-slider-sublabel2 {
    display: inline-block !important;
    float: none !important;
}

.reviews-container [data-bv-show="rating_summary"] .bv_main_container .bv_numReviews_text {
    line-height: 15px !important;
}

.reviews-container .bv-cv2-cleanslate .bv-compat .bv-content-placeholder .bv-write-review-label {
    display: none !important;
}

/* Category Copy image Template */
.sdlProdList .catTemplate {
    max-width: 1200px;
    margin: 0 auto;
}

.sdlProdList .catTemplate.ImgCatVersion {
    max-width: none;
}

.sdlProdList .catTemplate img {
    width: 100%;
    margin: 0;
}

.sdlProdList .catTemplate .catImg {
    padding: 5px 16px 10px;
}

.sdlProdList .catTemplate .topSection {
    padding: 0;
}

.sdlProdList .catTemplate .bottomSection {
    margin: 30px 0 10px;
    padding: 0;
    display: flex;
}

.sdlProdList .catTemplate .bottomSection div {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
}

.sdlProdList .catswiperSection {
    position: relative;
    padding-top: 15px;
}

.sdlProdList .catswiperSection .swiper-wrapper {
    align-items: center;
}

.sdlProdList .catTemplate .textDesc p {
    font-size: 1.1em;
    margin: 0;
    color: #333;
}

.sdlProdList .catTemplate .textDesc #catCopyMobileReadMoreLess {
    cursor: pointer;
}

.sdlProdList .catTemplate .siblingLinks {
    clear: left;
    margin: 10px 0;
}

.sdlProdList .catTemplate .postWrap {
    display: block;
}

.sdlProdList .catTemplate .viewMore {
    text-align: right;
    padding: 0 20px;
}

.sdlProdList .catTemplate .viewMore a span {
    font-size: 1.2em;
    display: inline-block;
    vertical-align: top;
}

.sdlProdList .catTemplate .viewMore .collapsed span.OpenView,
.sdlProdList .catTemplate .viewMore span.ClosedView {
    display: none;
}

.sdlProdList .catTemplate .viewMore .collapsed span.ClosedView,
.sdlProdList .catTemplate .viewMore a,
.sdlProdList .catTemplate .viewMore span.OpenView {
    display: inline-block;
}

.sdlProdList .catTemplate .viewMore span.OpenView::after,
.sdlProdList .catTemplate .viewMore span.ClosedView::after {
    content: "";
    height: 20px;
    width: 20px;
    margin-left: 5px;
    vertical-align: top;
    display: inline-block;
    background-size: 750px 750px;
    background-position: -170px -389px;
}

.sdlProdList .catTemplate .viewMore span.OpenView::after {
    background-position: -170px -417px;
}

.sdlProdList .catSwiper .swiper-slide {
    width: calc(40% - 8px);
    margin-right: 10px;
}

.sdlProdList .catSwiper .categoryContainer .swiper-slide a {
    padding: 11px 5px;
    width: 100%;
    border: 1px solid #ccc;
    color: #333;
    border-radius: 5px;
    background-color: #f7f7f7;
    letter-spacing: 0.03em;
    text-align: center;
    transition: 0.5s;
    font-size: 1em;
}

.sdlProdList .catSwiper .categoryContainer .swiper-slide a:hover {
    background-color: #c7c6c6;
    color: #000;
}

@media (min-width: 768px) {
    .catTemplate .collapse {
        display: block !important;
        height: auto !important;
    }

    .sdlProdList .catSwiper .innerContain {
        padding: 0 50px;
        position: relative;
    }

    .sdlProdList .catSwiper .swiper-button-nextCat,
    .catSwiper .swiper-button-prevCat {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        width: 45px;
        height: 45px;
        z-index: 10;
        cursor: pointer;
        line-height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sdlProdList .catSwiper .swiper-button-prevCat {
        left: 0;
    }

    .sdlProdList .catSwiper .swiper-button-nextCat {
        right: 0;
    }

    .sdlProdList .catSwiper .swiper-button-nextCat::before,
    .sdlProdList .catSwiper .swiper-button-prevCat::before {
        content: "";
        height: 18px;
        width: 15px;
        background-image: url("/images/marketing/arrows-blk.png");
        background-position: 33% 100%;
        background-size: 70%;
        display: inline-block;
        vertical-align: middle;
        background-repeat: no-repeat;
    }

    .sdlProdList .catSwiper .swiper-button-prevCat::before {
        background-position: 50% 0;
    }
}

@media (min-width: 768px) and (max-width: 1021px) {
    .sdlProdList .catSwiper .swiper-slide {
        width: calc(33.333333% - 8px);
    }
}

@media (min-width: 1022px) {
    .sdlProdList .catTemplate .postWrap {
        position: relative;
    }

    .sdlProdList .catTemplate .postWrap::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        right: 0;
        z-index: 1;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0);
    }

    .sdlProdList .catTemplate .postWrap:hover::before {
        background-color: rgba(0, 0, 0, 0.3);
    }

    .sdlProdList .catTemplate.ImgCatVersion .catDesc,
    .sdlProdList .catTemplate.ImgCatVersion .siblingLinks,
    .sdlProdList .catTemplate .bottomSection .leftside {
        padding-right: 15px;
    }

    .sdlProdList .catTemplate .bottomSection .rightside {
        padding-left: 15px;
    }

    .sdlProdList .catTemplate .topSection {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .sdlProdList .catTemplate .bottomSection::after,
    .sdlProdList .catTemplate .bottomSection::before {
        content: normal;
    }

    .sdlProdList .catTemplate div#ViewMoreCopy {
        order: 1;
        margin-top: 0 !important;
    }

    .sdlProdList .catTemplate .catImg {
        order: 2;
        padding-left: 0;
        padding-right: 0;
    }

    .sdlProdList .catSwiper .swiper-slide {
        width: calc(16.666667% - 8px);
    }
}

@media (min-width: 1022px) and (max-width: 1152px) {
    .sdlProdList .catTemplate .siblingLinks,
    .sdlProdList .catTemplate.ImgCatVersion .siblingLinks {
        width: 100%;
    }

    .sdlProdList .catTemplate.ImgCatVersion .siblingLinks {
        margin-bottom: 10px;
    }

    .sdlProdList .catTemplate .bottomSection {
        margin-top: 0;
    }
}

@media (max-width: 1021px) {
    .sdlProdList .catTemplate.ImgCatVersion .topSection {
        display: flex;
        flex-direction: column;
    }

    .sdlProdList .catTemplate.ImgCatVersion .catDesc {
        order: 1;
    }

    .sdlProdList .catTemplate.ImgCatVersion .siblingLinks {
        margin-bottom: 10px;
        order: 2;
    }

    .sdlProdList .catTemplate.ImgCatVersion .catImg {
        order: 3;
        margin-bottom: 5px;
    }

    .sdlProdList .catTemplate .siblingLinks {
        padding: 0;
    }
}

@media (max-width: 767px) {
    .sdlProdList .swiper-container.categoryContainer {
        overflow: visible;
    }
}

/* End Category Template */

/* GAME AREA START */
.gameCategory {
    position: relative;
    margin-top: 10px;
    margin-bottom: 20px;
}

.gameArea #MenuOpenContentCover.activeOverlay {
    opacity: 0;
}

.gameArea nav#topMenu ul li.root.sdHover::before {
    z-index: 3;
}

.gameCategory img {
    margin: 0;
}

.gameCategory .gameLogo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    max-width: 280px;
    transform: translate(-50%, -50%);
}

.gameArea #lblCategoryCopy .textLeft .siblingLinks::-webkit-scrollbar-thumb,
.gameArea .topheadbox .textLeft .siblingLinks::-webkit-scrollbar-thumb,
.gameArea #mobFilterControls li.mobApplyFilter .textIconWrap,
.gameArea .productFilter .productFilterList::-webkit-scrollbar-thumb,
.gameArea #PriceFilterTextEntryApply,
.gameArea .showFilterButtonWrapper .showFilterButton,
.gameArea .piActiveDot {
    background-color: #000 !important;
}

.gameArea #mobFilterControls li.mobApplyFilter .textIconWrap:hover,
.gameArea #PriceFilterTextEntryApply:hover,
.gameArea .showFilterButtonWrapper .showFilterButton:hover,
.gameArea .piActiveDot:hover {
    background-color: #bd1a8d !important;
}

.gameArea nav#topMenu ul.RootGroup li.root.backtoSD {
    flex-grow: 0;
}

.gameArea nav#topMenu ul.RootGroup li.root.backtoSD > a {
    border-left: 1px solid #ddd;
    text-transform: none;
    padding: 0 15px;
    font-weight: var(--fw-normal);
    letter-spacing: 1px;
}

@media (min-width: 768px) {
    .gameArea .LogoWrap img {
        max-width: 164px;
    }
}

/* PLP GAME */
.gameArea .hotspotbuy .WishIcon,
.gameArea .hotspotbuy .QuickLookIcon {
    opacity: 0.5;
}

.gameArea .hotspotbuy .WishIcon:hover,
.gameArea .hotspotbuy .QuickLookIcon:hover {
    opacity: 1;
}

.gameArea .hotspotbuy .WishIcon:hover {
    background-position: -19px -591px;
}

.gameArea .hotspotbuy .QuickLookIcon:hover {
    background-position: -20px -533px;
}

.gameSwitch .s-productthumbbox .s-producttext-top-wrapper a {
    display: flex;
    flex-direction: column;
}

.gameSwitch .s-productthumbbox .s-producttext-top-wrapper a .brandWrapTitle {
    order: 2;
}

.gameSwitch .s-productthumbbox .s-producttext-top-wrapper a .brandWrapTitle span {
    padding: 5px 0 0;
    font-weight: var(--fw-normal);
    letter-spacing: 0;
    text-transform: none;
    font-size: 1.2em;
    line-height: 1.3em;
}

.gameSwitch .s-productthumbbox .s-producttext-top-wrapper a .nameWrapTitle {
    order: 1;
}

.gameSwitch .s-productthumbbox .s-producttext-top-wrapper a .nameWrapTitle span {
    font-weight: var(--fw-bold);
    letter-spacing: 0.05em;
    font-size: 1.4em;
    line-height: 1.2em;
}

.gameSwitch .s-productthumbbox .s-producttext-top-wrapper {
    font-size: 1em;
    line-height: 1em;
}

.gameSwitch .s-productthumb-extra {
    display: none;
}

.gameSwitch .s-productthumbbox .s-producttext-top-wrapper a .brandWrapTitle .productdescriptionbrand::before {
    content: "Publisher: ";
}

/* PDP GAME */
.gameSwitch .AltProdDet .title h1 {
    display: flex;
    flex-direction: column;
    font-size: 1em;
    line-height: 1.7em;
}

.gameSwitch .AltProdDet .title h1 .brandTitle {
    order: 2;
    text-transform: none;
}

.gameSwitch .AltProdDet .title h1 .brandTitle a {
    font-weight: var(--fw-normal);
    letter-spacing: 0.05em;
    font-size: 1.3em;
}

.gameSwitch .AltProdDet .title h1 .prodTitle {
    order: 1;
    font-size: 1.5em;
    font-weight: var(--fw-bold);
}

.gameSwitch .AltProdDet .title h1 #lblProductBrand::before {
    content: "Publisher: ";
}

.gameSwitch .colourChooser .s-productextras-column-1::before,
.gameSwitch .swapSize .s-productextras-column-1::before {
    content: "Format: ";
    font-weight: var(--fw-bold);
    font-size: 1.2em;
    line-height: 1.2em;
    letter-spacing: 0.05em;
    display: inline-block;
    cursor: text;
}

.gameSwitch .colourChooser .s-productextras-column-1,
.gameSwitch .swapSize .s-productextras-column-1 {
    position: relative;
}

.gameSwitch .swapSize .s-productextras-column-1::before {
    content: "Edition: ";
}

.gameSwitch .s-productextras-column-1 #BuyColourText::after,
.gameSwitch .s-productextras-column-1 #BuySizeText::after {
    content: none;
}

.gameSwitch .colourChooser .s-productextras-column-1 #BuyColourText,
.gameSwitch .swapSize .s-productextras-column-1 .BuySizeText {
    left: 0;
    top: 0;
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.gameSwitch #divPopupSizeGuide {
    display: none;
}

.gameArea .colourImages li a:hover,
.gameArea .colourImages li.greyOut a:hover,
.gameArea .sizeButtons li a:hover,
.gameArea .sizeButtons li.greyOut a:hover,
.gameArea .s-basket-quantity-text-box input:focus,
.gameArea .s-basket-quantity-text-box input:hover,
.gameArea.sdDetails .sizeButtons li.sizeVariantHighlight a,
.gameArea.sdDetails .colourImages li.variantHighlight a {
    border-color: #000;
}

.gameArea .WishListContain .sAddToWishListWrapper a:hover > .pdpWishListIco {
    background-position: -18px -491px;
}

.gameArea .mobile_zoom_button:hover::before {
    background-position: -228px -193px;
}

.gameArea .zoomButton:hover .glyphicon-fullscreen {
    background-position: -174px -227px;
}

/* GAME AREA END */

/* Sports Direct Elevation 22 */

.sdlProdList .s-productscontainer2 {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
}

.sdlProdList .s-productscontainer2 > li {
    flex-basis: 50%;
    margin: 0 0 16px;
}

.sdlProdList .s-productscontainer2 > li:nth-child(n + 9) {
    content-visibility: auto;
    contain-intrinsic-size: auto 350px;
}

.sdlProdList.has-member-pricing-product-on-plp {
    .plp-advert-placement {
        order: 1 !important;
    }

    &.has-1-sponsored-products,
    &.has-3-sponsored-products {
        .plp-advert-placement {
            order: 0 !important;
        }
    }

    &.has-2-sponsored-products,
    &.has-4-sponsored-products {
        .plp-advert-placement {
            order: 1 !important;
        }
    }
}

@media (min-width: 768px) {
    .sdlProdList .s-productscontainer2 > li,
    .sdlProdList .columns3 .s-productscontainer2 > li,
    .sdlProdList .columns4 .s-productscontainer2 > li {
        width: auto;
        max-width: 33.333333%;
        flex-basis: 33.333333%;
    }

    .sdlProdList.has-member-pricing-product-on-plp {
        .plp-advert-placement {
            order: 2 !important;
        }

        &.has-1-sponsored-products,
        &.has-4-sponsored-products {
            .plp-advert-placement {
                order: 1 !important;
            }
        }

        &.has-2-sponsored-products {
            .plp-advert-placement {
                order: 0 !important;
            }
        }

        &.has-3-sponsored-products {
            .plp-advert-placement {
                order: 2 !important;
            }
        }
    }
}

@media (min-width: 1440px) {
    .sdlProdList .s-productscontainer2 > li,
    .sdlProdList .columns3 .s-productscontainer2 > li,
    .sdlProdList .columns4 .s-productscontainer2 > li {
        width: auto;
        max-width: 25%;
        flex-basis: 25%;
    }

    .sdlProdList.has-member-pricing-product-on-plp {
        .plp-advert-placement {
            order: 3 !important;
        }

        &.has-1-sponsored-products {
            .plp-advert-placement {
                order: 2 !important;
            }
        }

        &.has-2-sponsored-products {
            .plp-advert-placement {
                order: 1 !important;
            }
        }

        &.has-3-sponsored-products {
            .plp-advert-placement {
                order: 0 !important;
            }
        }

        &.has-4-sponsored-products {
            .plp-advert-placement {
                order: 3 !important;
            }
        }
    }
}

.sdlProdList .s-productthumbbox {
    padding: 0;
    border: none;
}

.sdlProdList .productimage {
    float: none;
    border-radius: 2px;
    border: none;
    padding: 0;
    overflow: hidden;
}

.sdlProdList .productImage::before {
    /* Keeps the prod image square - was going to use aspect ratio but it isnt widely supported yet*/
    content: "";
    display: block;
    padding-top: 100%;
}

.sdlProdList .TextSizeWrap {
    text-align: left;
    min-height: 111px;
    padding: 12px 0 24px;
}

.AltProdList .s-productthumbbox h4,
.sdlProdList .columnsmobile2 .TextSizeWrap {
    text-align: left;
    padding: 0;
}

.sdlProdList .s-productthumbbox .s-producttext-top-wrapper,
.ProdDetails .ShopTheLook .s-productthumbbox .s-producttext-top-wrapper {
    padding: 0;
    margin: 12px 0 3px !important;
}

.sdlProdList .s-productthumbbox .s-producttext-top-wrapper a span {
    padding-bottom: 1px;
}

.sdlProdList .productdescriptionname,
.ProdDetails .ShopTheLook .productdescriptionname {
    font-size: 15px;
    line-height: 21px;
    font-weight: var(--fw-normal);
    color: #131313;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    padding: 0;
}

.sdlProdList .productdescriptionbrand,
.ProdDetails .ShopTheLook .productdescriptionbrand {
    font-size: 14px;
    line-height: 16px;
    font-weight: var(--fw-normal);
    text-transform: none;
    padding: 0;
    color: #5b5d5e;
}

.s-productthumbbox .s-producttext-price,
.ProdDetails .ShopTheLook .s-producttext-price {
    padding: 0 0 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #131313;
}

.s-productthumbbox .s-producttext-price .RefandPrice,
.sdlProdList .s-producttext-price .s-largered,
.ProdDetails .ShopTheLook .s-producttext-price .s-largered {
    padding: 0 4px 0 0;
    display: flex;
    align-items: center;
}

.sdlProdList .s-productthumbtext {
    padding-top: 12px;
}

.s-producttext-withticket .AdditonalPriceLabel,
.sdlProdList .Responsive .RefandPrice span,
.ProdDetails .ShopTheLook .RefandPrice span {
    font-size: 12px;
    vertical-align: initial;
    color: #5b5d5e;
}

.sdlProdList .s-largered .curprice,
.ProdDetails .ShopTheLook .s-largered .curprice {
    font-size: 15px;
    font-weight: var(--fw-bold);
    line-height: 20px;
    padding: 2px 0;
    color: inherit;
}

.sdlProdList .Responsive .s-largered span.fromProductPrice,
.ProdDetails .ShopTheLook span.fromProductPrice {
    font-size: 12px;
    font-weight: var(--fw-normal);
    padding-right: 4px;
}

#lblSellingPrice.productHasRef,
.s-producttext-withticket .s-largered,
.productPrice .nowprice,
.ProdDetails .ShopTheLook .nowprice {
    color: #ed0000;
}

.sdlProdList .s-largered .curprice,
.sdlProdList .Responsive .s-largered span.fromProductPrice,
.s-producttext-withticket .AdditonalPriceLabel,
.sdlProdList .Responsive .RefandPrice span {
    line-height: 1em;
}

.sdlProdList .s-producttext-top-wrapper,
.sdlProdList .s-productthumb-extra {
    margin-bottom: 4px;
}

.sdlProdList .s-product-sache {
    position: absolute;
    top: 8px;
    left: 0;
    z-index: auto !important;
}

.hotspotbuy.hotspotwishlist {
    display: block !important;
    /* Needs the important to overwrite inline-styles */
}

#productlistcontainer .hotspotbuy.hotspotwishlist {
    top: 4px;
    right: 4px;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0.3;
}

#productlistcontainer .hotspotbuy.hotspotwishlist:hover {
    opacity: 1;
}

#productlistcontainer .hotspotbuy.hotspotwishlist:hover svg path {
    stroke: #000;
}

#productlistcontainer .hotspotbuy.hotspotwishlist .WishIcon {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-position: -61px -374px;
    background-size: 800px 800px;
}

@media (max-width: 1021px) {
    #Body .s-productthumbbox .hotspotquickbuy {
        display: none !important;
    }

    #Body .s-productthumbbox .hotspotwishlist {
        display: block !important;
    }

    #hotspotModal #hsViewProduct {
        position: static;
        display: block;
    }

    #hotspotModal .PinWrapText {
        width: 100%;
        padding: 25px;
    }
}

.sdlProdList .s-productthumbbox .s-productthumbimage .ProductImageList {
    margin-bottom: 0;
    max-width: 100%;
}

.sdlProdList .s-productthumbbox .s-productthumbimage .ProductImageList:focus img {
    transform: scale(1.02);
}

.s-producttext-top-wrapper a:focus {
    text-decoration: underline;
}

.s-productthumbbox,
.sdlProdList .s-productthumbbox:hover {
    border: none;
}

/* Overwriting importants that come with BV styling */
.sdlProdList .reviews-container .bv_numReviews_component_container .bv_text {
    color: #00f;
}

.sdlProdList .reviews-container .bv_numReviews_component_container {
    padding: 5px 5px 6px;
    background: #dde1e4;
    border-radius: 25px;
    text-align: center;
}

.sdlProdList .reviews-container [data-bv-rating] .bv_main_container .bv_text,
.sdlProdList .reviews-container [data-bv-show="inline_rating"] .bv_main_container .bv_text {
    line-height: 1 !important;
    font-size: 12px !important;
    padding: 0;
    font-weight: var(--fw-bold);
}

#productlistcontainer .reviews-container {
    padding: 0;
    margin-top: 7px;
    margin-bottom: 0;
    min-height: 19px;
    width: 100%;
}

.sdlProdList .columnsmobile2 .s-productthumbbox .s-producttext-top-wrapper,
.sdlProdList .columnsmobile2 .s-productthumbbox .s-producttext-price,
.sdlProdList .columnsmobile2 .s-productthumbbox .s-productthumb-extra .s-productsize {
    max-width: 100%;
}

.sdlProdList .bv_main_container {
    display: flex;
    align-items: center;
}

.sdlProdList .bv_stars_button_container {
    padding: 0 5px 0 0;
}

.sdlProdList .bv_stars_svg_no_wrap {
    display: flex;
}

[data-bv-show="inline_rating"] .bv_main_container[aria-label="0.0 out of 5 stars. "],
[data-bv-show="inline_rating"] .bv_main_container[aria-label="0.0 out of 5 stars.  "] {
    display: none !important;
}

.hotspotbuy.hotspotquickbuy .QuickLookIcon {
    display: none;
}

@media (min-width: 1022px) {
    #hotspotModal .modal-body {
        padding: 0 0 52px;
    }
}

#hotspotModal .modal-body {
    padding: 0;
}

#hotspotModal .modal-body .row {
    margin: 0;
}

@media (max-width: 767px) {
    #hotspotModal .modal-dialog {
        margin-top: 0 !important;
        height: 100%;
        border-radius: 0;
    }

    #hotspotModal .modal-dialog .modal-content,
    #hotspotModal .modal-body {
        height: 100%;
        max-height: initial !important;
    }
}

#hotspotModal .modal-dialog {
    max-width: 1030px;
    border-radius: 4px;
    background-color: #f8f8f8;
}

#hotspotModal .modal-dialog .modal-content {
    background-color: #f8f8f8;
    border: none;
    outline: none;
    border-radius: 4px;
}

#hotspotModal .PinWrapImage {
    padding: 16px;
    max-width: 556px;
    width: 100%;
}

#hotspotModal .PinWrapText {
    width: 100%;
    padding: 25px;
}

@media (min-width: 768px) {
    #hotspotModal .PinWrapText {
        padding: 48px 48px 48px 43px;
        display: flex;
        flex-wrap: wrap;
        width: 50%;
        position: static;
    }

    #hotspotModal .PinWrapImage {
        max-width: 50%;
    }
}

@media (min-width: 1022px) {
    #hotspotModal .PinWrapText {
        width: calc(100% - 556px);
    }

    #hotspotModal .PinWrapImage {
        max-width: 556px;
    }
}

.sdlProdList #hotspotModal #hsPriceWrapper {
    padding: 8px 0 0;
    flex-basis: 100%;
    order: 2;
    display: flex;
    flex-wrap: wrap;
}

#hotspotModal .member-price {
    padding: 8px 0 0;
    order: 2;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
}

#hotspotModal .member-price:not(.hide-member-price) + #hsPriceWrapper {
    padding: 0;
}

#hotspotModal .hsProductName {
    flex-grow: 1;
    flex-basis: calc(100% - 90px);
    padding: 0;
    order: 1;
}

#hotspotModal .hsProductName h2 {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
}

#hotspotModal #hsPrice,
#hotspotModal #hsRefPrice {
    display: block;
    text-align: left;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    margin-right: 4px;
}

@media (min-width: 1022px) {
    #hotspotModal #hsPrice,
    #hotspotModal #hsRefPrice {
        text-align: right;
    }
}

#hotspotModal .hsColourDesc,
#hotspotModal .PinWrapText .col-xs-12 {
    width: 100%;
    flex: 1 1 100%;
    order: 3;
}

@media (min-width: 768px) {
    #hotspotModal .hsColourDesc,
    #hotspotModal .PinWrapText .col-xs-12 {
        order: 2;
    }
}

#hotspotModal .col-xs-12.hsColourSelection,
#hotspotModal .col-xs-12.hsSizeSelection {
    flex: 1 1 100%;
    padding: 0;
}

#hotspotModal .hsColourDesc,
#hotspotModal .BuySizeText,
#hotspotModal .sizeGuideLink {
    margin: 24px 0 4px;
    padding: 0 5px 0 0;
}

#hotspotModal .BuySizeText,
#hotspotModal .sizeGuideLink {
    flex-basis: 50%;
}

.hsColourSelection .hsVariantButtons,
.hsSizeSelection .hsVariantButtons {
    padding: 0;
    margin: 0 -5px;
}

#hotspotModal .hsVariantButtons {
    display: flex;
    flex-wrap: wrap;
    padding-right: 5px;
}

#hotspotModal .hsVariantButtons li {
    margin: 5px 0 5px 5px;
}

#hotspotModal .hsVariantButtons li.greyOut a {
    background: #f8f8f8;
    color: #c4c4c4;
    border: 1px solid #e7eaec;
}

#hotspotModal .hsVariantButtons li a {
    border-radius: 4px;
    overflow: hidden;
}

#hotspotModal #ulHsSizes li {
    flex-basis: calc(20% - 5px);
}

#hotspotModal .hsVariantButtons .hsVariantHighlight::before {
    content: none;
}

#hotspotModal .hsVariantHighlight a {
    border: 1px solid #0000ed !important;
    overflow: hidden;
}

#hotspotModal .hsColourDesc .ColourDesc,
#hotspotModal .hsColourDesc .ColourLabel,
#hotspotModal .hsSizeLabel {
    font-style: normal;
    font-size: 13px;
    font-weight: var(--fw-normal);
    letter-spacing: normal;
}

#hotspotModal .sizeGuide-text {
    text-decoration: underline;
}

#hotspotModal #hsViewProduct {
    text-align: center;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 52px;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    width: 100%;
    background: #dde1e4;
}

#hotspotModal #hsViewProduct a {
    display: block;
    width: 100%;
    height: 100%;
    text-transform: uppercase;
    font-weight: var(--fw-bold);
    font-size: 14px;
    padding: 16px 0 17px;
}

#hotspotModal #hsViewProduct:hover {
    background: #0000ed;
}

#hotspotModal #hsViewProduct:hover a {
    color: #fff;
    text-decoration: none;
}

#hotspotModal .hsColourSelection {
    order: 4;
}

#hotspotModal .BuySizeText {
    order: 5;
}

#hotspotModal .sizeGuideLink {
    order: 6;
}

#hotspotModal .hsSizeSelection {
    order: 7;
}

#hotspotModal .PinWrapText .col-xs-12.hsbottom {
    width: 100%;
    order: 8;
    position: static;
    padding: 0 0 10px;
}

#hotspotModal #hsAddToBagContainer,
#hotspotModal #hsAddToWishListContainer {
    margin-top: 24px;
}

#hotspotModal #hsAddToWishListContainer a {
    font-size: 16px;
    letter-spacing: 0;
    font-weight: var(--fw-bold);
    padding: 20px 5px;
    text-transform: capitalize;
}

#hotspotModal #addHotspotToBag,
#hotspotModal #addHotspotToWishList {
    border-radius: 4px;
    text-transform: capitalize;
}

.sdlProdList #ProductContainer {
    border-top: 0;
    width: 100%;
}

@media (min-width: 1022px) {
    .sdlProdList #ProductContainer {
        padding-left: 30px;
    }
}

@media (min-width: 1022px) {
    .productdescriptionname {
        font-size: 15px;
        line-height: 21px;
    }

    .productdescriptionbrand {
        font-size: 12px;
        line-height: 14px;
    }

    .s-largered .curprice {
        font-size: 14px;
    }
}

.hotspotbuy.hotspotquickbuy .QuickLookText {
    display: block;
    position: absolute;
    border-radius: 4px;
    color: #fff;
    padding: 8px 0;
    height: 32px;
    background: #000;
    width: auto;
    left: 8px;
    right: 8px;
    bottom: 8px;
    text-transform: capitalize;
}

#BodyWrap .hotspotbuy.hotspotquickbuy {
    background: rgba(255, 255, 255, 0.5);
    left: 0;
    right: 0;
    bottom: -48px;
    margin: 0;
    width: 100%;
    padding: 0;
    height: 48px;
    display: block !important;
    transition: bottom linear 0.25s;
}

#BodyWrap .s-productthumbimage:hover .hotspotbuy.hotspotquickbuy {
    bottom: 0;
}

.sdDetails .stockLevelWrapper {
    width: 100%;
}

.stock-level-container {
    border-radius: 4px;
    margin: 0 0 16px;
}

/* SD LV8 */
/* Global overrides*/
.s-breadcrumbs-bar,
.s-breadcrumbs-bar a,
.s-breadcrumbs-bar a:link,
.s-breadcrumbs-bar a:visited,
.s-breadcrumbs-bar a:active {
    color: #aaa;
}

.s-breadcrumbs-bar a:hover,
.s-breadcrumbs-bar a:focus {
    color: #000;
}

.s-breadcrumbs-bar a,
.s-breadcrumbs-bar a:active,
.s-breadcrumbs-bar a:focus,
.s-breadcrumbs-bar a:hover,
.s-breadcrumbs-bar a:visited {
    color: #aaa;
}

.s-breadcrumbs-bar a:first-of-type {
    padding-left: 5px;
}

.s-breadcrumbs-bar li:first-of-type a {
    padding-left: 0;
}

.s-breadcrumbs-bar .hereTItle {
    display: none;
}

li#liBack {
    font-weight: 800;
    color: #000;
    cursor: pointer;
    padding-right: 5px;
}
li#liBack:hover {
    color: #0000ed;
}
.breadcrumb-seperator.back {
    font-weight: 800;
    color: #000;
}

li#liBack .global-icon-chevron {
    display: inline-block;
}

li#liBack .global-icon-chevron svg {
    width: 10px;
    height: 10px;
}

@media (max-width: 1021px) {
    li#liBack {
        padding-right: 0px;
    }
}

.sdDetails .ProdDetLeft .logontitle {
    padding-bottom: 0;
}

.brandTitle-price {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}

.brandTitle a,
.brandTitle a span {
    color: #7d8082;
}

#lblFromText {
    text-transform: lowercase;
}

.sdDetails .AltProdDet .title h1 #lblProductName,
.sdDetails .AltProdDet .pdpPrice,
.sdDetails .AltProdDet .originalprice {
    letter-spacing: normal;
}

.sdDetails .AltProdDet .title h1 {
    font-size: 14px;
}

.sdDetails .AltProdDet .pdpPrice,
.sdDetails .AltProdDet .title h1 #lblProductName {
    font-size: 15px;
}

.sdDetails .AltProdDet .originalprice {
    padding-right: 3px;
    padding-left: 0;
    color: #7d8082;
}

.sdDetails .AltProdDet .spnFrom {
    margin-right: 3px;
}

#TopPaginationWrapper .paginationWrapper {
    padding: 8px 0 16px;
}

#TopPaginationWrapper.activeFilterBar .paginationWrapper {
    position: relative;
    z-index: 1;
    width: 100% !important;
}

.sdlProdList #FilterContainer.activeFilter {
    overflow: auto;
}

.s-productextras-column-1 span,
.s-productextras-column-2-3 span {
    letter-spacing: normal;
}

.s-productextras-column-2-3 span {
    font-style: normal;
}

.swapColour .s-productextras-column-2-3 {
    padding: 5px 0 5px 5px;
}
/* Reviews */
#productDetails .rating-container div.bv_main_container_row_flex {
    display: none !important;
}

#productDetails .rating-container .bv_numReviews_text {
    color: #0000ed !important;
    background-color: #e7eaec !important;
    border-radius: 8px !important;
    padding: 3.5px !important;
    font-size: 10px !important;
    text-decoration: none !important;
}

/* PDP Elevation 21 */

#productDetails .TrueFitWrapper {
    padding: 0 15px;
}

#productDetails .TrueFitWrapper iframe {
    margin: 10px 0 15px;
}

.sdDetails #productImages #productImageContainer {
    width: 100%;
}

#productImages #productImageContainer .productImage.productImageGrid {
    position: relative;
}

#productImages #productImageContainer .productImage.productImageGrid .innerImageContainer,
.AltProdDet #divMultiplePurchases,
.BasketWishContainer,
.sdDetails .s-basket-minus-button,
.sdDetails .s-basket-plus-button {
    display: flex;
}

#productImages #productImageContainer .productImage.productImageGrid .innerImageContainer a.viewMoreNumber {
    display: none;
}

@keyframes loading-spinner {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.sdPageProductContainer .productImageContentWrapper {
    display: flex;
}

.sdPageProductContainer .ColourImagesWrap,
.AltProdDet #productDetails .productVariantContainer > div,
.BasketWishContainer {
    z-index: 1 !important;
}

.AltProdDet .productVariantContainer .pnlPersonalisation {
    padding-top: 15px;
}

#productImages {
    position: relative;
}

#productImages.ImageNotReady .ThumbProdWrap,
#productImages .ThumbProdWrap {
    position: relative;
    z-index: 1;
    opacity: 1;
}

#productImages.ImageNotReady::before,
#productImages.ImageNotReady::after {
    content: "";
    display: block;
    position: absolute;
}

#productImages.ImageNotReady::before {
    background-color: #efefef;
    opacity: 0.8;
    block-size: 100%;
    inline-size: 100%;
    z-index: 2;
    inset-block-start: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-end: 0;
}

#productImages.ImageNotReady::after {
    border-radius: 50%;
    block-size: 48px;
    inline-size: 48px;
    z-index: 3;
    inset-inline-start: 50%;
    inset-block-start: 50%;
    translate: -50% -50%;
    border-width: 3px;
    border-style: solid;
    border-color: #ed0000 #ed0000 #0000ed;
    animation: 1s loading-spinner linear infinite;
}

/* Size Buttons*/
.s-productextras-column-1 span,
.s-productextras-column-1 label,
.s-productextras-checkbox-column-1 label {
    font-weight: var(--fw-normal);
    letter-spacing: normal;
}

.SzQuantGroup {
    margin-top: 24px;
}

.SzQuantGroup .SizeGuideText {
    font-size: 12px;
    font-weight: var(--fw-normal);
    line-height: 16px;
}

.sdDetails .sizeButtons.row {
    margin-left: -2px;
    margin-right: -2px;
}

.SzQuantGroup .sizeButtons li {
    margin: 0;
    padding: 0 2px 4px;
    width: 33.333333%;
    height: auto;
    background-color: transparent;
    font-size: 1.2em;
}

/* two sizes */
.SzQuantGroup .sizeButtons li:first-child:nth-last-child(2),
.SzQuantGroup .sizeButtons li:first-child:nth-last-child(2) ~ li {
    width: 50%;
}

.SzQuantGroup .sizeButtons li.sizeVariantHighlight::before {
    display: none;
}

.SzQuantGroup .sizeButtons li.sizeVariantHighlight a {
    border: 1px solid #0000ed;
    color: #0000ed;
    background-color: #e8e8ff;
    font-weight: var(--fw-normal);
}

.SzQuantGroup .sizeButtons li a {
    border: 1px solid #e7eaec;
    background-color: #fff;
    border-radius: 4px;
    height: 52px;
    line-height: normal;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SzQuantGroup .sizeButtons li a span {
    line-height: 1em;
    pointer-events: none;
}

.SzQuantGroup .sizeButtons li.greyOut a {
    background: #f8f8f8;
    color: #c4c4c4;
    border: 1px solid #e7eaec;
}

.SzQuantGroup .sizeButtons li a:hover,
.SzQuantGroup .sizeButtons li a:focus {
    border: 1px solid #0000ed;
    background: #e8e8ff;
}

.SzQuantGroup .sizeButtons li.greyOut a:hover,
.SzQuantGroup .sizeButtons li.greyOut a:focus {
    background: #f8f8f8;
}

#productDetails .colourImages li.greyOut.variantHighlight a,
#productDetails .sizeButtons li.sizeVariantHighlight.greyOut a {
    border-color: #000;
}

.BasketWishContainer {
    margin-top: 20px;
    margin-bottom: 14px;
    flex-wrap: wrap;
}

.BasketWishContainer .addtoBagWrap {
    flex: 1;
}

.sdDetails .AltProdDet .WishListContain {
    width: auto !important;
}

.AltProdDet #divMultiplePurchases,
.sdDetails .AltProdDet .WishListContain {
    float: none;
    margin: 0;
    padding: 0;
}

.AltProdDet #divMultiplePurchases {
    margin-bottom: 24px;
    height: 48px;
}

.sdDetails .AltProdDet .WishListContain .sAddToWishListWrapper,
.sdDetails .AltProdDet .WishListContain .sAddToWishListWrapper a {
    height: 100%;
}

.sdDetails .WishListContain .global-icon {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.hotspotbuy.hotspotwishlist .wishListSVG svg {
    position: relative;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.sdDetails .WishListContain svg path,
.hotspotbuy.hotspotwishlist .wishListSVG svg {
    transition: all 0.3s ease-in-out;
}

.sdDetails .WishListContain a:hover svg path,
.sdDetails .WishListContain a:focus svg path {
    stroke: #000;
}

.sdDetails .WishListContain .addedWishList a:hover svg path {
    stroke: #5c5c5c;
    fill: #5c5c5c;
}

.hotspotbuy.hotspotwishlist.addedWishList,
#productlistcontainer .hotspotbuy.hotspotwishlist.addedWishList {
    opacity: 1;
}

.sdDetails .WishListContain .addedWishList svg path,
.hotspotbuy.hotspotwishlist.addedWishList .wishListSVG svg path {
    fill: #000;
    stroke: #000;
}

.sdDetails #availableFromContainer #litAvailableFromDate,
.sdDetails #availableFromContainer .availableFromTitle {
    font-size: 13px;
    line-height: 17px;
}

.sdDetails #availableFromContainer {
    padding: 10px 5px;
    float: left;
    width: 100%;
    background-color: #e6e6e6;
    border-radius: 4px;
    margin: 0 0 24px;
    display: flex;
    justify-content: center;

    .availableFromTitle {
        font-weight: var(--fw-normal);
        padding-left: 10px;
    }

    #litAvailableFromDate {
        padding-left: 5px;
        font-style: normal;
        font-weight: var(--fw-bold);
    }
}

@media (min-width: 768px) and (max-width: 1022px) {
    #availableFromContainer {
        text-align: center;
        flex-wrap: wrap;
    }
}

.qtyBorder {
    border: 1px solid #dde1e4;
    background-color: #fff;
    display: flex;
    max-width: 180px;
    margin-right: 4px;
    border-radius: 4px;
    align-items: center;
}

.sdDetails .s-basket-minus-button,
.sdDetails .s-basket-plus-button {
    transition: background-color linear 0.3s;
    height: 100%;
    align-items: center;
    text-align: center;
    border-radius: 4px 0 0 4px;
    margin: 0;
    flex: 1;
}

.sdDetails .s-basket-plus-button {
    border-radius: 0 4px 4px 0;
}

.sdDetails .s-basket-minus-button > span,
.sdDetails .s-basket-plus-button > span {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.sdDetails .s-basket-minus-button:hover,
.sdDetails .s-basket-plus-button:hover,
.sdDetails .s-basket-minus-button:focus,
.sdDetails .s-basket-plus-button:focus {
    background-color: #000;
    color: #fff;
}

.sdDetails .s-basket-minus-button:hover svg path,
.sdDetails .s-basket-plus-button:hover svg path,
.sdDetails .s-basket-minus-button:focus svg path,
.sdDetails .s-basket-plus-button:focus svg path {
    stroke: #fff;
}

.s-basket-quantity-text-box,
.s-basket-quantity-text-box input {
    height: 100%;
}

.s-basket-quantity-text-box input {
    border: none;
    padding: 0;
    margin: 0;
}
/* Chrome, Safari, Edge, Opera */
.s-basket-quantity-text-box input::-webkit-outer-spin-button,
.s-basket-quantity-text-box input::-webkit-inner-spin-button {
    margin: 0;
}

.s-basket-quantity-text-box input::-webkit-outer-spin-button,
.s-basket-quantity-text-box input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/* Color selectors */
.sdDetails .colourImages {
    transition: height 0.3s ease-in-out;
    height: auto;
    overflow: hidden;
    margin: 0 -2px;
}

.sdDetails .colourImages li.swiper-slide {
    height: auto;
}

.sdDetails .colourImages li {
    height: 56px;
    width: 56px;
    margin: 0;
    padding: 0 2px 4px;
}

.sdDetails .colourImages li a,
.sdDetails .colourImages li.greyOut a {
    border: 1px solid #e7eaec;
    border-radius: 4px;
    padding: 2px;
}

.sdDetails .colourImages li a:hover,
.sdDetails .colourImages li a:focus,
.sdDetails .colourImages li.variantHighlight a {
    border-color: #0000ed;
}

.sdDetails .colourImages li.variantHighlight::before {
    display: none;
}

.sdDetails .colourImages li.greyOut span {
    background: #f8f8f8;
    opacity: 0.85;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    left: 1px;
    top: 1px;
}

.sdDetails .colourImages li a .overlayColorway {
    cursor: pointer;
    background: rgba(231, 235, 237, 0.9);
    border-radius: 4px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 13px;
}

@media (min-width: 1022px) {
    #productImages #productImageContainer .productImage.productImageGrid .innerImageContainer a {
        flex: 0 0 calc(50% - 5px);
    }

    #productImages #productImageContainer .productImage.productImageGrid .gridDisplayOne .innerImageContainer {
        justify-content: center;
    }

    #productImages #productImageContainer .productImage.productImageGrid .gridDisplayOne .innerImageContainer a {
        flex: 0 0 calc(68% - 5px);
    }

    .SzQuantGroup .sizeButtons li {
        width: 25%;
    }

    /* three sizes */
    .SzQuantGroup .sizeButtons li:first-child:nth-last-child(3),
    .SzQuantGroup .sizeButtons li:first-child:nth-last-child(3) ~ li {
        width: 33.3333%;
    }
}

/* 360 view */
.sdDetails #Popup360ButtonWrapper {
    position: absolute;
    left: 18px;
    bottom: 18px;
    width: 46px;
    border-radius: 100%;
    border: 1px solid #5d5c5c;
    background-color: #fff;
}

.sdDetails #Popup360ButtonWrapper::before {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.sdDetails #Popup360ButtonWrapper .glyphicon {
    display: none;
}

.sdDetails #Popup360ButtonWrapper #Popup360Label {
    position: absolute;
    font-size: 14px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.sdDetails .swiper-slide.video-slide:hover .vjs-play-control,
.sdDetails .swiper-slide.video-slide:focus .vjs-play-control,
.sdDetails .swiper-slide:hover .popup360Button,
.sdDetails .swiper-slide:focus .popup360Button {
    color: #000 !important;
    border: 1px solid #000 !important;
}

.productRollOverPanel .pinch-zoom-container {
    height: 100% !important;
}

.productRollOverPanel .pinch-zoom-container::before,
#productImages #productImageGrid .zoomMainImage::before {
    content: "";
    display: block;
    padding-bottom: 100%;
}

.productRollOverPanel .pinch-zoom-container .imgProduct,
.productRollOverPanel .pinch-zoom-container,
#productImages #productImageGrid .zoomMainImage .imgProduct {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    aspect-ratio: 1/1;
}

@media (min-width: 1022px) {
    #productImages #productImageContainer .productImage.productImageGrid .innerImageContainer a,
    .swiper-slide.video-slide {
        flex: 0 0 calc(50% - 5px);
    }
}

@media (min-width: 768px) {
    #productImages #productImageContainer .productImage.productImageGrid .innerImageContainer {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    #productImages #productImageContainer .productImage.productImageGrid .innerImageContainer a {
        margin-bottom: 10px;
    }

    #productImages #productImageContainer .productImage.productImageGrid .innerImageContainer a.viewMoreNumber {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        cursor: pointer;
    }

    #productImages #productImageContainer .productImage.productImageGrid .innerImageContainer a.viewMoreNumber span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 5em;
        color: #000;
        z-index: 1;
    }

    #productImages
        #productImageContainer
        .productImage.productImageGrid
        .innerImageContainer
        a.viewMoreNumber
        .viewMoreImageGrid {
        flex: 0 0 50%;
        opacity: 0.3;
        background-size: cover;
    }

    #productImages
        #productImageContainer
        .productImage.productImageGrid
        .innerImageContainer
        a.viewMoreNumber
        .viewMoreImageGrid::before {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    #productImages #productImageContainer .productImage.productImageGrid .innerImageContainer .viewMoreHide {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1021px) {
    #productImages #productImageContainer .productImage.productImageGrid .innerImageContainer a.viewMoreNumber {
        flex: 0 0 100%;
    }
}

@media (max-width: 767px) {
    .sdPageProductContainer .productImageContentWrapper {
        flex-wrap: wrap;
    }

    .sdDetails .productImageContentWrapper .product-header--mobile {
        flex: 0 0 100%;
    }

    #productImages {
        flex: 1 1 100%;
        width: 100%;
        padding: 0 5px;
    }

    #productImages.ImageNotReady #productImageContainer .productImage.productImageGrid {
        display: flex;
        overflow: hidden;
    }

    #productImages.ImageNotReady #productImageContainer .productImage.productImageGrid .productRollOverPanel {
        flex: 0 0 90%;
    }

    #productImages .ThumbProdWrap {
        padding: 0;
    }

    .sdDetails .productRollOverPanel .swiper-scrollbar {
        bottom: 2px;
        left: 50%;
        transform: translateX(-50%);
        max-width: 150px;
        height: 3px;
    }

    .sdDetails .productRollOverPanel .swiper-scrollbar.swiper-scrollbar-lock {
        display: none !important;
    }

    .sdDetails .productRollOverPanel .swiper-scrollbar .swiper-scrollbar-drag {
        background-color: #0000ed;
    }

    .sdDetails .personalisationContainer {
        padding-left: 15px;
        padding-right: 15px;
    }
}

/* Elevation PDP - move up when Grid branch merged */
.sdDetails .AltProdDet .infoTabs {
    display: none;
}

.sdDetails .infoaccordion {
    display: block;
}

.sdDetails .myIdPersonalisationContainer {
    background: transparent;
}

.sdDetails .myIdPersonalisationWrap,
.productPersonalisation .PersonaliseSpace {
    margin-bottom: 10px;
    padding: 0;
    background: #fff;
}

.AltProdDet #productDetails .BasketWishContainer .addtoBagWrap a svg,
.AltProdDet #productDetails .addtoBagWrap .addToBasketContainer a svg,
.AltProdDet #productDetails .PersVouchBasketContainer a svg {
    display: none;
}

.sdDetails .AltProdDet .addToBasketContainer.sizeSelected .ImgButWrap #aPrePersAddToBag,
#divPersaddToBasketContainer .ImgButWrap a {
    background-color: #80e096 !important;
    color: #000 !important;
    border-radius: 4px;
}

.AltProdDet #productDetails .BasketWishContainer .addtoBagWrap a,
.AltProdDet #productDetails .addtoBagWrap .addToBasketContainer a,
.sdDetails .AltProdDet .addToBasketContainer .ImgButWrap #aPrePersAddToBag,
.AltProdDet #productDetails .PersVouchBasketContainer a,
#productMyIdPerosonalisationPages .addtoBagWrap a,
.sticky-atb .sticky-atb--buysection a.addToBag,
.OrderComplete.Checkout22 a.button,
.Checkout22 .OrderCompleteRegistration .ContinueOn {
    background-color: #131313 !important;
    border-radius: 4px;
    padding: 17px 15px;
    position: relative;
    margin: 0;
}

.AltProdDet #productDetails .addtoBagWrap .addToBasketContainer a {
    text-transform: capitalize;
}

.sticky-atb .sticky-atb--buysection a.addToBag {
    padding: 8px 15px;
    text-transform: capitalize;
}

.AltProdDet #productDetails .addtoBagWrap .addToBasketContainer a span,
.AltProdDet #productDetails .PersVouchBasketContainer a span,
#productMyIdPerosonalisationPages .addtoBagWrap a span,
.sticky-atb .sticky-atb--buysection a span {
    position: relative;
    z-index: 1;
}

.AltProdDet #productDetails .BasketWishContainer .addtoBagWrap .sizeSelected a,
#PreorderPopupModal .popupPreorderPopup .modal-body .preOrderbuttons .preorderContinueButton,
.AltProdDet #productDetails .PersVouchBasketContainer.sizeSelected a,
#productMyIdPerosonalisationPages .addtoBagWrap.sizeSelected a,
.sticky-atb .sticky-atb--buysection .sizeSelected a,
#hotspotModal .addToBasketContainer .ImgButWrap a {
    background-color: #80e096 !important;
    color: #131313 !important;
    opacity: 1;
}

.AltProdDet #productDetails .BasketWishContainer .addtoBagWrap .sizeSelected a:hover,
#hotspotModal .addToBasketContainer .ImgButWrap a:hover,
.AltProdDet #productDetails .PersVouchBasketContainer.sizeSelected a:hover {
    background-color: #96eaa9 !important;
}

.AltProdDet #productDetails .BasketWishContainer .addtoBagWrap .sizeSelected a::after,
.AltProdDet #productDetails .PersVouchBasketContainer a::after,
#productMyIdPerosonalisationPages .addtoBagWrap a::after,
.sticky-atb .sticky-atb--buysection .sizeSelected a::after {
    display: block;
    content: "";
    position: absolute;
    border-radius: 4px 0 0 4px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 100%;
    transition:
        right 0.7s ease-in,
        opacity 0.25s 0.5s ease-in;
}

.AltProdDet #productDetails .BasketWishContainer .addtoBagWrap .addToBagInProgress a::after,
.AltProdDet #productDetails .PersVouchBasketContainer.addToBagInProgress a::after,
#productMyIdPerosonalisationPages .addtoBagWrap.addToBagInProgress a::after,
.sticky-atb .sticky-atb--buysection .addToBagInProgress a::after {
    background-color: #72c786;
    right: 25%;
}

.AltProdDet #productDetails .BasketWishContainer .addtoBagWrap .addedToBag a::after,
.AltProdDet #productDetails .PersVouchBasketContainer.addedToBag a::after,
#productMyIdPerosonalisationPages .addtoBagWrap.addedToBag a::after,
.sticky-atb .sticky-atb--buysection .addedToBag a::after {
    right: 0;
    border-radius: 4px;
    opacity: 0;
    background-color: #72c786;
}

.AltProdDet #productDetails .PersVouchBasketContainer.addedToBag a::before,
#productMyIdPerosonalisationPages .addtoBagWrap.addedToBag a::before,
.sticky-atb .sticky-atb--buysection .addedToBag a::before {
    display: inline-block;
    margin-right: 10px;
    content: "";
    height: 14px;
    width: 7px;
    border-right: 2px solid #000;
    border-bottom: 2px solid #000;
    transform: rotate(45deg);
}

.AltProdDet #productDetails .BasketWishContainer .addtoBagWrap .addedToBag a::before {
    display: inline-block;
    content: "";
    transform: none;
    height: 14px;
    width: auto;
    border: 0;
    margin-right: 0;
}

#productDetails .TrafficLights .stockRed,
#productDetails .TrafficLights .stockYellow {
    text-decoration: none;
    text-align: center;
    float: none;
    margin: 10px 0 0;
    font-size: 0.75rem;
    font-weight: var(--fw-normal);
    color: #ed0000;
}

#productDetails .TrafficLights .stockRed::before,
#productDetails .TrafficLights .stockYellow::before {
    content: "!";
    border-radius: 50%;
    font-weight: var(--fw-bold);
    color: #fff;
    margin-right: 8px;
    width: 18px;
    height: 18px;
    display: inline-block;
    background-color: #ed0000;
}

#productDetails .TrafficLights .stockGreen {
    display: none !important;
}

.productNextDayDeliveryCountdownWrapper {
    padding-top: 10px;
}

.ndd-countdown {
    background-color: transparent;
    padding: 0;
}

.ndd-countdown .countdown-timer .countdown-timer-wrapper {
    background-color: transparent;
    color: #000;
    width: 100%;
    padding: 0;
    align-items: end;
}

.ndd-countdown .countdown-timer {
    margin-top: 0;
}

.ndd-countdown .ndd-countdown-time-hour,
.ndd-countdown .ndd-countdown-time-minute,
.ndd-countdown .ndd-countdown-time-second,
.ndd-countdown .ndd-countdown-time-unit {
    color: #ed0000;
    font-weight: var(--fw-normal);
}

.ndd-countdown .ndd-countdown-time-unit {
    margin-right: 5px;
}

.ndd-countdown .countdown-timer-minute-container,
.ndd-countdown .countdown-timer-second-container {
    margin-left: 0;
}

.ndd-countdown .countdown-timer-hour-container,
.ndd-countdown .countdown-timer-minute-container,
.ndd-countdown .countdown-timer-second-container {
    align-items: baseline;
}

.inline-countdown .ndd-countdown .countdown {
    display: inline-block;
}

.inline-countdown .ndd-countdown .countdown-timer-hour-container,
.inline-countdown .ndd-countdown .countdown-timer-minute-container {
    color: #0000ed;
    display: flex;
    transform: translateY(1px);
}

.inline-countdown .ndd-countdown .countdown-timer.cut-off .countdown-timer-hour-container,
.inline-countdown .ndd-countdown .countdown-timer.cut-off .countdown-timer-minute-container {
    color: #ed0000;
}

.inline-countdown .ndd-countdown .countdown-timer .countdown-timer-wrapper {
    margin: 10px 0;
    display: flex;
    justify-content: center;
}

.inline-countdown .ndd-countdown .ndd-countdown-time-unit {
    margin-right: 5px;
}

.inline-countdown .ndd-countdown .ndd-countdown-time-hour,
.inline-countdown .ndd-countdown .ndd-countdown-time-minute,
.inline-countdown .ndd-countdown .ndd-countdown-time-second {
    min-width: 15px;
}

.inline-countdown .ndd-countdown .countdown-timer .countdown-timer-wrapper .ndd-countdown-time-unit {
    margin-left: 0;
}

/* Online Prices Differ note */
.ProdDetails .online-prices-differ-note {
    margin-bottom: 14px;
}

/* Mobile filter work */
.pppt,
.pppt span {
    font-size: 12px;
    line-height: 1;
}

.PageFromTo {
    display: none;
    border: none;
    margin-bottom: 13px;
    height: auto;
}

.topheadbox .PageFromTo {
    font-size: 18px;
    margin-bottom: 0;
    padding: 0;
}

.topheadbox .PageFromTo .pppt,
.topheadbox .PageFromTo .pppt span {
    font-size: 16px;
    line-height: 18px;
    font-style: normal;
    letter-spacing: normal;
}

.topheadbox .PageFromTo .pppt::before {
    content: "(";
    display: inline-block;
}

.topheadbox .PageFromTo .pppt::after {
    content: ")";
    display: inline-block;
    margin-left: -4px;
}

.topheadbox .PageFromTo .productsCountText {
    display: none;
}

.PageFromTo.mobilePageFromTo {
    display: flex;
    margin-bottom: 0;
    float: none;
}

@media (min-width: 768px) {
    .topheadbox .PageFromTo .productsCountText {
        display: inline-block;
    }

    .topheadbox .PageFromTo .pppt::after {
        margin-left: 0;
    }

    .sdDetails .ndd-countdown {
        padding-left: 15px;
        padding-right: 15px;
    }
}

#FiltersHeader .PageFromTo.mobilePageFromTo {
    margin-left: 0;
    margin-top: 4px;
    text-transform: none;
    height: 14px;
    padding: 0;
}

.filtersTitleContainer {
    display: flex;
    align-items: center;
    align-content: center;
}

#FiltersHeader .mobappfltrsNo {
    display: none;
}

#FiltersHeader .mobappfltrsNo.activeFilters {
    display: block;
    margin-left: 6px;
    margin-top: 2px;
    padding: 2px;
    border-radius: 50%;
    color: #0000ed;
    background-color: #dde1e4;
    font-size: 12px;
    height: 18px;
    width: 18px;
    line-height: 12px;
    letter-spacing: 0;
}

.mobSortFilter .mobFilterContainer #filterByMob {
    display: flex;
    align-items: center;
    position: relative;
}

#filterByMob.activeFilters {
    border: 1px solid #0000ed !important;
}

#filterByMob.activeFilters .MobFiltersText {
    color: #0000ed !important;
}

#filterByMob.activeFilters .mobFilterAppIcon {
    background-position: -336px -312px;
}

#filterByMob.activeFilters .mobappfltrsNo {
    width: 21px;
    height: 18px;
    background: #e8e8ff;
    border-radius: 50%;
    color: #0000ed;
    font-size: 12px;
    font-weight: var(--fw-bold);
    line-height: 12px;
    padding: 2px;
    letter-spacing: 0;
    text-align: center;
}

#mobControlBar {
    margin: 0;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
}

.mobFilterAppIcon {
    margin: 0;
    display: block !important;
    background-size: 600px 600px;
    background-position: -290px -312px;
}

.productFilterTitleBox .glyphicon::before {
    background-size: 870px 600px;
    background-position: -424px -287px;
}

.productFilterTitleBox .glyphicon {
    width: 20px;
    height: 20px;
}

.flexFilters {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.mobFilterContainer,
#mobSortFilter {
    height: auto;
}

#filterByMob {
    position: static;
    text-align: left;
    top: 0;
    transform: none;
    text-transform: none;
}

#filterByMob .MobFiltersText {
    width: calc(100% - 20px);
    font-size: 14px;
    font-family: var(--brand-font);
    letter-spacing: 0;
    font-weight: var(--fw-normal);
    line-height: 15px;
}

.productFilterTitleBox .glyphicon::before,
.productFilterTitleBox.FilterClosed .glyphicon::before,
.sdlProdList .glyphicon-ok-sign::before {
    content: "";
    display: block;
    height: 20px;
    width: 20px;
}

.mobFilterAppIcon.glyphicon.glyphicon-ok-sign::before {
    vertical-align: top;
}

.sdlProdList .glyphicon-ok-sign::before {
    background-position: -240px -417px;
}

.productFilterTitleBox.FilterClosed .glyphicon::before {
    background-size: 870px 600px;
    background-position: -386px -287px;
}

.Responsive .productFilterTitleBox {
    padding: 0;
}

@media (max-width: 1021px) {
    .sdlProdList .Responsive .FilteringApplied .productFilterTitleBox {
        background: transparent;
    }
}

@media (min-width: 1022px) {
    .productFilterTitleBox.FilterClosed .glyphicon::before {
        background-position: -264px -288px;
        background-size: 600px 600px;
    }

    .productFilterTitleBox .glyphicon::before {
        background-size: 600px 600px;
        background-position: -290px -288px;
    }

    .flexFilters {
        justify-content: space-between;
    }
}

#innerfiltercontainer .productFilters > li {
    border: none;
    border-bottom: 1px solid #ddd;
    padding-bottom: 12px;
    padding-top: 12px;
}

#innerfiltercontainer .productFilters .productFilter.filtersSet .productFilterTitle {
    color: #0000ed !important;
}

#innerfiltercontainer .productFilters > li.FilterClosed,
#innerfiltercontainer .MobSortSelector {
    border: none;
    border-bottom: 1px solid #ddd;
}

#innerfiltercontainer .productFilters > li.FilterClosed {
    border: none;
    border-bottom: 1px solid #ddd;
    padding-bottom: 12px;
}

.selectedFiltersGroup.FilterClosed .selectedFiltersGroupInner {
    margin: 0;
}

.filtersSet .selectedFiltersGroup {
    margin-top: 12px;
}

.filtersSet .selectedFilter {
    margin-bottom: 4px;
}

.productFilterList {
    border: none;
}

.MobSortSelector {
    border: none;
    margin: 16px;
}

.MobSortSelector .mobAppliedFilters {
    height: 0;
}

.mobAppliedFilters {
    display: none !important;
}

.mobSortFilter .mobAppliedFilters {
    font-size: 1em;
    height: auto;
    min-height: 15px;
}

.MobSortSelector .productFilterTitleBox.FilterClosed {
    padding: 0 0 12px;
}

.MobSortSelector .productFilterTitleBox.FilterClosed + .productFilterList {
    display: none;
}

.MobSortSelector .productFilterList {
    padding: 0 0 22px;
    display: block;
}

.mobSortFilter .MobSortSelector {
    margin: 0;
    height: 100%;
}

.mobSortFilter .MobSortSelector > .productFilterList {
    position: absolute;
    z-index: 960;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 8px 16px;
    left: auto;
    top: 50px;
    width: auto;
    margin-left: -15px;
}

.mobSortFilter .MobSortSelector > .productFilterList .sdlProdList .MobSortSelector input[type="radio"] {
    display: none;
}

.sdlProdList .FilterAnchor .MobSortSelectionCheckbox {
    width: auto;
}

.sdlProdList .productFilterList .FilterAnchor .MobSortSelectionCheckbox {
    padding: 0 10px 0 12px;
    line-height: 22px;
}

@media (min-width: 1022px) {
    .sdlProdList .productFilterList .FilterAnchor .MobSortSelectionCheckbox {
        line-height: 16px;
    }
}

.sdlProdList .MobSortSelector label {
    font-size: 15px;
    color: #161616;
}

input#MobSortOptions_discountvalue_desc {
    margin: 0;
}

.Responsive .productFilterList .FilterAnchor {
    display: flex;
    align-items: center;
    padding: 6px 0;
}

.Responsive .productFilterList .FilterAnchor:hover,
.Responsive .FilterListItem a:hover .FilterName {
    text-decoration: none;
}

.MobSortSelector input[type="radio"] {
    display: block;
    appearance: none;
    margin: 0;
    width: 16px;
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    border: 1px solid #585d5e;
    background: #fff;
    border-radius: 10px;
}

.MobSortSelector input[type="radio"]::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: #0000ed;
    margin: 2px;
    display: none;
}

.MobSortSelector input[type="radio"]:checked::before {
    display: block;
}

.MobSortSelector input[type="radio"]:checked {
    border: 1px solid #0000ed;
}

.selectedFiltersGroupInner {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}

.filtersSet .selectedFiltersGroupInner {
    margin: 12px 0 0;
}

.SelectedFilter {
    display: block;
}

@media (min-width: 1022px) {
    .selectedFilter {
        margin-bottom: 8px;
    }
}

.selectedFilterToggle {
    background: #dde1e4;
    position: relative;
    padding: 4px 28px 5px 8px;
    border-radius: 4px;
    margin-right: 4px;
    display: block;
    cursor: pointer;
}

.selectedFilterLabel {
    color: #131313;
    font-size: 12px;
    line-height: 1;
}

.selectedFilterToggle::after {
    display: block;
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    content: "";
    background-size: 400px 400px;
    background-position: -243px -194px;
    margin: 0;
}

.filtersOverflow {
    background: #dde1e4;
    position: relative;
    padding: 4px 8px 5px;
    border-radius: 4px;
    margin-right: 4px;
    margin-bottom: 4px;
}

.filtersOverflow span {
    color: #131313;
    font-size: 12px;
    line-height: 1;
}

.brandSearchSort {
    padding: 0;
}

#divBrandSearch input {
    border-radius: 4px;
}

.brandSearchSort .SortQtyName {
    display: block;
}

#productDetails .recognitionStrip {
    margin-bottom: 15px;
}

.ACOL.FilterListItem__show,
.category-ACOL .FilterListItem__hide {
    display: inline-block;
    width: 49%;
}

.FilterShowMore {
    padding-left: 31px;
    font-size: 15px;
    text-decoration: underline;
    color: #7d8082;
    margin-top: 4px;
}

.topheadbox .SelectedFiltersWrapper {
    display: none !important;
}

.sdlProdList .productFilterList .SelectableFilter::before,
.sdlProdList .productFilterList .SelectedFilter::before {
    width: 18px;
    min-width: 18px;
    max-width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: top;
    font-size: 11px;
    border-radius: 4px;
    margin-left: 1px;
}

.sdlProdList .productFilterList .SelectableFilter::before {
    border: 1px solid #5b5d5e;
    background: #fff;
}

.sdlProdList .productFilterList .SelectedFilter::before {
    background: #0000ed;
    border: 3px solid #fff;
    box-shadow: 0 0 0 1px #0000ed;
}

.sdlProdList .productFilterList .greyOut .FilterValue {
    background: none;
}

/*Colour filter blocks*/
.sdlProdList .productFilterList .SelectableFilter.ACOLblack::before {
    background: #000;
    border: 0 solid #fff;
    box-shadow: 0 0 0 1px #000;
}

.sdlProdList .productFilterList .SelectedFilter.ACOLblack::before {
    background: #000;
    border: 3px solid #fff;
    box-shadow: 0 0 0 1px #000;
}

.sdlProdList .productFilterList .SelectableFilter.ACOLblue::before {
    background: #3f50e3;
    border: 0 solid #fff;
    box-shadow: 0 0 0 1px #3f50e3;
}

.sdlProdList .productFilterList .SelectedFilter.ACOLblue::before {
    background: #3f50e3;
    border: 3px solid #fff;
    box-shadow: 0 0 0 1px #3f50e3;
}

.sdlProdList .productFilterList .SelectableFilter.ACOLgrey::before {
    background: #999;
    border: 0 solid #fff;
    box-shadow: 0 0 0 1px #999;
}

.sdlProdList .productFilterList .SelectedFilter.ACOLgrey::before {
    background: #999;
    border: 3px solid #fff;
    box-shadow: 0 0 0 1px #999;
}

.sdlProdList .productFilterList .SelectableFilter.ACOLpink::before {
    background: #e398c5;
    border: 0 solid #fff;
    box-shadow: 0 0 0 1px #e398c5;
}

.sdlProdList .productFilterList .SelectedFilter.ACOLpink::before {
    background: #e398c5;
    border: 3px solid #fff;
    box-shadow: 0 0 0 1px #e398c5;
}

.sdlProdList .productFilterList .SelectableFilter.ACOLbrown::before {
    background: #683f2e;
    border: 0 solid #fff;
    box-shadow: 0 0 0 1px #683f2e;
}

.sdlProdList .productFilterList .SelectedFilter.ACOLbrown::before {
    background: #683f2e;
    border: 3px solid #fff;
    box-shadow: 0 0 0 1px #683f2e;
}

.sdlProdList .productFilterList .SelectableFilter.ACOLred::before {
    background: #ed0000;
    border: 0 solid #fff;
    box-shadow: 0 0 0 1px #ed0000;
}

.sdlProdList .productFilterList .SelectedFilter.ACOLred::before {
    background: #ed0000;
    border: 3px solid #fff;
    box-shadow: 0 0 0 1px #ed0000;
}

.sdlProdList .productFilterList .SelectableFilter.ACOLgreen::before {
    background: #62904c;
    border: 0 solid #fff;
    box-shadow: 0 0 0 1px #62904c;
}

.sdlProdList .productFilterList .SelectedFilter.ACOLgreen::before {
    background: #62904c;
    border: 3px solid #fff;
    box-shadow: 0 0 0 1px #62904c;
}

.sdlProdList .productFilterList .SelectableFilter.ACOLpurple::before {
    background: #693ee1;
    border: 0 solid #fff;
    box-shadow: 0 0 0 1px #693ee1;
}

.sdlProdList .productFilterList .SelectedFilter.ACOLpurple::before {
    background: #693ee1;
    border: 3px solid #fff;
    box-shadow: 0 0 0 1px #693ee1;
}

.sdlProdList .productFilterList .SelectableFilter.ACOLbeige::before {
    background: #f1e2d0;
    border: 0 solid #fff;
    box-shadow: 0 0 0 1px #f1e2d0;
}

.sdlProdList .productFilterList .SelectedFilter.ACOLbeige::before {
    background: #f1e2d0;
    border: 3px solid #fff;
    box-shadow: 0 0 0 1px #f1e2d0;
}

.sdlProdList .productFilterList .SelectableFilter.ACOLorange::before {
    background: #ec8044;
    border: 0 solid #fff;
    box-shadow: 0 0 0 1px #ec8044;
}

.sdlProdList .productFilterList .SelectedFilter.ACOLorange::before {
    background: #ec8044;
    border: 3px solid #fff;
    box-shadow: 0 0 0 1px #ec8044;
}

.sdlProdList .productFilterList .SelectableFilter.ACOLsilver::before {
    background: #c4c4c4;
    border: 0 solid #fff;
    box-shadow: 0 0 0 1px #c4c4c4;
}

.sdlProdList .productFilterList .SelectedFilter.ACOLsilver::before {
    background: #c4c4c4;
    border: 3px solid #fff;
    box-shadow: 0 0 0 1px #c4c4c4;
}

.sdlProdList .productFilterList .SelectableFilter.ACOLyellow::before {
    background: #f1e15d;
    border: 0 solid #fff;
    box-shadow: 0 0 0 1px #f1e15d;
}

.sdlProdList .productFilterList .SelectedFilter.ACOLyellow::before {
    background: #f1e15d;
    border: 3px solid #fff;
    box-shadow: 0 0 0 1px #f1e15d;
}

.sdlProdList .productFilterList .SelectableFilter.ACOLcream::before {
    background: #f4f4e7;
    border: 0 solid #fff;
    box-shadow: 0 0 0 1px #e7eaec;
}

.sdlProdList .productFilterList .SelectedFilter.ACOLmetallics::before {
    background: linear-gradient(222.8deg, #565656 0%, rgba(255, 255, 255, 0.38) 50%, #565656 100%);
    border: 3px solid #fff;
    box-shadow: 0 0 0 1px #5b5d5e;
}

.sdlProdList .productFilterList .SelectableFilter.ACOLmetallics::before {
    background: linear-gradient(222.8deg, #565656 0%, rgba(255, 255, 255, 0.38) 50%, #565656 100%);
    border: 0 solid #fff;
    box-shadow: 0 0 0 1px #5b5d5e;
}

.sdlProdList .productFilterList .SelectedFilter.ACOLmetallics:hover::before,
.sdlProdList .productFilterList .SelectableFilter.ACOLmetallics:hover::before {
    background-position: center;
}

.sdlProdList .productFilterList .SelectableFilter.ACOLgold::before {
    background: #c9a854;
    border: 0 solid #fff;
    box-shadow: 0 0 0 1px #c9a854;
}

.sdlProdList .productFilterList .SelectedFilter.ACOLgold::before {
    background: #c9a854;
    border: 3px solid #fff;
    box-shadow: 0 0 0 1px #c9a854;
}

#PriceFilterTextEntry {
    padding: 0;
    display: flex;
}

#PriceFilterTextEntryApply {
    margin-top: 0;
    margin-left: 10px;
}

#PriceFilterTextEntryError {
    position: absolute;
    margin-top: 40px;
    margin-bottom: 0;
}

#PriceFilterTextEntry input {
    border-radius: 4px;
}

#Body.sdlProdList #mobFilterControls li .textIconWrap {
    background-color: #000 !important;
    border-radius: 4px;
    border: 1px solid #000;
}

.sdlProdList #mobFilterControls li#mobclrfltrs .textIconWrap {
    background-color: transparent !important;
    border: 1px solid #000;
}

.sdlProdList #mobFilterControls li#mobclrfltrs .textIconWrap span {
    color: #000;
}

#FiltersHeader {
    height: 94px;
    background: #f8f8f8;
    border-bottom: 1px solid #dde1e4;
    padding: 0;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    align-content: space-between;
}

.sdlProdList .ChildCategoriesListWrapper {
    padding: 0 16px;
}

.ChildCategoriesListWrapper #ChildCategoriesList {
    padding-right: 10px;
}

.sdlProdList .ChildCategoriesListWrapper #ChildCategoriesList li {
    min-height: 28px;
    margin-bottom: 5px;
    flex-basis: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.sdlProdList .ChildCategoriesListWrapper #ChildCategoriesList li a {
    font-size: 15px;
    padding: 2px 0;
    line-height: 24px;
    width: 100%;
}

.ChildCategoriesListWrapper h2 {
    padding: 0;
    letter-spacing: 0;
    margin-bottom: 10px;
    text-align: left;
    font-size: 16px;
    text-transform: capitalize;
}

@media (min-width: 1022px) {
    .sdlProdList .ChildCategoriesListWrapper {
        padding: 0 0 22px;
        border-bottom: 1px solid #ddd;
        margin-bottom: 14px;
    }

    .sdlProdList .ChildCategoriesListWrapper h2 {
        padding: 0;
    }
}

.Responsive .s-left-header h2 {
    padding: 16px;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0;
}

.MobFiltersText {
    font-size: 20px;
    line-height: 24px;
    text-transform: none;
    display: block;
    letter-spacing: 0;
}

.Responsive .mobDdClose .clsFilterIcon {
    font-family: initial;
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.Responsive .mobDdClose .clsFilterIcon::before {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    background-size: 900px 600px;
    background-position: -541px -281px;
}

.Responsive .mobDdClose {
    width: 40px;
    line-height: 40px;
    height: 40px;
    padding: 0;
    border: 0;
    top: auto;
    right: 5px;
    bottom: 22px;
    position: absolute;
}

.sdlProdList .productFilterTitleBox ~ .clearfilters.visible {
    display: block;
    font-size: 12px;
}

.productFilter .clearfilters.visible .productFilterClear {
    text-decoration: underline;
    text-transform: none;
    float: none;
    padding: 12px 0;
    display: block;
}

.sdlProdList #spnSearchIcon .glyphicon {
    font-size: 11px;
    font-family: initial;
    background-size: 700px 700px;
    background-position: -274px -379px;
    height: 30px;
    width: 30px;
}

.sdlProdList #spnSearchIcon .glyphicon::before {
    content: none;
}

.productFilter.FilterClosed .selectedFiltersGroupInner {
    margin: 0;
}

/* accordions */
.infoaccordions div:first-child .panel-heading {
    border-top: 1px solid #dde1e4;
}

.infoaccordions {
    margin-top: 10px;
}

.infoaccordions .panel-heading,
.shippingaccordion .panel-heading {
    border-bottom: 1px solid #dde1e4;
    padding: 0;
    border-radius: 0;
}

.shippingaccordion .panel-heading {
    border-bottom: 1px solid #dde1e4;
}

.infoaccordions .panel-heading a {
    display: block;
}

.infoaccordions .panel-heading h4 {
    padding: 0;
    font-size: 1.25em;
    position: relative;
    color: #000;
}

.infoaccordions .panel-heading h4::after,
.infoaccordions .panel-heading h4::before {
    content: "";
    display: block;
    height: 2px;
    width: 12px;
    border-radius: 2px;
    background-color: #000;
    position: absolute;
    right: 0;
    top: 50%;
    transition: transform 0.25s;
}

.infoaccordions .panel-heading a.collapsed h4::after {
    transform: rotate(-90deg);
}

.infoaccordions .panel-heading a h4::after {
    transform: rotate(0deg);
}

.infoaccordions .panel-body {
    background-color: #fff;
    border-bottom: 1px solid #dde1e4;
}

.infoaccordions span,
.infoaccordions p,
.infoaccordions h4,
.infoaccordions .productDescriptionInfoText,
.infoaccordions .DeliveryMethods li .SpendVoucher {
    font-size: 1.25em !important;
}

.infoaccordions p.productCode {
    font-size: 1.1em;
}

.infoaccordions .productDescriptionInfoText > *,
.infoaccordions .DeliveryMethods li .SpendVoucher span,
.infoaccordions .productDescriptionInfoText span {
    font-size: 1em !important;
}

.infoaccordions .productCode,
.infoaccordions .DeliveryMethods li .DeliveryMethodDescription,
.infoaccordions .DeliveryMethods li .SpendVoucher {
    color: #7d8082 !important;
}

.infoaccordions .DeliveryMethodsContainer {
    margin-bottom: 16px;
}

.infoaccordions .DeliveryMethods li {
    margin-bottom: 8px;
    justify-content: space-between;
}

.infoaccordions .DeliveryMethods li,
.infoaccordions .DeliveryMethods li > div:first-child,
#DisplayAttributes li {
    display: flex;
}

.infoaccordions .DeliveryMethods li > div:first-child {
    padding-right: 16px;
    flex: 1;
    flex-direction: column;
}

.infoaccordions .DeliveryMethods li span.TitleDel {
    padding-right: 8px;
    min-width: 100px;
}

.infoaccordions .DeliveryMethods li .DeliveryMethodDescription,
.infoaccordions .DeliveryMethods li .SpendVoucher {
    margin: 0;
    flex: 1;
}

#DisplayAttributes {
    margin: 0;
    display: block;
}

#DisplayAttributes li {
    padding-bottom: 8px;
    list-style-type: none;
    border: none;
}

#DisplayAttributes li:last-child {
    padding-bottom: 0;
}

#DisplayAttributes li span.feature-name,
#DisplayAttributes li span.feature-value {
    width: auto;
    padding: 0;
}

#DisplayAttributes li span.feature-name {
    padding-right: 8px;
}

#DisplayAttributes li span.feature-name::after {
    content: ": ";
    display: inline-block;
    padding-left: 2px;
}

#DisplayAttributes li span.feature-value {
    flex: 1;
}

.productFilter .productFilterList {
    padding-bottom: 12px;
}

.productFilter .productFilterList #spnClearBrandFilter {
    position: absolute;
    width: 30px;
    top: 0;
    right: 0;
    background-size: 625px 625px;
    background-position: -372px -290px;
    overflow: hidden;
}

@media (min-width: 769px) {
    .productImage {
        max-width: 100%;
    }
}

@media (min-width: 1022px) {
    .PageFromTo {
        display: block;
    }

    .PageFromTo.mobilePageFromTo {
        display: none;
    }

    #FiltersHeader {
        display: none;
    }

    .productFilterTitleBox,
    .productFilterTitleBoxCLOSED {
        margin-bottom: 10px;
    }

    .productFilterTitleBox.FilterClosed {
        margin: 0;
    }

    .sdlProdList #FilterContainer {
        border: none;
        padding: 0 5px 0 0;
        flex-basis: 240px;
    }

    #FilterContainer #innerfiltercontainer {
        padding: 12px 0 0;
        border-top: 1px solid #dde1e4;
    }

    .productFilterList {
        border: none;
        padding: 0 4px 0 0;
        max-height: 345px;
    }

    .ACOL.FilterListItem__show,
    .category-ACOL .FilterListItem__hide {
        width: 100%;
    }

    .AltProdList ul.productFilters > li .mobFiltInnerWrap {
        position: relative;
    }

    .Responsive .productFilterList .FilterAnchor > span {
        display: flex;
        align-items: center;
    }

    .sdlProdList .productFilterList .FilterName {
        width: auto;
        font-size: 13px;
        line-height: 17px;
    }

    .sdlProdList .productFilterList .FilterValue {
        line-height: 1em;
    }

    .sdlProdList .productFilterList .FilterValue font {
        vertical-align: initial !important;
    }

    .sdlProdList .productFilterList .SelectableFilter::before,
    .sdlProdList .productFilterList .SelectedFilter::before {
        width: 16px;
        min-width: 16px;
        max-width: 16px;
        height: 16px;
    }

    .MobSortSelector {
        margin: 0;
        display: block;
    }

    .sdlProdList .MobSortSelector label,
    .sdlProdList .productFilterList .FilterName,
    .FilterShowMore {
        font-size: 14px;
        flex-grow: 1;
        margin-bottom: 0;
        line-height: 1.4em;
    }

    .FilterShowMore {
        padding-left: 28px;
        font-size: 12px;
    }

    .mobSortDesc .productFilterTitle {
        font-size: 14px;
    }

    #FiltersAndProductsWrapper #FilterContainer ul.productFilters .productFilter .FilterAnchor {
        padding: 6px 5px 6px 0;
    }

    .productFilter .productFilterList .brandSearchSort {
        padding: 0 6px 0 0;
    }

    .productFilter .productFilterList::-webkit-scrollbar,
    .MobSortSelector .productFilterList::-webkit-scrollbar {
        width: 5px;
        margin-left: 8px;
    }

    .productFilter .productFilterList::-webkit-scrollbar-track,
    .MobSortSelector .productFilterList::-webkit-scrollbar-track {
        background: #ddd;
    }

    .productFilter .productFilterList::-webkit-scrollbar-thumb,
    .MobSortSelector .productFilterList::-webkit-scrollbar-thumb {
        background: #0000ed;
        border-radius: 5px;
    }

    .sdlProdList .productFilterList .SelectableFilter:hover .FilterName,
    .sdlProdList .productFilterList .SelectedFilter:hover .FilterName {
        text-decoration: underline;
    }

    #PriceFilterTextEntryMin,
    #PriceFilterTextEntryMax {
        width: 33%;
    }
}

@media (min-width: 1440px) {
    .MobSortSelectionCheckbox label {
        margin: 0;
    }
}

@media (max-width: 1021px) {
    .Responsive #FilterContainer #innerfiltercontainer {
        display: flex;
        flex-direction: column;
        padding: 0 0 73px;
    }

    .Responsive #FilterContainer #innerfiltercontainer .productFilters {
        padding: 0 16px;
    }
}

/* SD LV8 PLP Header Changes */

.sdlProdList .ContentWrapper,
.Checkout .ContentWrapper {
    padding: 0;
    max-width: 1440px;
}

.sdlProdList .ContentWrapper,
.sdlProdList #lblCategoryCopy,
.sdlProdList .catTemplate,
.sdlProdList .topheadbox,
.Checkout .ContentWrapper {
    max-width: 100%;
}

.sdlProdList .ImgTxtContainer {
    border-bottom: 1px solid #dde1e4;
    padding: 0 0 20px;
    float: none;
}

.sdlProdList .ImgTxtContainer,
.sdlProdList .BreadcrumbGroupWrapper {
    text-align: left;
    background: #f8f8f8;
}

.sdlProdList #BreadcrumbGroup {
    padding: 10px 16px;
}

.sdlProdList #FiltersAndProductsWrapper {
    padding: 16px;
}

.sdlProdList .topheadbox,
.sdlProdList #BreadcrumbGroup,
.sdlProdList #FiltersAndProductsWrapper {
    max-width: 1400px;
    margin: 0 auto;
    float: none;
}

.sdlProdList .topheadbox.col-xs-12,
.sdlProdList .topheadbox {
    padding-left: 0;
    padding-right: 0;
}

.sdlProdList .s-breadcrumbs-container {
    margin: 0;
}

.sdlProdList .catTemplate .textDesc {
    font-size: 14px;
    text-align: left;
    width: 100%;
    margin: 0 auto;
    padding: 0;
}

#divsiblinglistwrapper {
    padding: 0;
}

#divsiblinglistwrapper ul {
    text-align: left;
}

#divsiblinglistwrapper ul li {
    height: auto;
    overflow: initial;
}

#lblCategoryCopy .catTemplate .siblingLinks,
.topheadbox .siblingLinks,
#divsiblinglistwrapper ul.ulFirst {
    padding-top: 12px;
    padding-left: 0;
    padding-right: 0;
    font-size: 1em;
    margin: 0;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

#lblCategoryCopy .catTemplate .siblingLinks {
    display: block;
}

#divsiblinglistwrapper ul.ulFirst {
    display: flex;
}

#lblCategoryCopy .catTemplate .siblingLinks a,
.topheadbox .siblingLinks a,
#lblCategoryCopy .catTemplate-FWC .siblingLinks a,
.catTemplate-FWC .siblingLinks a,
#divsiblinglistwrapper ul li a {
    line-height: 14px;
    background-color: #fff;
    letter-spacing: 0;
    padding: 12px 16px;
    display: inline-block;
    font-size: 12px;
    color: #000;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    text-transform: none;
    margin: 0 4px 10px 0;
}

#lblCategoryCopy .catTemplate-FWC .siblingLinks a {
    min-width: 0;
}

#lblCategoryCopy .catTemplate .siblingLinks a:hover,
#lblCategoryCopy .catTemplate .siblingLinks a:focus,
#lblCategoryCopy .catTemplate .siblingLinks a:active,
.topheadbox .siblingLinks a:hover,
.topheadbox .siblingLinks a:focus,
.topheadbox .siblingLinks a:active,
#divsiblinglistwrapper ul li a:hover,
#divsiblinglistwrapper ul li a:focus,
#divsiblinglistwrapper ul li a:active {
    border-color: #0000ed;
}

#divsiblinglistwrapper ul li a:hover,
#divsiblinglistwrapper ul li a:focus,
#divsiblinglistwrapper ul li a:active {
    text-decoration: none;
}

#lblCategoryCopy .catTemplate .siblingLinks a:active,
.topheadbox .siblingLinks a:active,
#divsiblinglistwrapper ul li a:active {
    border-color: #0000ed;
    background-color: #0000ed;
    color: #fff;
}

#lblCategoryHeader,
.sdlProdList #catHeader {
    font-size: 18px;
    font-weight: var(--fw-bold);
    margin-right: 4px;
    color: #000;
    letter-spacing: 0;
    text-transform: uppercase;
}

#lblCategoryCopy .textDesc,
.topheadbox .textDesc {
    text-align: left;
}

.categorycopyd4,
.col-xs-12.FilterProductsTable {
    padding: 0;
}

.Browse .ImgTxtContainer .online-prices-differ-note,
.ProdDetails .online-prices-differ-note {
    display: none;
}

#lblCategoryCopy .siblingLinks,
.topheadbox .siblingLinks,
#divsiblinglistwrapper ul.ulFirst {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    scrollbar-color: #0000ed #c4c4c4;
}

#lblCategoryCopy .siblingLinks::-webkit-scrollbar,
.topheadbox .siblingLinks::-webkit-scrollbar,
#divsiblinglistwrapper ul.ulFirst::-webkit-scrollbar {
    height: 5px;
}

#lblCategoryCopy .siblingLinks::-webkit-scrollbar-thumb,
.topheadbox .siblingLinks::-webkit-scrollbar-thumb,
#divsiblinglistwrapper ul.ulFirst::-webkit-scrollbar-thumb {
    background: #0000ed;
    border-radius: 4px;
}

#lblCategoryCopy .siblingLinks::-webkit-scrollbar-track,
.topheadbox .siblingLinks::-webkit-scrollbar-track,
#divsiblinglistwrapper ul.ulFirst::-webkit-scrollbar-track {
    background: #c4c4c4;
    border-radius: 4px;
    margin-left: 16px;
    margin-right: 16px;
}

.online-prices-differ-note {
    padding: 0 16px;
}

/*Flex ordering to position image above header*/
.sdlProdList .catImage {
    order: 1;
}

.sdlProdList #catHeader {
    order: 2;
    margin-left: 0;
    width: auto;
}

.sdlProdList #lblCategoryCopy,
.sdlProdList .catTemplate,
.sdlProdList #lblCategorySearchCopy {
    order: 3;
}

.sdlProdList .HeadnCount {
    padding: 24px 16px 0;
}

.sdlProdList .siblingcategory,
.sdlProdList .catDesc {
    padding: 0 16px;
}

.sdlProdList .siblingLinks span:first-of-type {
    margin-left: 16px;
}

.sdlProdList .siblingLinks span:last-of-type {
    margin-right: 16px;
}

#divsiblinglistwrapper ul li:last-of-type a,
#lblCategoryCopy .catTemplate .siblingLinks span:last-of-type a,
.topheadbox .siblingLinks span:last-of-type a {
    margin: 0 0 10px;
}

#CategoryAcross {
    padding: 10px 16px;
}

@media (min-width: 768px) {
    .sdlProdList .ImgTxtContainer {
        padding-bottom: 26px;
    }

    #lblCategoryCopy .catTemplate .siblingLinks a,
    .topheadbox .siblingLinks a,
    #divsiblinglistwrapper ul li a {
        padding: 10px;
    }

    #lblCategoryCopy .catTemplate {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 767px) {
    #lblCategoryCopy .catTemplate .siblingLinks a,
    .topheadbox .siblingLinks a,
    #divsiblinglistwrapper ul li a {
        background-color: #fff;
    }
}

@media (min-width: 1022px) {
    .sdlProdList .catDesc,
    .sdlProdList .HeadnCount,
    .sdlProdList #BreadcrumbGroup,
    .sdlProdList .online-prices-differ-note,
    .sdlProdList #FiltersAndProductsWrapper,
    .sdlProdList #lblCategoryCopy,
    .sdlProdList .siblingcategory,
    #CategoryAcross {
        padding-left: 40px;
        padding-right: 40px;
    }

    .sdlProdList #lblCategorySearchCopy {
        padding-left: 40px;
    }

    #lblCategoryCopy .siblingLinks::-webkit-scrollbar-track,
    .topheadbox .siblingLinks::-webkit-scrollbar-track,
    #divsiblinglistwrapper ul.ulFirst::-webkit-scrollbar-track {
        margin-left: 40px;
        margin-right: 40px;
    }

    #lblCategoryCopy .catTemplate .siblingLinks,
    .topheadbox .siblingLinks,
    #divsiblinglistwrapper ul.ulFirst {
        white-space: normal;
        flex-wrap: wrap;
    }

    #lblCategoryCopy .catTemplate .siblingLinks a,
    .topheadbox .siblingLinks a,
    #divsiblinglistwrapper ul li a {
        margin-bottom: 4px;
    }

    .sdlProdList .siblingLinks span:first-of-type {
        margin-left: 0;
    }

    .sdlProdList .siblingLinks span:last-of-type {
        margin-right: 0;
    }

    .sdlProdList .catDesc,
    .sdlProdList .catTemplate-FWC {
        padding-left: 0;
        padding-right: 0;
    }

    .sdlProdList .catTemplate {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media (min-width: 1640px) {
    #lblCategoryHeader,
    .sdlProdList .catDesc,
    #lblCategoryCopy .catTemplate .siblingLinks,
    .topheadbox .siblingLinks,
    .sdlProdList .topheadbox,
    .sdlProdList #lblCategorySearchCopy,
    .sdlProdList .siblingcategory,
    .sdlProdList #lblCategoryCopy,
    .sdlProdList .HeadnCount,
    #CategoryAcross {
        padding-left: 0;
        padding-right: 0;
    }

    .sdlProdList .catTemplate,
    .sdlProdList #catHeader,
    .sdlProdList #BreadcrumbGroup,
    .sdlProdList .online-prices-differ-note,
    .sdlProdList #FiltersAndProductsWrapper {
        padding-left: 0;
        padding-right: 0;
    }

    .sdlProdList .siblingLinks span:first-of-type,
    .sdlProdList .siblingLinks span:first-of-type a {
        margin-left: 0;
    }

    .sdlProdList .siblingLinks span:last-of-type {
        margin-right: 0;
    }

    .sdlProdList .catDesc,
    #lblCategoryCopy .catTemplate .siblingLinks,
    .topheadbox .siblingLinks,
    .sdlProdList .siblingcategory {
        margin-left: 0;
    }

    .sdlProdList .topheadbox,
    .sdlProdList #BreadcrumbGroup,
    .sdlProdList #FiltersAndProductsWrapper {
        max-width: 1600px;
    }
}

.sdlProdList .catTemplate-FWC .textDesc,
.mobile-read-more-less-enabled #lblCategoryCopy #read-more-less,
.mobile-read-more-less-enabled .topheadbox #read-more-less {
    font-size: 14px;
}

.sdlProdList #lblCategorySearchCopy,
.sdlProdList .catTemplate-FWC {
    order: 3;
    padding: 0 16px;
}

.sdlProdList #lblCategorySearchCopy {
    padding-right: 0;
}

.sdlProdList #lblCategorySearchCopy,
.sdlProdList #CategoryResultLink {
    display: block;
    font-size: 14px;
    margin-top: 6px;
}

#pnlRecentlyViewedProducts .swiper-slide {
    margin-right: 8px;
}

/* Need adding to the portal*/
.hotspotbuy.hotspotquickbuy,
#hotspotModal #hsViewProduct,
#hotspotModal #hsViewProduct a {
    transition: all 0.3s ease-in;
}

/* Pre-Order Modal Styling */

#PreorderPopupModal .modal-dialog.popupPreorderPopup {
    padding: 0;
    position: fixed;
    bottom: 0;
    height: min-content;
}

#PreorderPopupModal .popupPreorderPopup .modal-body {
    padding: 0 16px 30px;
    height: 370px;
}

#PreorderPopupModal .popupPreorderPopup .modal-content .modal-header .text-center,
#PreorderPopupModal .popupPreorderPopup .modal-body .preOrderContent,
.Checkout22 .OrderCompleteRegistrationDone p {
    text-align: left;
}

#PreorderPopupModal .popupPreorderPopup .modal-content .modal-header .header-text {
    text-transform: initial;
}

#PreorderPopupModal .popupPreorderPopup .modal-content .modal-header {
    padding-left: 16px;
}

#PreorderPopupModal .popupPreorderPopup .modal-body .preOrderContent .preOrderText {
    font-size: 0.938rem;
}

#PreorderPopupModal .popupPreorderPopup .modal-body .preOrderContent .preorderDate {
    display: block;
    margin: 15px 0;
    font-size: 1.2rem;
}

#PreorderPopupModal .popupPreorderPopup .modal-body .addToBasketContainer .ImgButWrap {
    max-width: 100%;
    padding: 0;
}

#PreorderPopupModal .popupPreorderPopup .modal-body .addToBasketContainer .ImgButWrap a {
    border-radius: 4px;
}

#PreorderPopupModal .popupPreorderPopup .modal-body .addToBasketContainer .ImgButWrap:first-child {
    margin-bottom: 12px;
}

#PreorderPopupModal .popupPreorderPopup .modal-body .addToBasketContainer .ImgButWrap:first-child a {
    background-color: #80e096 !important;
}

#PreorderPopupModal .popupPreorderPopup .modal-body .addToBasketContainer .ImgButWrap:first-child a span {
    color: #131313;
}

@media (min-width: 500px) {
    #PreorderPopupModal .popupPreorderPopup .modal-body .addToBasketContainer .ImgButWrap:first-child {
        padding-right: 10px;
    }

    #PreorderPopupModal .popupPreorderPopup .modal-body .addToBasketContainer .ImgButWrap:last-child {
        padding-left: 10px;
    }
}

@media (min-width: 768px) {
    #PreorderPopupModal .modal-dialog.popupPreorderPopup {
        bottom: unset;
        left: 0;
        right: 0;
    }

    #PreorderPopupModal .popupPreorderPopup .modal-body {
        padding: 7px 40px 23px;
        height: fit-content;
    }

    #PreorderPopupModal .popupPreorderPopup .modal-body .preOrderContent {
        text-align: center;
        margin-bottom: 15px;
        justify-content: center;
    }

    #PreorderPopupModal .popupPreorderPopup .modal-body .preOrderContent .preOrderText {
        margin-top: 23px;
    }
}

/* Hides product Title and gap in the product information */
.sdDetails .infoPageDescription .productDescriptionInfoText h2 {
    display: none;
}

/* PDP Container Changes */
.pdpExtras {
    background: #fff;
    padding-top: 40px;
    padding-bottom: 40px;
}

.sdDetails .sdPageProductContainer,
.sdDetails .BreadcrumbGroupWrapper,
.sdDetails .ContentPane,
.sdDetails .PSPlacementWrapper,
.sdDetails .ContentWrapper {
    background: #f8f8f8;
}

.sdDetails .ResponsiveProductDetail,
.sdDetails .PSPlacementWrapper {
    background: #fff;
}

.sdDetails .BreadcrumbGroupWrapper {
    padding-top: 10px;
}

.sdDetails .prodDetailsWrap.col-xs-12,
.sdDetails .pdpMoreLinksDelivery.col-xs-12 {
    padding: 0;
}

.sdDetails .infoaccordions .panel-heading a,
.sdDetails .shippingaccordion .panel-heading a {
    padding: 18px 16px;
}

.sdDetails .PSPlacementWrapper {
    padding-left: 15px;
    padding-right: 15px;
}

.sdDetails #BodyWrap .ContentWrapper {
    max-width: none;
    padding-top: 0;
    padding-bottom: 0;
}

.sdDetails .pdpPriceRating {
    padding-top: 20px;
    padding-bottom: 20px;
}

.sdDetails .frasers-plus-payment-enabled .FrasersPlusContainer {
    margin-top: 14px;
    padding: 0 15px;
}

.sdDetails .frasers-plus-payment-enabled .productNextDayDeliveryCountdownWrapper + .FrasersPlusContainer {
    margin-top: 29px;
}

.OrderComplete.Checkout22 {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1600px;
    margin: 0 auto;
}

.orderCompleteRegisterShown {
    display: flex;
    flex-wrap: wrap;
}

.orderCompleteRegisterShown .orderInformationWrapper {
    order: 1;
}

.orderCompleteRegisterShown .RegisterWrapper,
.orderCompleteRegisterShown .OrderCompleteRegistration {
    order: 2;
}

.orderCompleteRegisterShown .order-summary {
    order: 3;
}

.orderCompleteRegisterShown .footer-nav {
    order: 4;
    justify-content: center;
}

.OrderComplete.Checkout22 h1,
.order-complete-subtitle {
    text-align: left;
    margin-bottom: 30px;
    font-weight: var(--fw-normal);
}

.order-details-delivery-date span {
    color: #0000ed;
}

.Checkout22 .OrderCompleteRegistration .ContinueOn {
    text-transform: capitalize;
}

.OrderComplete.Checkout22 h1 em {
    font-style: inherit;
}

.OrderComplete.Checkout22 h1 em::before {
    display: none;
}

.order-complete-messages .subtitle,
.order-complete-subtitle {
    margin-bottom: 10px;
}

.OrderComplete.Checkout22 a.button,
.Checkout22 .OrderCompleteRegistration .ContinueOn {
    border: 1px solid;
    display: block;
    line-height: normal;
    letter-spacing: normal;
    padding: 15px;
}

.OrderComplete .order-complete-messages a.button {
    margin-top: 20px;
}

.order-summary-discount-value {
    color: #ed0000;
}

/* Sort Fix */
.sdlProdList #divSortOptions {
    display: none;
}

.sdDetails .hereTItle {
    display: none;
}

@media (min-width: 1640px) {
    .sdDetails .productImageContentWrapper,
    .sdDetails .PSPlacementWrapper .PSPlacementHorizontal,
    .sdDetails .BreadcrumbGroupWrapper,
    .sdDetails .reviews-container {
        max-width: 1600px;
        margin: 0 auto;
        padding-left: 40px;
        padding-right: 40px;
        float: none;
        overflow: hidden;
    }

    .sdDetails .ContentWrapper {
        max-width: none;
    }

    .sdDetails .productImageContentWrapper,
    .sdDetails .PSPlacementWrapper .PSPlacementHorizontal,
    .sdDetails .BreadcrumbGroupWrapper {
        background: transparent;
    }
}

@media (min-width: 1022px) {
    .sdDetails .productImageContentWrapper,
    .sdDetails .BreadcrumbGroupWrapper,
    .sdDetails .PSPlacementWrapper .PSPlacementHorizontal,
    .sdDetails .PSPlacementWrapper {
        padding-left: 60px;
        padding-right: 60px;
    }

    .sdDetails .sdPageProductContainer {
        padding-left: 0;
        padding-right: 0;
    }

    .sdDetails #productImages {
        padding: 0;
    }

    .sdDetails #productDetails {
        padding-left: 40px;
        padding-bottom: 40px;
    }

    .sdlProdList .s-productscontainer2 > li {
        margin-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .sdlProdList .productFilterList .FilterName,
    .sdlProdList .productFilterList .FilterValue {
        line-height: 1.4em;
    }
}

@media (min-width: 768px) {
    .sdDetails .sdPageProductContainer,
    .sdDetails .BreadcrumbGroupWrapper,
    .sdDetails .PSPlacementWrapper {
        padding-left: 40px;
        padding-right: 40px;
    }

    #TopPaginationWrapper.sticky .paginationWrapper {
        padding-right: 16px;
        padding-left: 16px;
    }

    nav#topMenu li.root .SubMenuWrapper > ul .disablelink a {
        pointer-events: auto;
    }

    nav#topMenu li.root .SubMenuWrapper > ul .disablelink > a {
        pointer-events: none;
    }

    .sdDetails .ContentWrapper {
        padding: 10px 0 0;
    }

    .sdDetails #productImages {
        width: 60%;
        padding: 0;
    }

    .sdDetails #productDetails {
        width: 40%;
        padding-left: 24px;
    }

    .sdDetails .reviews-container,
    .OrderComplete.Checkout22 {
        padding-left: 40px;
        padding-right: 40px;
    }

    .OrderComplete.Checkout22 a.button {
        display: inline-block;
        min-width: 330px;
    }

    .order-complete-subtitle {
        margin-bottom: 30px;
    }

    .OrderComplete .order-complete-messages a.button {
        margin-top: 40px;
    }

    .sticky-atb .originalprice {
        padding-right: 0;
    }
}

@media (min-width: 1440px) {
    .sdDetails #productImages {
        width: 70%;
    }

    .sdDetails #productDetails {
        width: 30%;
    }
}

/* 360 Modal styling for elevation only */
.popupFrame360.popupFullscreen .modal-content .sliderWrap {
    bottom: 40px;
}

.popupFrame360.popupFullscreen .modal-content .sliderWrap input[type="range"] {
    margin: 0 auto;
    padding: 0 20px;
}

.popupFrame360.popupFullscreen input[type="range"]::-webkit-slider-runnable-track {
    background: #0000ed;
    border-radius: 3px;
}

.popupFrame360.popupFullscreen input[type="range"]::-webkit-slider-thumb {
    border: none;
    background: #0000ed;
    transition: transform 0.3s;
}

.popupFrame360.popupFullscreen input[type="range"]:hover::-webkit-slider-thumb {
    transform: scale(1.1);
}

.popupFrame360.popupFullscreen input[type="range"]::-moz-range-track {
    background: #0000ed;
    border-radius: 3px;
}

.popupFrame360.popupFullscreen input[type="range"]::-moz-range-thumb {
    border: none;
    background: #0000ed;
}

.popupFrame360.popupFullscreen input[type="range"]::-ms-fill-lower {
    background: #0000ed;
    border-radius: 3px;
}

.popupFrame360.popupFullscreen input[type="range"]::-ms-fill-upper {
    background: #0000ed;
    border-radius: 3px;
}

.popupFrame360.popupFullscreen input[type="range"]::-ms-thumb {
    border: none;
    background: #0000ed;
}

.ProdDetails .ShopTheLook {
    background-color: #f8f8f8;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 24px 0;
    border: none;
    max-width: 1600px;
}

.ProdDetails .ShopTheLook::before,
.ProdDetails .ShopTheLook::after {
    display: none;
}

.ProdDetails .ShopTheLook .shopLookImg {
    overflow: hidden;
    display: flex;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 2;
}

.ProdDetails .ShopTheLook .shopLookImg h2 {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    width: 100%;
    color: #fff;
    margin: 0;
    border: none;
}

.ProdDetails .ShopTheLook .shopLookImg img {
    width: 100%;
    display: none;
}

.ProdDetails .ShopTheLook .shopLookCarousel {
    padding: 0 0 40px;
    display: flex;
    align-items: center;
    max-width: none;
}

.ProdDetails .ShopTheLook .shopLookCarousel > div {
    width: 100%;
}

.ProdDetails .ShopTheLook .LookProducts li {
    margin-top: 16px;
    min-height: auto;
    border: none;
}

.ProdDetails .ShopTheLook .productlist-overlay,
#BodyWrap .ShopTheLook .hotspotbuy.hotspotquickbuy,
.ShopTheLook .hotspotbuy.hotspotsaveforlater {
    display: none !important;
}

.ProdDetails .ShopTheLook .s-productthumbbox {
    display: block;
}

.ProdDetails .ShopTheLook .s-productthumbtext {
    background-color: transparent;
}

.ProdDetails .ShopTheLook .CurrencySizeLarge.curprice {
    padding: 0;
}

.ProdDetails .ShopTheLook .swiper-button-prevLook,
.ProdDetails .ShopTheLook .swiper-button-nextLook {
    top: 50%;
    left: 10px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: none;
    opacity: 0.5;
    transition: opacity 0.3s;
}

.ProdDetails .ShopTheLook ul#navlist.s-productscontainer2.swiper-wrapper {
    overflow: visible;
}

.ProdDetails .ShopTheLook .swiper-container.swiper-container-shop-the-look:not(.swiper-grid) {
    overflow: visible;
    padding: 0 45px;
    margin: 0;
}

.ProdDetails
    .ShopTheLook
    .swiper-container-shop-the-look:not(.swiper-grid)
    ul#navlist.s-productscontainer2.swiper-wrapper {
    padding: 0;
}

.ProdDetails .ShopTheLook .swiper-button-prevLook:hover,
.ProdDetails .ShopTheLook .swiper-button-nextLook:hover {
    opacity: 1;
}

.ProdDetails .ShopTheLook .swiper-button-prevLook.swiper-button-disabled,
.ProdDetails .ShopTheLook .swiper-button-nextLook.swiper-button-disabled {
    opacity: 0;
}

.ProdDetails .ShopTheLook .swiper-button-prevLook svg {
    transform: rotate(180deg);
}

.ProdDetails .ShopTheLook .swiper-button-nextLook {
    right: 0;
    left: auto;
}

.swiper-scrollbar-shopLook.swiper-scrollbar-horizontal {
    position: relative;
    left: 50%;
    bottom: 3px;
    height: 3px;
    width: 30%;
    background-color: #d3d3d3;
    transform: translateX(-50%);
    padding: 1px 0;
    transition:
        all 0.25s ease-in,
        transform 0s;
    margin: 32px 0 0;
}

.swiper-scrollbar-shopLook .swiper-scrollbar-drag {
    background: #0000ed;
    cursor: pointer;
    height: 4px;
    margin: -2px 0;
    transition:
        all 0.25s ease-in,
        transform 0s;
}

@media screen and (max-width: 767px) {
    .ProdDetails .ShopTheLook .shopLookImg {
        padding: 0;
        height: 100vw;
    }

    .ProdDetails .ShopTheLook .shopLookImg img {
        display: none;
        width: auto;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media (min-width: 768px) {
    .ProdDetails .ShopTheLook {
        padding-left: 40px;
        background-color: transparent;
    }

    .ProdDetails .ShopTheLook .swiper-navigation-disabled.swiper-container-shop-the-look:not(.swiper-grid)::before,
    .ProdDetails .ShopTheLook .swiper-navigation-disabled.swiper-container-shop-the-look:not(.swiper-grid)::after {
        display: none;
    }

    .ProdDetails .ShopTheLook .shopLookCarousel {
        padding: 18px 0 15px;
    }

    .swiper-scrollbar-shopLook .swiper-scrollbar-drag {
        height: 3px;
        margin: -1px 0;
    }
}

@media (min-width: 1022px) {
    .ProdDetails .ShopTheLook {
        padding-left: 60px;
    }

    .ProdDetails .ShopTheLook .shopLookCarousel {
        padding: 56px 0 21px;
    }

    .swiper-scrollbar.swiper-scrollbar-shopLook:focus .swiper-scrollbar-drag,
    .swiper-scrollbar.swiper-scrollbar-shopLook:hover .swiper-scrollbar-drag,
    .swiper-scrollbar.swiper-scrollbar-shopLook .swiper-scrollbar-drag:hover .swiper-scrollbar-drag,
    .swiper-scrollbar.swiper-scrollbar-shopLook .swiper-scrollbar-drag:active {
        height: 5px;
        margin: -2px 0;
    }
}

.bv-expand-filter-button-offset-off:has(#bv-text-field-search-contentSearch1:focus) .bv-content-search-btn {
    outline-width: 2px;
    outline-width: 2px !important;
    color: #757575 !important;
    outline-color: #757575 !important;
    outline-style: solid !important;
}

/* Hover/focus PDP */
.s-breadcrumbs-bar > span ol li a:hover,
.s-breadcrumbs-bar > span ol li a:focus,
.brandTitle a:hover span,
.brandTitle a:focus span,
.infoaccordions .panel-body p a:hover,
.infoaccordions .panel-body p a:focus,
.sdDetails .sizeslink:hover .SizeGuideText,
.sdDetails .sizeslink:focus .SizeGuideText {
    color: #0000ed;
}

.s-breadcrumbs-bar > span ol li a:hover,
.s-breadcrumbs-bar > span ol li a:focus {
    text-decoration: none;
}

nav#topMenu li.root .SubMenuWrapper > ul li.level2 a::before,
nav#topMenu ul li.root .SubMenuWrapper > ul li.sdmColHeader a::before,
nav#topMenu ul li.root.MenuGroupBrands .SubMenuWrapper > ul li.sdmColHeader.level1 > a::after,
nav#topMenu li.root .SubMenuWrapper > ul li.level2 a:hover::before,
nav#topMenu li.root .SubMenuWrapper > ul li.level2 a:focus::before,
nav#topMenu ul li.root .SubMenuWrapper > ul li.sdmColHeader a:hover::before,
nav#topMenu ul li.root .SubMenuWrapper > ul li.sdmColHeader a:focus::before,
nav#topMenu ul li.root.MenuGroupBrands .SubMenuWrapper > ul li.sdmColHeader.level1 > a:hover::after,
nav#topMenu ul li.root.MenuGroupBrands .SubMenuWrapper > ul li.sdmColHeader.level1 > a:focus::after {
    border-bottom: none;
}

.sdDetails .infoaccordions .panel-heading a:focus {
    text-decoration: underline;
}

.easyzoom-flyout {
    overflow: visible;
}

.popupFullscreen .ZoomBody ul li {
    overflow: hidden;
}

.sdDetails #MoreFromLinks li.MoreFromLinksRow a {
    color: #000;
}

/*Filters*/
#TopPaginationWrapper .paginationWrapper .mobSortFilter {
    position: static;
}

#TopPaginationWrapper .mobSortFilter .mobTitlArro {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    left: auto;
}

#TopPaginationWrapper #mobSortFilter {
    padding: 0;
}

#TopPaginationWrapper .productFilterTitleBox .glyphicon,
.mobFilterAppIcon {
    height: 20px;
    width: 20px;
}

#TopPaginationWrapper .mobSortDesc {
    width: calc(100% - 20px);
}

#TopPaginationWrapper .mobSortDesc .productFilterTitle {
    text-transform: none;
    color: #161616;
    font-size: 14px;
    font-weight: var(--fw-normal);
    padding-bottom: 0;
}

.sdlProdList .productFilterTitle {
    font-size: 14px;
    text-transform: none;
    padding-bottom: 0;
    line-height: 20px;
    font-weight: var(--fw-bold);
}

.mobSortFilter {
    flex-basis: 50%;
}

.mobSortFilter #filterByMob,
.mobSortFilter .MobSortSelector {
    border: 1px solid #c4c4c4;
    padding: 10px 14px;
    border-radius: 4px;
}

.mobSortFilter .MobSortSelector .FilterCollapseImage {
    order: 1;
}

.mobSortFilter .MobSortSelector .mobSortDesc {
    order: 2;
}

.mobSortFilter.mobSortSelectorWrap {
    margin-right: 8px;
}

#TopPaginationWrapper .MobSortSelector .productFilterTitleBox.FilterClosed {
    padding: 0;
}

#TopPaginationWrapper .productFilterTitleBox .glyphicon::before,
#TopPaginationWrapper .productFilterTitleBox.FilterClosed .glyphicon::before {
    background-size: 650px 650px;
    background-position: -364px -312px;
}

@media (min-width: 768px) {
    .mobSortFilter {
        flex-basis: 25%;
    }

    .flexFilters {
        justify-content: space-between;
    }
}

@media (max-width: 1021px) {
    .mobSortFilter .productFilterTitleBox,
    .mobSortFilter .mobFiltInnerWrap {
        height: 100%;
    }

    .sdlProdList #FilterContainer {
        top: 0;
        box-shadow: none;
        left: 100%;
        width: 100%;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
    }

    .sdlProdList #FilterContainer.MobClosed {
        visibility: hidden;
        display: block;
    }

    .sdlProdList #FilterContainer.activeFilter {
        visibility: visible;
        transform: translateX(-100%);
    }

    .sdlProdList #FilterContainer::before {
        content: "";
        position: fixed;
        z-index: 10050;
        background-color: rgba(0, 0, 0, 0.7);
        width: 0;
        height: 100%;
        left: 0;
        opacity: 0;
        visibility: hidden;
        transition: ease all 0.25s;
        transition-delay: 0.25s;
    }

    .sdlProdList #FilterContainer #mobFilterControls {
        position: absolute;
        height: auto;
        padding: 10px;
        width: 100%;
        display: flex !important;
        left: 0;
        bottom: 0;
        background: #f8f8f8;
    }

    .sdlProdList #mobFilterControls li {
        height: auto;
        flex: 1 1 auto;
        order: 2;
        flex-basis: 50%;
    }

    .sdlProdList #mobFilterControls li#mobclrfltrs {
        border: 0;
        padding-right: 10px;
        order: 1;
    }

    .sdlProdList #mobFilterControls li .textIconWrap {
        position: static;
        top: auto;
        left: auto;
        transform: none;
        padding: 15px 10px;
    }

    .sdlProdList #mobFilterControls li .textIconWrap span {
        font-size: 15px;
        text-transform: none;
    }

    .sdlProdList #mobFilterControls .mobClearFilters .textIconWrap span {
        letter-spacing: normal;
    }

    .sdlProdList #innerfiltercontainer .MobSortSelector {
        display: none;
    }
}

.ShopTheLook.ShopTheLook--overlay-dark .shopLookImg::before,
.ShopTheLook.ShopTheLook--overlay-light .shopLookImg::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.ShopTheLook.ShopTheLook--overlay-dark .shopLookImg::before {
    background: rgba(0, 0, 0, 0.3);
}

.ShopTheLook.ShopTheLook--overlay-light .shopLookImg::before {
    background: rgba(255, 255, 255, 0.3);
}

.ShopTheLook.ShopTheLook--overlay-dark .shopLookImg .LookHeader {
    color: #fff;
}

.ShopTheLook.ShopTheLook--overlay-light .shopLookImg .LookHeader {
    color: #000;
}

/*Personalisation Wishlist*/

.divPersAddToBasketWishlistWrapper,
#productMyIdPerosonalisationPages {
    display: flex;
    flex-wrap: wrap;
}

.personalisationActive .divPersAddToBasketWishlistWrapper {
    margin-top: 20px;
    margin-bottom: 14px;
}

.PersonaliseSpace,
.myIdPersonalisationWrap {
    flex-basis: 100%;
}

#divPersaddToBasketContainer,
#pnlMyIdPersonalisation .addToBasketContainer {
    flex: 1;
}

#PersonalisationModal #divPersaddToBasketContainer,
#PersonalisationModal #pnlMyIdPersonalisation .addToBasketContainer {
    flex-basis: 100%;
}

#pnlMyIdPersonalisation .myIdTabBody {
    height: auto;
}

#Body.sdlProdList #mobFilterControls .clearFiltersOpen .textIconWrap {
    background-color: #0000ed !important;
    border: 1px solid #0000ed;
}

#aPersAddToWishList,
#aNoPersAddToWishList {
    cursor: pointer;
}

.footerLanguageSwitcher #divCurrencyLanguageSelector .spanCurrencyLanguageSelector .flag,
#divLanguageSelector .languageSelector a span.flag,
.MobileMenuContentWrap #liMobileLanguageSelector span.flag {
    display: none;
    background: none;
}

#MenuOpenContentCover,
.ToplinksGroup .container-fluid .header-overlay.overlay,
.modal-backdrop,
.sdlProdList #FilterContainer::before {
    display: none;
    opacity: 1;
    transition: none;
    background-color: transparent;
    transition-delay: 0s;
    filter: none;
}

#MenuOpenContentCover.activeOverlay,
.ToplinksGroup .container-fluid .header-overlay.overlay.activeOverlay,
.modal-backdrop.in,
.sdlProdList #FilterContainer.activeFilter::before {
    display: block;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.4);
    transition-delay: 0s;
    filter: none;
    z-index: 12;
}

#MenuOpenContentCover.activeOverlay {
    z-index: 1;
}

.ToplinksGroup .container-fluid .header-overlay.overlay.activeOverlay {
    z-index: 3;
}

.sdDetails .PSPlacementWrapper.li-pr-pd.li-suggest {
    display: none;
}

.sdDetails .isPreOrderable {
    color: #fff;
}

.sdDetails .colourImages.expandingColorways {
    display: block;
}

.sdDetails .colourImages.expandingColorways.expandColourways {
    height: auto;
}

.sdDetails .colourImages.expandingColorways.expandColourways li.colorwayExpandAction {
    display: none;
}

.order-details-section,
.Checkout22 .OrderCompleteRegisteredSignUp,
.RegisterWrapper-inner,
.Checkout22 .newsletterCompletion,
.order-summary,
.rokt-emdedded-placement-container:has(iframe) {
    background: #fff;
    border: 1px solid #e5e5e5;
}

.Checkout22 .newsletterCompletion,
.Checkout22 .OrderCompleteRegisteredSignUp {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.OrderCompleteRegistrationDone {
    border: none;
    padding: 0;
}

.Checkout22
    .OrderCompleteRegistrationDone:not(
        .OrderCompleteRegisteredSignUp,
        .newsletterCompletion .OrderCompleteRegistrationDone
    ) {
    padding: 0;
}

.Checkout22 .OrderCompleteRegistrationDone p:first-of-type:not(.registration-benefits p) {
    display: block;
    margin-left: 50px;
    position: relative;
}

.Checkout22 .OrderCompleteRegistrationDone p .global-icon-tick {
    left: -50px !important;
    top: 0;
}

.Checkout22 .global-icon-tick {
    position: absolute;
}

.Checkout22 .global-icon-tick svg {
    width: 34px;
}

.orderCompleteRegisterShown .OrderCompleteRegistrationDone p:not(.OrderComplete .registration-benefit p) {
    margin-bottom: 30px;
}

.OrderCompleteRegistrationDone .reward-message p:not(:last-child) {
    margin-bottom: 10px;
}

.OrderComplete.Checkout22:not(.orderCompleteRegisterShown) .footer-nav {
    text-align: center;
    margin: 24px 0;
}

.OrderComplete.Checkout22 .footer-nav p {
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .OrderComplete.Checkout22:not(.orderCompleteRegisterShown) .footer-nav {
        justify-content: center;
    }

    .sdDetails .colourImages.expandingColorways {
        max-height: 108px;
    }
}

.Checkout22 .OrderCompleteRegistration {
    max-width: none;
}

.orderCompleteRegisterShown .innerBorder {
    margin-top: 10px;
}

.Checkout22 .newsletterCompletion .OrderCompleteRegistrationDone,
.Checkout22 .OrderCompleteRegisteredSignUp .OrderCompleteRegistrationFormInner,
.order-summary {
    padding: 18px 18px 30px;
}

.Checkout22 .OrderCompleteRegistrationFormInner {
    max-width: 750px;
}

.Checkout22 .newsletterCompletion p {
    font-size: 1.45em;
    line-height: 1.6em;
}

.OrderCompleteRegistrationFormInner .dnnFormMessage {
    padding: 10px 0;
}

.OrderComplete.Checkout22 h2,
.Checkout22 .SignUpWrapper,
.Checkout22 .RegisterPrompt {
    text-align: left;
}

.OrderComplete.Checkout22 h2 {
    color: #000;
    margin: 0 0 22px;
}

.OrderComplete.Checkout22 h2.account-info {
    padding-top: 30px;
    border-top: 1px solid #e5e5e5;
}

.Checkout22 .OrderCompleteRegistration .SignUpWrapper {
    margin-bottom: 10px;
    display: flex;
    font-size: 1.45em;
    line-height: 1.6em;
    font-weight: var(--fw-normal);
}

.Checkout22 .NewsletterFree,
.Checkout22 .OrderCompleteRegistration .SignUpLabel .NewsletterFree {
    text-transform: uppercase;
    color: #0000ed;
}

.Checkout22 .OrderCompleteRegistration .SignUpLabel .NewsletterFree {
    color: #000;
}

.Checkout22 .OrderCompleteLogin .OrderCompleteLoginForm .dnnPrimaryAction {
    text-transform: uppercase;
    letter-spacing: 1.4px;
    border-radius: 3px;
    padding: 20px 55px;
}

.Checkout22 .OrderCompleteRegistration .SignUpWrapper input {
    width: 15px;
    height: 15px;
    margin-right: 11px;
}

.orderCompleteRegisterShown .OrderCompleteRegistrationFormInner {
    padding: 0;
}

.Checkout22 .OrderCompleteRegistration .ContinueOn,
.RegisterWrapper-inner a.button {
    width: 100%;
}

.orderCompleteRegisterShown .OrderCompleteRegistration .ContinueOn {
    margin: 20px 0;
}

.orderCompleteRegisterShown .OrderCompleteLoginHeader {
    padding: 0;
}

.orderCompleteRegisterShown .OrderCompleteLoginHeader h2 {
    text-decoration: underline;
}

.orderCompleteRegisterShown .OrderCompleteLoginHeader .glyphicon {
    display: none;
}

.orderCompleteRegisterShown .OrderCompleteLoginHeader .glyphicon::before {
    background: none !important;
}

.orderCompleteRegisterShown .OrderCompleteLogin .OrderCompleteLoginForm {
    margin: 0;
    max-width: none;
}

.orderCompleteRegisterShown .OrderCompleteLoginPrompt,
.OrderCompleteRegisteredSignUp {
    text-align: left;
}

.OrderCompleteRegistrationForm .field {
    margin: 0;
    max-width: none;
}

.orderCompleteRegisterShown .OrderCompleteRegistrationForm,
.orderCompleteRegisterShown .OrderCompleteLogin {
    border: none;
}

.orderCompleteRegisterShown .OrderCompleteLogin {
    margin-bottom: 0;
}

.orderCompleteRegisterShown .OrderCompleteLoginForm {
    padding: 10px 0 0;
}

.orderCompleteRegisterShown .OrderCompleteLogin .NewCustWrap {
    position: relative;
}

.orderCompleteRegisterShown .loginContainer .ForgotPass {
    padding: 10px 0;
    text-align: left;
}

.OrderComplete.Checkout22 .OrderCompleteLogin .ForgotPass .ForgotPasswordLinkButton {
    font-weight: var(--fw-normal);
}

.Checkout22 .OrderCompleteRegistrationDone p:not(.RegisterWrapper .OrderCompleteRegistrationDone p) {
    display: block;
    text-align: left;
    margin: 0 0 0 50px;
}

.Checkout22 .OrderCompleteRegistrationDone p a {
    color: #000;
}

.orderCompleteRegisterShown .orderInformationWrapper,
.orderCompleteRegisterShown .RegisterWrapper {
    width: 100%;
}

@media (min-width: 768px) {
    .Checkout22 .OrderCompleteRegistration .ContinueOn,
    .RegisterWrapper-inner a.button {
        width: auto;
        min-width: 330px;
    }
}

@media (min-width: 1022px) {
    .orderCompleteRegisterShown .OrderCompleteRegistrationFormInner {
        padding-bottom: 20px;
    }

    .orderCompleteRegisterShown .RegisterWrapper,
    .orderCompleteRegisterShown .OrderCompleteRegistration {
        order: 2;
    }

    .orderCompleteRegisterShown .order-summary {
        order: 3;
    }

    .orderCompleteRegisterShown .footer-nav {
        order: 4;
        margin-bottom: 24px;
    }

    .orderCompleteRegisterShown .orderInformationWrapper,
    .orderCompleteRegisterShown .RegisterWrapper {
        width: 50%;
    }

    .orderCompleteRegisterShown .RegisterWrapper {
        padding-left: 24px;
    }

    .Checkout22 .OrderCompleteLogin .OrderCompleteLoginForm .dnnPrimaryAction {
        width: auto;
        display: inline-block;
    }

    .OrderComplete.Checkout22 .footer-nav p {
        margin-bottom: 0;
    }

    .Checkout22 .newsletterCompletion,
    .Checkout22 .OrderCompleteRegisteredSignUp {
        flex-direction: row;
    }

    .Checkout22 .newsletterCompletion .OrderCompleteRegistrationDone,
    .Checkout22 .OrderCompleteRegisteredSignUp .OrderCompleteRegistrationFormInner,
    .order-summary {
        padding: 22px 22px 30px;
    }

    .Checkout22 .OrderCompleteRegistrationDoneImage {
        width: 100%;
        max-width: 450px;
    }
}

.Checkout22 .OrderCompleteRegistrationDoneImage > div {
    height: 100%;
}

.sdlProdList #divPagination .disablePaginationArrow {
    display: inline-block;
}

.PageNumber a.NextLink,
.PageNumber a.PrevLink,
.PageNumber a.NextLink:hover,
.PageNumber a.PrevLink:hover {
    height: 48px;
    line-height: 35px;
    background-size: 1000px 1000px;
    width: 30px;
    border: none;
}

.PageNumber a.NextLink,
.PageNumber a.NextLink:hover {
    background-position: -523px -471px;
}

.PageNumber a.PrevLink,
.PageNumber a.PrevLink:hover {
    background-position: -523px -471px;
    transform: rotate(180deg);
}

.PageNumber a,
.PageNumber span.PageSelector,
.PageNumber span {
    height: 48px;
    min-width: 0;
    line-height: 48px;
    margin: 0 5px;
    padding: 0 8px;
    font-size: 15px;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
}

#BodyWrap .PageNumber .swipeNumberClick,
.PageNumber span {
    background: none;
    color: #000;
}

#BodyWrap .PageNumber span.PageSelector {
    color: #0000ed;
    font-weight: var(--fw-bold);
}

.PrevLink.disablePaginationArrow,
.NextLink.disablePaginationArrow {
    pointer-events: none;
    cursor: default;
    opacity: 0.2;
}

@media (max-width: 374px) {
    .PageNumber a,
    .PageNumber span.PageSelector {
        padding: 0 4px;
    }
}

@media (min-width: 768px) {
    :lang(en-au),
    :lang(en-nz),
    :lang(en-us) {
        .footerLanguageSwitcher,
        .footerInfoSection #topLinkMenu {
            width: 50%;
        }
    }
}

.NonBuyableOverlay {
    background-color: #f8f8f8;
}

:lang(nl),
:lang(cs),
:lang(fr),
:lang(de),
:lang(hu),
:lang(is),
:lang(it),
:lang(pl),
:lang(pt),
:lang(sk),
:lang(sl),
:lang(es),
:lang(sv) {
    #delivery_NextdayDPDcarbon,
    #delivery_NextdayExpress,
    #delivery_CollectInStore,
    #NextDayDeliverybyDPDCarbonNeutral,
    #ExpressDelivery-48Hours,
    #CollectInStore {
        display: none;
    }
}

.sdlProdList .member-pricing {
    .member-price-text {
        font-size: 15px;
        margin-bottom: 2px;

        @media (max-width: 767px) {
            font-size: 13px;
        }
    }
}

.member-pricing {
    .member-price-text {
        font-family: var(--brand-font);
        font-weight: var(--fw-bold);
    }
}

.ProdDetails .member-price {
    justify-content: start;
    text-align: left;
    font-size: 15px;
    margin-top: 10px;

    @media (max-width: 767px) {
        padding-left: 15px;
    }

    .member-price-details {
        font-family: var(--brand-font);
        font-weight: var(--fw-bold);
    }
}

.basket-container .member-pricing,
.elevated-sliding-basket .member-pricing,
.wishlist-summary .member-pricing,
.wishlist-page .member-pricing {
    max-width: 133px;
}

.innerSummaryWrap {
    .summaryListInfoPriSell span.member-line-label-FRAS,
    .summaryListInfoPriSell span.member-line-label-STUD,
    .summaryTotal .summaryTotalFinal.member-total-FRAS .summaryTotalTitle,
    .summaryTotal .summaryTotalFinal.member-total-STUD .summaryTotalTitle {
        font-family: var(--brand-font);
        font-weight: var(--fw-bold);
    }
}

.sdDetails .member-price + .pdpPriceRating {
    padding-top: 0;
    padding-bottom: 35px;
}

.frasers-plus-breakdown {
    padding-bottom: 35px;
}

.sdlProdList .showFilterButtonWrapper button {
    color: #fff;
    background-color: #0000ed;
}

.AltProdDet .title {
    margin-bottom: 0;
}

[data-sash-inside-pdp-info="true"] {
    .pdpPriceRating {
        padding-top: 40px;
        padding-bottom: 10px;
    }

    @media (min-width: 768px) {
        .member-price + .pdpPriceRating {
            padding-bottom: 8px;
        }
    }
}

.Basket .elevated-cart .basket-heading,
.Basket h1 .totalItemsContainer .itemCount,
.wishlist-summary-header,
.wishlist-page__header h1 {
    text-transform: capitalize;
}

.Basket .elevated-cart.elevated-cart-is-empty .cart-page__primary-button {
    font-weight: var(--fw-bold);
}
